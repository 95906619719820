/* eslint-disable max-lines */
import { GUILD_LINE_DATA } from '@/constants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { formatNumber } from '@/utils/helper';
const accordionStyle = {
  marginTop: '16px',
  background: '#fff',
  boxShadow:
    'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
};

export const GuildLine = () => {
  const { t } = useTranslation();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#fdfdfd',
      fontWeight: 'bold',
      color: '#000',
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  }));
  const rows: any = GUILD_LINE_DATA;

  return (
    <>
      <Accordion
        defaultExpanded
        className="accordion-market"
        style={accordionStyle}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {t('label.orderTicketGuild')}
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Token Supply</StyledTableCell>
                  <StyledTableCell align="right">Order Size</StyledTableCell>
                  <StyledTableCell align="right">Tick Size</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.tokenSupply}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatNumber(row.orderSize)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.tickSize}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
