import styled, { keyframes } from 'styled-components';

interface ILoaderProps {
  show: boolean;
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loading = styled.div`
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #111; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${spin} 2s linear infinite;
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = ({ show }: ILoaderProps) => {
  return (
    <LoadingContainer style={{ display: show ? 'flex' : 'none' }}>
      <Loading />
    </LoadingContainer>
  );
};
