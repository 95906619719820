import { Title } from '@/components/login';
import { ReactComponent as CheckSolIcon } from '@/assets/icons/check_sol.svg';

import { BtnContainer, TabProfile } from '.';

export interface AuthorityProps {
  onFreeClick: () => void;
  onMintClick: () => void;
  isDisabledMint: boolean;
  isDisabledFree: boolean;
  revokeTx: string;
  renounceTx: string;
}

export const Authority = ({
  onFreeClick,
  onMintClick,
  isDisabledMint,
  isDisabledFree,
  revokeTx,
  renounceTx,
}: AuthorityProps) => {
  return (
    <TabProfile>
      <Title>Authority</Title>
      <BtnContainer mt={2} className="two">
        <button
          className="main_btn multi_color_btn button new_btn"
          onClick={onFreeClick}
          // disabled={isDisabledFree}
        >
          Revoke Freeze
        </button>
        <button
          className="main_btn multi_color_btn button new_btn"
          onClick={onMintClick}
          // disabled={isDisabledMint}
        >
          Renounce
        </button>
      </BtnContainer>
      <BtnContainer className="two">
        {revokeTx ? (
          <a
            href={`https://solscan.io/tx/${revokeTx}`}
            target="_blank"
            rel="noreferrer"
          >
            <CheckSolIcon />
          </a>
        ) : (
          ''
        )}
        {renounceTx ? (
          <a
            href={`https://solscan.io/tx/${renounceTx}`}
            target="_blank"
            rel="noreferrer"
          >
            <CheckSolIcon />
          </a>
        ) : (
          ''
        )}
      </BtnContainer>
    </TabProfile>
  );
};
