/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { CommonContainer } from '@/components/layouts/content';
import { ConfigTraderPopup } from '@/components/layouts/pumpfun/configTraderPopup';
import { PumpfunCard } from '@/components/layouts/pumpfun/pumpfunTraderCard';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Title } from '@/components/login';
import { ConfirmDeletePopup } from '@/components/token/components/confirmDeletePopup';
import { ImportWalletPopup } from '@/components/token/components/importWalletPopup';
import { RequestStatus } from '@/constants/API';
import { useAppDispatch } from '@/hooks';
import useGlobalHook from '@/hooks/useGlobalHook';
import usePumpHook from '@/hooks/usePumpHook';
import { mainRequest } from '@/services';
import { updateListTraderActive } from '@/stores/slices/pump/pump';
import { checkIsRequesting } from '@/utils/helper';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  Grid,
  IconButton,
  Link,
  ThemeProvider,
  Tooltip,
  createTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

export interface DeleteForm {
  input: string;
}

const lightTheme = createTheme({ palette: { mode: 'light' } });

const mockSignature = [
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },

  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
  {
    signature:
      '58uCppYZgA3eb5dcesrEJTF3nKGvq9hptoUk54cAE8kZK6vKYZ9MRwbdYVJiRMmRnUFRujn52VxzbVzkgwUnwjcF',
  },
];

export enum TypeTrader {
  BUY = 'buy',
  SELL = 'sell',
}
const mockData = [
  {
    id: 1,
    address: '5uGKakxNDyUwqZbVMcudBcCtXtjnvX1bBDXcncf1QXj8',
    sol: 0,
    token: 0.0,
    profit: 30,
    isActive: true,
  },
  {
    id: 2,
    address: '5uGKakxNDyUwqZbVMcudBcCtXtjnvX1bBDXcncf1QXj8',
    sol: 0,
    token: '0.00',
    profit: 20,
    isActive: true,
  },
  {
    id: 3,
    address: '5uGKakxNDyUwqZbVMcudBcCtXtjnvX1bBDXcncf1QXj8',
    sol: 0,
    isActive: true,
    token: '0.00',
    profit: 60,
  },
  {
    id: 4,
    address: '5uGKakxNDyUwqZbVMcudBcCtXtjnvX1bBDXcncf1QXj8',
    sol: 0,
    isActive: true,
    token: '0.00',
    profit: 80,
  },
  {
    id: 5,
    address: '5uGKakxNDyUwqZbVMcudBcCtXtjnvX1bBDXcncf1QXj8',
    sol: 0,
    isActive: true,
    token: '0.00',
    profit: 40,
  },
  {
    id: 6,
    address: '5uGKakxNDyUwqZbVMcudBcCtXtjnvX1bBDXcncf1QXj8',
    sol: 0,
    isActive: true,
    token: '0.00',
    profit: 70,
  },
  {
    id: 7,
    address: '5uGKakxNDyUwqZbVMcudBcCtXtjnvX1bBDXcncf1QXj8',
    sol: 0,
    isActive: true,
    token: '0.00',
    profit: 90,
  },
];
export const PumpfunTraderDetail = () => {
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const { t } = useTranslation();
  const [open, setOpen] = useState({
    importWallet: false,
    deleteAll: false,
    walletConfig: false,
    traderConfig: false,
  });
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [listDataSignature, setListDataSignature] = useState<any>([]);

  const dispatch = useAppDispatch();
  const queries = useParams();
  const location = useLocation();

  const {
    requestGetListPumpWallet,
    requestImportPumpWallet,
    requestDeletePumpWallet,
    getListPumpWalletStatus,
    importPumpWalletStatus,
    deletePumpWalletStatus,
    buyPumpWalletStatus,
    sellPumpWalletStatus,
    listPumpWallet,
    activeWalletStatus,
    unActiveWalletStatus,
    updateSettingTradeStatus,
    requestUpdateSettingTrade,
  } = usePumpHook();

  const reloadData = () => {
    requestGetListPumpWallet();
  };

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getListPumpWalletStatus,
        importPumpWalletStatus,
        deletePumpWalletStatus,
        buyPumpWalletStatus,
        sellPumpWalletStatus,
        unActiveWalletStatus,
        updateSettingTradeStatus,
      ]),
    [
      getListPumpWalletStatus,
      importPumpWalletStatus,
      deletePumpWalletStatus,
      buyPumpWalletStatus,
      sellPumpWalletStatus,
      activeWalletStatus,
      unActiveWalletStatus,
      updateSettingTradeStatus,
    ],
  );

  const handleImportWalletBuy = (payload: any) => {
    requestImportPumpWallet(payload);
    setOpen({ ...open, importWallet: false });
  };

  const handleDeleteAll = () => {
    const ids = listPumpWallet.map(item => item.id);
    requestDeletePumpWallet({ walletIds: ids });
  };

  const handleTraderConfig = (data: any) => {
    requestUpdateSettingTrade({
      slipBuyPercent: data.slipBuyPercent,
      slipSellPercent: data.slipSellPercent,
      gasBuy: data.gasBuy,
      gasSell: data.gasSell,
      tipMEV: data.tipMEV,
    });
    setOpen({ ...open, traderConfig: false });
  };

  const handleWalletConfig = () => {};

  const handleSubmitBuyAndSell = async (
    data: any,
    value: string,
    type: TypeTrader,
  ) => {
    const ids = listPumpWallet.map(item => item.id);

    let nameAPI;
    let body;

    if (type === TypeTrader.BUY) {
      //api buy
      body = {
        tokenId: queries.id && +queries.id,
        walletIds: ids,
        amount: value,
      };
      nameAPI = '/trader/buy';
    }
    if (type === TypeTrader.SELL) {
      //api sell
      body = {
        tokenId: queries.id && +queries.id,
        walletIds: ids,
        percent: value,
      };
      nameAPI = '/trader/sell';
    }
    const response = await mainRequest(`${nameAPI}`, body, 'post');

    try {
      const dataTransaction = response?.data?.data || [];
      setListDataSignature(prevDataArray => [
        ...prevDataArray,
        ...dataTransaction,
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    dispatch({
      type: updateListTraderActive.type,
      payload: {
        active: 'reset',
        data: [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** fetch data based on query string */
  useEffect(() => {
    setTimeout(() => {
      reloadData();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (importPumpWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Please check again!')]);
    }
    if (importPumpWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Token is imported!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importPumpWalletStatus]);

  useEffect(() => {
    if (buyPumpWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to buy!')]);
    }
    if (buyPumpWalletStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyPumpWalletStatus]);

  useEffect(() => {
    if (sellPumpWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to sell!')]);
    }
    if (sellPumpWalletStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellPumpWalletStatus]);

  useEffect(() => {
    if (deletePumpWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong.Please check again! ')]);
    }
    if (deletePumpWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Wallet Deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePumpWalletStatus]);

  return (
    <CommonContainer className="top16">
      <Box
        sx={{
          display: 'flex',

          flexDirection: 'column',
          backgroundColor: '#2C2D30',
          width: '170px',
          padding: '8px 15px 8px 15px',
          borderRadius: '4px',
          marginBottom: '8px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Tooltip title="Import Wallet" arrow>
            <IconButton
              sx={{ backgroundColor: '#474747' }}
              onClick={() => setOpen({ ...open, importWallet: true })}
            >
              <DownloadForOfflineIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove All Wallet" arrow>
            <IconButton
              sx={{ backgroundColor: '#474747' }}
              onClick={() => setOpen({ ...open, deleteAll: true })}
            >
              <DeleteIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Trader Config" arrow>
            <IconButton
              sx={{ backgroundColor: '#474747' }}
              onClick={() => setOpen({ ...open, traderConfig: true })}
            >
              <TuneIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <CommonTable
            type={TableType.PUMPFUN_TRADER_DETAIL}
            data={listPumpWallet}
            // onReload={reloadData}
          />
          <PumpfunCard handleSubmitBuyAndSell={handleSubmitBuyAndSell} />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              border: '1px solid #333',
              borderRadius: 1,
              backgroundColor: '#f0f0f0',
              width: '100%',
            }}
          >
            <Title>Transaction</Title>
            {[lightTheme].map((theme, index) => (
              <Box
                key={index}
                sx={{
                  maxHeight: '590px',
                  overflow: 'auto',
                  width: '-webkit-fill-available',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f0f0f0',
                    height: 400,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10px',
                    backgroundColor: '#555',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 1,
                      bgcolor: 'background.default',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {listDataSignature.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          flex: 1,
                          width: '100%',
                          wordWrap: 'nowrap',
                          cursor: 'pointer',
                          marginBottom: '2px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Link
                          href={`https://solscan.io/tx/${item.signature}`}
                          target="_blank"
                          sx={{
                            wordWrap: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            fontSize: '0.8rem',
                            lineHeight: '1.43',
                            letterSpacing: '0.01071em',
                            '&:hover': {
                              color: '#0d47a1',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {item.signature}
                        </Link>
                      </Box>
                    ))}
                    {listDataSignature.length === 0 && (
                      <Box
                        sx={{
                          flex: 1,
                          width: '100%',
                          wordWrap: 'break-word',
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                          fontSize: '0.9rem',
                          lineHeight: '1.43',
                          letterSpacing: '0.01071em',
                          paddingLeft: '12px',
                          textAlign: 'center',
                        }}
                      >
                        {isRunning ? 'Loading...' : 'No data'}
                      </Box>
                    )}
                  </Box>
                </ThemeProvider>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      <ImportWalletPopup
        open={open.importWallet}
        onSubmit={handleImportWalletBuy}
        onClose={() => setOpen({ ...open, importWallet: false })}
      />
      <ConfirmDeletePopup
        open={open.deleteAll}
        content={'Are you sure you want to delete all wallet?'}
        onSubmit={handleDeleteAll}
        onClose={() => setOpen({ ...open, deleteAll: false })}
      />

      <ConfigTraderPopup
        open={open.traderConfig}
        onSubmit={handleTraderConfig}
        onClose={() => setOpen({ ...open, traderConfig: false })}
      />

      <Loader show={loading} />
    </CommonContainer>
  );
};
