import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  autoRugPoolTokenFailure,
  autoRugPoolTokenRequest,
  autoRugPoolTokenSuccess,
  burnTokenFailure,
  burnTokenRequest,
  burnTokenSuccess,
  createBookInfoFailure,
  createBookInfoRequest,
  createBookInfoSuccess,
  createBookMarketInfoFailure,
  createBookMarketInfoRequest,
  createBookMarketInfoSuccess,
  createBuyInfoFailure,
  createBuyInfoRequest,
  createBuyInfoSuccess,
  createPoolInfoFailure,
  createPoolInfoRequest,
  createPoolInfoSuccess,
  createPoolInfoV2Failure,
  createPoolInfoV2Request,
  createPoolInfoV2Success,
  createPumpFunFailure,
  createPumpFunRequest,
  createPumpFunSuccess,
  createSellInfoFailure,
  createSellInfoRequest,
  createSellInfoSuccess,
  createTokenAddressFailure,
  createTokenAddressRequest,
  createTokenAddressSuccess,
  createTokenInfoFailure,
  createTokenInfoRequest,
  createTokenInfoSuccess,
  deleteTokenInfoFailure,
  deleteTokenInfoRequest,
  deleteTokenInfoSuccess,
  getBootTxListFailure,
  getBootTxListRequest,
  getBootTxListSuccess,
  getBootVolumeTxListFailure,
  getBootVolumeTxListRequest,
  getBootVolumeTxListSuccess,
  getContractAddressFailure,
  getContractAddressRequest,
  getContractAddressSuccess,
  getListWalletBuyFailure,
  getListWalletBuyRequest,
  getListWalletBuySuccess,
  getListWalletSellFailure,
  getListWalletSellRequest,
  getListWalletSellSuccess,
  getListWalletVolumeFailure,
  getListWalletVolumeRequest,
  getListWalletVolumeSuccess,
  getPumpFunListFailure,
  getPumpFunListRequest,
  getPumpFunListSuccess,
  getPumpfunMintFailure,
  getPumpfunMintRequest,
  getPumpfunMintSuccess,
  getSnipSolFailure,
  getSnipSolRequest,
  getSnipSolSuccess,
  getTokenDetailsFailure,
  getTokenDetailsRequest,
  getTokenDetailsSuccess,
  getTokenHoldersFailure,
  getTokenHoldersRequest,
  getTokenHoldersSuccess,
  getTokenListFailure,
  getTokenListRequest,
  getTokenListSuccess,
  importBootTxFailure,
  importBootTxRequest,
  importBootTxSuccess,
  importBootVolumeTxFailure,
  importBootVolumeTxRequest,
  importBootVolumeTxSuccess,
  importTokenFailure,
  importTokenRequest,
  importTokenSuccess,
  importWalletBuyFailure,
  importWalletBuyRequest,
  importWalletBuySuccess,
  importWalletSellFailure,
  importWalletSellRequest,
  importWalletSellSuccess,
  importWalletVolumeFailure,
  importWalletVolumeRequest,
  importWalletVolumeSuccess,
  refreshTokenFailure,
  refreshTokenRequest,
  refreshTokenSuccess,
  revokeFreezeTokenFailure,
  revokeFreezeTokenRequest,
  revokeFreezeTokenSuccess,
  revokeMintTokenFailure,
  revokeMintTokenRequest,
  revokeMintTokenSuccess,
  rugPoolTokenFailure,
  rugPoolTokenRequest,
  rugPoolTokenSuccess,
  sendMultiSolFailure,
  sendMultiSolRequest,
  sendMultiSolSuccess,
  sendMultiTokenFailure,
  sendMultiTokenRequest,
  sendMultiTokenSuccess,
  sendSolFailure,
  sendSolRequest,
  sendSolSuccess,
  sendSolV2Failure,
  sendSolV2Request,
  sendSolV2Success,
  snipSolFailure,
  snipSolRequest,
  snipSolSuccess,
  startFakeBuyFailure,
  startFakeBuyRequest,
  startFakeBuySuccess,
  startFakeSellFailure,
  startFakeSellRequest,
  startFakeSellSuccess,
  startFakeVolumeFailure,
  startFakeVolumeRequest,
  startFakeVolumeSuccess,
  updateTokenInfoFailure,
  updateTokenInfoRequest,
  updateTokenInfoSuccess,
  updateVolumeBuyFailure,
  updateVolumeBuyRequest,
  updateVolumeBuySuccess,
  updateVolumeSellFailure,
  updateVolumeSellRequest,
  updateVolumeSellSuccess,
  updateVolumeVolumeFailure,
  updateVolumeVolumeRequest,
  updateVolumeVolumeSuccess,
} from '../slices/token/token';

type IPayload = Record<string, any>;

function* getTokenListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getTokens, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getTokenListSuccess.type,
        payload: response.data.data,
      });
    } else {
      // if (response.response.status === StatusCode.UNAUTHORIZED) {
      //   const res = yield call(SERVICES.login, {
      //     email: 'ha.ntv88+22@gmail.com',
      //     password: 'Sia111222333',
      //   });
      //   if (
      //     res.status === StatusCode.SUCCESS_COMMON ||
      //     res.status === StatusCode.SUCCESS_CREATED
      //   ) {
      //     yield localStorage.setItem(
      //       TOKEN_ACCESS_KEY,
      //       res.data.data.access_token,
      //     );
      //   } else {
      //     yield put({ type: getTokenListFailure.type });
      //   }
      // } else {
      yield put({ type: getTokenListFailure.type });
      // }
    }
  } catch (error) {
    yield put({ type: getTokenListFailure.type });
  }
}

function* getTokenHolderFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getTokenHolders, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getTokenHoldersSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getTokenHoldersFailure.type });
    }
  } catch (error) {
    yield put({ type: getTokenHoldersFailure.type });
  }
}

function* getPumpfunMintFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getPumpFunMint);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getPumpfunMintSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getPumpfunMintFailure.type });
    }
  } catch (error) {
    yield put({ type: getPumpfunMintFailure.type });
  }
}

function* getSnipSolFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getSnip, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getSnipSolSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getSnipSolFailure.type });
    }
  } catch (error) {
    yield put({ type: getSnipSolFailure.type });
  }
}

function* getTokenDetailsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getTokenDetails, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getTokenDetailsSuccess.type,
        payload: response.data?.data,
      });
    } else {
      yield put({ type: getTokenDetailsFailure.type });
    }
  } catch (error) {
    yield put({ type: getTokenDetailsFailure.type });
  }
}

function* createTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createToken, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createTokenInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createTokenInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createTokenInfoFailure.type });
  }
}

function* importTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importToken, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importTokenSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: importTokenFailure.type });
  }
}

function* createBookFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.openBook, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createBookInfoSuccess.type,
        payload: response.data?.data,
      });
    } else {
      yield put({ type: createBookInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createBookInfoFailure.type });
  }
}

function* createBookMarketFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.openBookMarket, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createBookMarketInfoSuccess.type,
        payload: response?.data,
      });
    } else {
      yield put({ type: createBookMarketInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createBookMarketInfoFailure.type });
  }
}

function* createPoolFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.openPool, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createPoolInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createPoolInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createPoolInfoFailure.type });
  }
}

function* createPoolV2Flow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.openPoolV2, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createPoolInfoV2Success.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createPoolInfoV2Failure.type });
    }
  } catch (error) {
    yield put({ type: createPoolInfoV2Failure.type });
  }
}

function* createTokenAddressFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createTokenAddress, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createTokenAddressSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createTokenAddressFailure.type });
    }
  } catch (error) {
    yield put({ type: createTokenAddressFailure.type });
  }
}

function* updateTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateToken, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: updateTokenInfoSuccess.type,
        payload: response.data?.data ?? '',
      });
    } else {
      yield put({ type: updateTokenInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateTokenInfoFailure.type });
  }
}

function* deleteTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteToken, payload);

    if (
      response.status === StatusCode.NO_CONTENT ||
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: deleteTokenInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteTokenInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteTokenInfoFailure.type });
  }
}

function* revokeMintTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.revokeMintTokens, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: revokeMintTokenSuccess.type,
        payload: response.data?.data ?? '',
      });
    } else {
      yield put({ type: revokeMintTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: revokeMintTokenFailure.type });
  }
}

function* revokeFreezeTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.revokeFreezeTokens, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: revokeFreezeTokenSuccess.type,
        payload: response.data?.data ?? '',
      });
    } else {
      yield put({ type: revokeFreezeTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: revokeFreezeTokenFailure.type });
  }
}

function* snipSolFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.snipSol, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: snipSolSuccess.type,
        payload: response.data?.data ?? '',
      });
    } else {
      yield put({ type: snipSolFailure.type });
    }
  } catch (error) {
    yield put({ type: snipSolFailure.type });
  }
}

function* rugPoolTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.rugPoolTokens, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: rugPoolTokenSuccess.type,
      });
    } else {
      yield put({ type: rugPoolTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: rugPoolTokenFailure.type });
  }
}

function* autoRugPoolTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.autoRugPoolTokens, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: autoRugPoolTokenSuccess.type,
      });
    } else {
      yield put({ type: autoRugPoolTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: autoRugPoolTokenFailure.type });
  }
}

function* burnTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.burnTokens, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: burnTokenSuccess.type,
        payload: response.data?.data ?? '',
      });
    } else {
      yield put({ type: burnTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: burnTokenFailure.type });
  }
}

function* sendSolFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.sendSol, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: sendSolSuccess.type,
      });
    } else {
      yield put({ type: sendSolFailure.type });
    }
  } catch (error) {
    yield put({ type: sendSolFailure.type });
  }
}

function* sendSolV2Flow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.sendSolV2, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: sendSolV2Success.type,
        payload: response.data?.data ?? [],
      });
    } else {
      yield put({ type: sendSolV2Failure.type });
    }
  } catch (error) {
    yield put({ type: sendSolV2Failure.type });
  }
}

function* refreshTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.refreshTokens, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: refreshTokenSuccess.type,
      });
    } else {
      yield put({ type: refreshTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: refreshTokenFailure.type });
  }
}

function* sendMultiSolFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.sendMultiSol, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: sendMultiSolSuccess.type,
        payload: response.data?.data ?? undefined,
      });
    } else {
      yield put({ type: sendMultiSolFailure.type });
    }
  } catch (error) {
    yield put({ type: sendMultiSolFailure.type });
  }
}

function* getPumpFunListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getPumpFunList, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getPumpFunListSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getPumpFunListFailure.type });
    }
  } catch (error) {
    yield put({ type: getPumpFunListFailure.type });
  }
}

function* createPumpFunFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createPumpFun, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createPumpFunSuccess.type,
        payload: response.data?.data ?? {},
      });
    } else {
      yield put({ type: createPumpFunFailure.type });
    }
  } catch (error) {
    yield put({ type: createPumpFunFailure.type });
  }
}

function* buyPumpFunFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.buyPumpFun, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createBuyInfoSuccess.type,
        payload: response.data?.data ?? [],
      });
    } else {
      yield put({ type: createBuyInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createBuyInfoFailure.type });
  }
}

function* sellPumpFunFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.sellPumpFun, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createSellInfoSuccess.type,
        payload: response.data?.data ?? [],
      });
    } else {
      yield put({ type: createSellInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createSellInfoFailure.type });
  }
}

function* sendMultiTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.multiTokenSend, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: sendMultiTokenSuccess.type,
        payload: response.data?.data ?? undefined,
      });
    } else {
      yield put({ type: sendMultiTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: sendMultiTokenFailure.type });
  }
}

function* getContractAddressFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getContractAddress, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getContractAddressSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getContractAddressFailure.type });
    }
  } catch (error) {
    yield put({ type: getContractAddressFailure.type });
  }
}

function* importBootTxFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importBootTx, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importBootTxSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importBootTxFailure.type });
    }
  } catch (error) {
    yield put({ type: importBootTxFailure.type });
  }
}

function* getBootTxListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getBootTx, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getBootTxListSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getBootTxListFailure.type });
    }
  } catch (error) {
    yield put({ type: getBootTxListFailure.type });
  }
}

function* updateVolumeBuyFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateVolumeBuy, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateVolumeBuySuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateVolumeBuyFailure.type });
    }
  } catch (error) {
    yield put({ type: updateVolumeBuyFailure.type });
  }
}

function* updateVolumeSellFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateVolumeSell, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateVolumeSellSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateVolumeSellFailure.type });
    }
  } catch (error) {
    yield put({ type: updateVolumeSellFailure.type });
  }
}

function* updateVolumeVolumeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateVolumeVolume, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateVolumeVolumeSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateVolumeVolumeFailure.type });
    }
  } catch (error) {
    yield put({ type: updateVolumeVolumeFailure.type });
  }
}

function* getListWalletBuyFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getListWalletBuy, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getListWalletBuySuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getListWalletBuyFailure.type });
    }
  } catch (error) {
    yield put({ type: getListWalletBuyFailure.type });
  }
}

function* getListWalletSellFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getListWalletSell, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getListWalletSellSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getListWalletSellFailure.type });
    }
  } catch (error) {
    yield put({ type: getListWalletSellFailure.type });
  }
}

function* getListWalletVolumeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getListWalletVolume, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getListWalletVolumeSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getListWalletVolumeFailure.type });
    }
  } catch (error) {
    yield put({ type: getListWalletVolumeFailure.type });
  }
}
function* startFakeBuyFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.startFakeBuy, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: startFakeBuySuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: startFakeBuyFailure.type });
    }
  } catch (error) {
    yield put({ type: startFakeBuyFailure.type });
  }
}

function* startFakeSellFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.startFakeSell, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: startFakeSellSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: startFakeSellFailure.type });
    }
  } catch (error) {
    yield put({ type: startFakeSellFailure.type });
  }
}

function* startFakeVolumeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importWalletVolume, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: startFakeVolumeSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: startFakeVolumeFailure.type });
    }
  } catch (error) {
    yield put({ type: startFakeVolumeFailure.type });
  }
}

function* importWalletBuyFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importWalletBuy, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importWalletBuySuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importWalletBuyFailure.type });
    }
  } catch (error) {
    yield put({ type: importWalletBuyFailure.type });
  }
}

function* importWalletSellFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importWalletSell, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importWalletSellSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importWalletSellFailure.type });
    }
  } catch (error) {
    yield put({ type: importWalletSellFailure.type });
  }
}

function* importWalletVolumeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importWalletVolume, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importWalletVolumeSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importWalletVolumeFailure.type });
    }
  } catch (error) {
    yield put({ type: importWalletVolumeFailure.type });
  }
}
function* importBootVolumeTxFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importBootVolumeTx, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importBootVolumeTxSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importBootVolumeTxFailure.type });
    }
  } catch (error) {
    yield put({ type: importBootVolumeTxFailure.type });
  }
}

function* getBootVolumeTxListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getBootVolumeTx, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getBootVolumeTxListSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getBootVolumeTxListFailure.type });
    }
  } catch (error) {
    yield put({ type: getBootVolumeTxListFailure.type });
  }
}

function* tokenSaga() {
  yield takeLatest(getTokenHoldersRequest, getTokenHolderFlow);
  yield takeLatest(createTokenInfoRequest, createTokenFlow);
  yield takeLatest(createTokenAddressRequest, createTokenAddressFlow);
  yield takeLatest(updateTokenInfoRequest, updateTokenFlow);
  yield takeLatest(deleteTokenInfoRequest, deleteTokenFlow);
  yield takeLatest(getTokenDetailsRequest, getTokenDetailsFlow);
  yield takeLatest(getTokenListRequest, getTokenListFlow);
  yield takeLatest(revokeFreezeTokenRequest, revokeFreezeTokenFlow);
  yield takeLatest(revokeMintTokenRequest, revokeMintTokenFlow);
  yield takeLatest(rugPoolTokenRequest, rugPoolTokenFlow);
  yield takeLatest(autoRugPoolTokenRequest, autoRugPoolTokenFlow);
  yield takeLatest(burnTokenRequest, burnTokenFlow);
  yield takeLatest(sendSolRequest, sendSolFlow);
  yield takeLatest(sendSolV2Request, sendSolV2Flow);
  yield takeLatest(refreshTokenRequest, refreshTokenFlow);
  yield takeLatest(snipSolRequest, snipSolFlow);
  yield takeLatest(getSnipSolRequest, getSnipSolFlow);
  yield takeLatest(sendMultiSolRequest, sendMultiSolFlow);
  yield takeLatest(createBookInfoRequest, createBookFlow);
  yield takeLatest(createBookMarketInfoRequest, createBookMarketFlow);
  yield takeLatest(importTokenRequest, importTokenFlow);
  yield takeLatest(createPoolInfoRequest, createPoolFlow);
  yield takeLatest(createPoolInfoV2Request, createPoolV2Flow);
  yield takeLatest(createPumpFunRequest, createPumpFunFlow);
  yield takeLatest(getPumpFunListRequest, getPumpFunListFlow);
  yield takeLatest(createBuyInfoRequest, buyPumpFunFlow);
  yield takeLatest(createSellInfoRequest, sellPumpFunFlow);
  yield takeLatest(getPumpfunMintRequest, getPumpfunMintFlow);
  yield takeLatest(sendMultiTokenRequest, sendMultiTokenFlow);
  yield takeLatest(getContractAddressRequest, getContractAddressFlow);
  yield takeLatest(importBootTxRequest, importBootTxFlow);
  yield takeLatest(getBootTxListRequest, getBootTxListFlow);
  yield takeLatest(importWalletBuyRequest, importWalletBuyFlow);
  yield takeLatest(importWalletSellRequest, importWalletSellFlow);
  yield takeLatest(importWalletVolumeRequest, importWalletVolumeFlow);

  yield takeLatest(updateVolumeBuyRequest, updateVolumeBuyFlow);
  yield takeLatest(updateVolumeSellRequest, updateVolumeSellFlow);
  yield takeLatest(updateVolumeVolumeRequest, updateVolumeVolumeFlow);
  yield takeLatest(getListWalletBuyRequest, getListWalletBuyFlow);
  yield takeLatest(getListWalletSellRequest, getListWalletSellFlow);
  yield takeLatest(getListWalletVolumeRequest, getListWalletVolumeFlow);
  yield takeLatest(startFakeBuyRequest, startFakeBuyFlow);
  yield takeLatest(startFakeSellRequest, startFakeSellFlow);
  yield takeLatest(startFakeVolumeRequest, startFakeVolumeFlow);
  yield takeLatest(importBootVolumeTxRequest, importBootVolumeTxFlow);
  yield takeLatest(getBootVolumeTxListRequest, getBootVolumeTxListFlow);
}

export default tokenSaga;
