import { RequestStatus } from '@/constants/API';
import { LoginFormState } from '@/pages/login';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { removeUserCachedDataFromLocalStorage } from '@/utils/localStorage';

export interface AuthenState {
  loginRequestStatus: RequestStatus;
  getProfileRequestStatus: RequestStatus;
  updateProfileRequestStatus: RequestStatus;
  updatePasswordRequestStatus: RequestStatus;
  userData: UserData | undefined;
  errorMess: string[];
  message: string;
  token: string;
  signUpRequestStatus: RequestStatus;
}

export interface UserData {
  id: number;
  username: string;
  full_name?: string;
  profile_image?: string;
  email: string;
  token: string;
}

const initialState: AuthenState = {
  loginRequestStatus: RequestStatus.IDLE,
  getProfileRequestStatus: RequestStatus.IDLE,
  updateProfileRequestStatus: RequestStatus.IDLE,
  updatePasswordRequestStatus: RequestStatus.IDLE,
  userData: undefined,
  errorMess: [],
  message: '',
  token: '',
  signUpRequestStatus: RequestStatus.IDLE,
};

export const authenSlice = createSlice({
  name: 'authen',
  initialState,
  reducers: {
    loginRequest: (state, action: PayloadAction<LoginFormState>) => {
      state.loginRequestStatus = RequestStatus.REQUESTING;
    },
    loginSuccess: (state, action: PayloadAction<string>) => {
      state.loginRequestStatus = RequestStatus.SUCCESS;
      state.token = action.payload;
    },
    loginFailure: (state, action: PayloadAction<any>) => {
      state.loginRequestStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    signUpRequest: (state, action: PayloadAction<LoginFormState>) => {
      state.signUpRequestStatus = RequestStatus.REQUESTING;
    },
    signUpSuccess: (state, action: PayloadAction<string>) => {
      state.signUpRequestStatus = RequestStatus.SUCCESS;
      state.message = action.payload;
    },
    signUpResetState: state => {
      state.signUpRequestStatus = RequestStatus.IDLE;
      state.message = '';
    },
    signUpFailure: (state, action: PayloadAction<any>) => {
      state.signUpRequestStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    getProfileRequest: (state, action: PayloadAction<any>) => {
      state.getProfileRequestStatus = RequestStatus.REQUESTING;
    },
    getProfileSuccess: (state, action: PayloadAction<UserData>) => {
      state.getProfileRequestStatus = RequestStatus.SUCCESS;
      state.userData = action.payload;
    },
    getProfileFailure: (state, action: PayloadAction<any>) => {
      state.getProfileRequestStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    updateProfileRequest: (state, action: PayloadAction<any>) => {
      state.updateProfileRequestStatus = RequestStatus.REQUESTING;
    },
    updateProfileSuccess: (state, action: PayloadAction<UserData>) => {
      state.updateProfileRequestStatus = RequestStatus.SUCCESS;
      state.userData = action.payload;
    },
    updateProfileFailure: (state, action: PayloadAction<any>) => {
      state.updateProfileRequestStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    updatePasswordRequest: (state, action: PayloadAction<any>) => {
      state.updatePasswordRequestStatus = RequestStatus.REQUESTING;
    },
    updatePasswordSuccess: (state, action: PayloadAction<UserData>) => {
      state.updatePasswordRequestStatus = RequestStatus.SUCCESS;
      state.userData = action.payload;
    },
    updatePasswordFailure: (state, action: PayloadAction<any>) => {
      state.updatePasswordRequestStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    logoutRequest: state => {
      state.loginRequestStatus = RequestStatus.IDLE;
      state.getProfileRequestStatus = RequestStatus.IDLE;
      state.updateProfileRequestStatus = RequestStatus.IDLE;
      state.updatePasswordRequestStatus = RequestStatus.IDLE;
      state.userData = undefined;
      state.errorMess = [];
      removeUserCachedDataFromLocalStorage();
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  getProfileFailure,
  getProfileRequest,
  getProfileSuccess,
  updatePasswordFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updateProfileRequest,
  updateProfileFailure,
  updateProfileSuccess,
  signUpRequest,
  signUpSuccess,
  signUpResetState,
  signUpFailure,
} = authenSlice.actions;

export default authenSlice.reducer;
