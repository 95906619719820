export const UpIcon = () => (
  <svg
    fill="#fff"
    width="16px"
    height="16px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>up</title>
    <path d="M11.25 15.688l-7.656 7.656-3.594-3.688 11.063-11.094 11.344 11.344-3.5 3.5z"></path>
  </svg>
);
