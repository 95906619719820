import { Title } from '@/components/login';
import { ReactComponent as CheckSolIcon } from '@/assets/icons/check_sol.svg';

import { BtnContainer, TabProfile } from '.';

export interface MarketType {
  price: number;
  market_cap?: number;
  supply?: number;
  holder?: number;
}
export interface LPTabProps {
  onBurnLP: () => void;
  onRugPool: () => void;
  onFakeVolumn: () => void;
  isDisableBurn: boolean;
  isDisableRug: boolean;
  lpTx: string;
  poolTx: string;
}

export const LPTab = ({
  onRugPool,
  onBurnLP,
  onFakeVolumn,
  isDisableBurn,
  isDisableRug,
  lpTx,
  poolTx,
}: LPTabProps) => {
  return (
    <TabProfile>
      <Title>LP</Title>
      <BtnContainer mt={2} className="two">
        <button
          className="main_btn multi_color_btn button new_btn"
          onClick={onBurnLP}
          disabled={isDisableBurn}
        >
          Burn LP
        </button>
        <button
          className="main_btn multi_color_btn button new_btn"
          onClick={onRugPool}
        >
          Rug Pool
        </button>
      </BtnContainer>
      <BtnContainer className="two">
        {lpTx ? (
          <a
            href={`https://solscan.io/tx/${lpTx}`}
            target="_blank"
            rel="noreferrer"
          >
            <CheckSolIcon />
          </a>
        ) : (
          ''
        )}
        {poolTx ? (
          <a
            href={`https://solscan.io/tx/${poolTx}`}
            target="_blank"
            rel="noreferrer"
          >
            <CheckSolIcon />
          </a>
        ) : (
          ''
        )}
      </BtnContainer>
    </TabProfile>
  );
};
