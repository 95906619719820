export const ClaimIcon = () => (
  <>
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style></style>
      </defs>
      <rect x="4.36" y="5.32" width="15.27" height="17.18" rx="2.86" />
      <line x1="8.18" y1="8.18" x2="8.18" y2="19.64" />
      <line x1="15.82" y1="8.18" x2="15.82" y2="19.64" />
      <line x1="12" y1="8.18" x2="12" y2="19.64" />
      <rect x="9.14" y="1.5" width="5.73" height="3.82" />
      <line x1="7.23" y1="1.5" x2="16.77" y2="1.5" />
    </svg>
  </>
);
