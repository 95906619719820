import Layout from '@/components/layouts';
import { TOKEN_ACCESS_KEY } from '@/constants';
import Path from '@/constants/path';
import { Navigate } from 'react-router-dom';

export interface ProtectedRouteProps {
  children: any;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  if (localStorage.getItem(TOKEN_ACCESS_KEY)) {
    return <Layout>{children}</Layout>;
  } else return <Navigate replace to={Path.LOGIN} />;
};

export default ProtectedRoute;
