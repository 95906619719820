import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { tokenSlice } from './token';

const selectSlice = (state: RootState) =>
  state.token || tokenSlice.getInitialState;

export const selectToken = createSelector([selectSlice], tokenState => ({
  ...tokenState,
}));
