// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  font-family: 'Poppins', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\np.x3v1VjmJULCg4wCKCLyP {\n  font-family: 'Poppins', sans-serif;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,kCAAkC;EAClC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,kCAAkC;AACpC","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Poppins', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\np.MuiTypography-root {\n  font-family: 'Poppins', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = "x3v1VjmJULCg4wCKCLyP";
export { _1 as "MuiTypography-root" };
export default ___CSS_LOADER_EXPORT___;
