/* eslint-disable max-lines */
import PopUp from '@/components/common/Popup';
import Input from '@/components/form/input';
import { InputContainer } from '@/components/form/styled';
import { Box } from '@mui/material';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';

export interface ImportTokenProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: any) => void;
}

export function ImportBootTxPopup({
  open,
  onClose,
  onSubmit,
}: ImportTokenProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    contractAddress: string;
  }>({
    defaultValues: {
      contractAddress: '',
    },
  });

  const submitForm = (formDt: any) => {
    onSubmit(formDt);
    reset();
  };

  const onCancel = (e: any) => {
    e.preventDefault();
    onClose();
  };

  return (
    <PopUp
      title={'Import Boot TX'}
      onClose={onClose}
      open={open}
      className="modal-default"
    >
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <InputContainer className="single">
          <Controller
            control={control}
            name="contractAddress"
            rules={{
              required: 'This field is required',
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('placeholder.placeholder.enterParam', {
                    param: t('label.contractAddress'),
                  })}
                  labelName={t('label.contractAddress')}
                  isRequired
                  error={errors?.contractAddress?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>

        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '20px',
              width: '100%',
            }}
          >
            <button
              className="main_btn button new_btn multi_color_btn secondary_btn"
              onClick={onCancel}
            >
              Cancel
            </button>

            <button
              className="main_btn button new_btn multi_color_btn"
              type="submit"
            >
              Submit
            </button>
          </Box>
        </Box>
      </Box>
    </PopUp>
  );
}
