/* eslint-disable max-lines */
import { Box, CardMedia, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CameraIcon } from '../icons';
import { UploadImage } from '../icons/UploadImage';
import { InputType } from './customSelect';
import ReactImageUploading from './imageUploader';
import { ErrorMessage } from './input';

export interface ImageInputProps {
  title?: string;
  label?: string;
  width: number;
  height: number;
  multiple?: boolean;
  inputType?: InputType;
  isSetting?: boolean;
  onUpload?: (value: any) => void;
  value?: any[];
  errorMessage?: string;
  isRequired?: boolean;
}

export const ImageInput = ({
  title,
  label,
  width,
  height,
  multiple = false,
  isSetting = false,
  inputType = InputType.POP_UP,
  onUpload,
  value,
  errorMessage,
  isRequired = false,
}: ImageInputProps) => {
  const [images, setImages] = useState(value ?? []);
  const maxNumber = 69;
  const { t } = useTranslation();

  const onChange = (imageList: any) => {
    // data for submit
    setImages(imageList);
    onUpload && onUpload(imageList);
  };

  const onError = (errors: any, files: any) => {
    // TODO
  };

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactImageUploading
        multiple={multiple}
        value={images}
        onChange={onChange}
        onError={onError}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          // write your own UI
          <Box component="div" className="img_input">
            <Box
              component="div"
              sx={{
                margin: isSetting ? '0 auto' : 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isSetting ? (
                <Box
                  onClick={onImageUpload}
                  sx={{
                    width: 360,
                    height: 137,
                    marginTop: '24px',
                    border: '1px dashed black',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box component="div" sx={{ textAlign: 'center' }}>
                    {images.length === 0 ? (
                      !isSetting ? (
                        ''
                      ) : (
                        <Box
                          component="div"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '160px',
                            minHeight: '160px',
                            opacity: isDragging ? '0.3' : '1',
                            background: '#fff',
                            borderRadius: '50%',
                            padding: '24px',
                            margin: '20px auto 0',
                          }}
                          {...dragProps}
                        >
                          <Box
                            component="div"
                            sx={{ textAlign: 'center', width: '100%' }}
                          >
                            <CameraIcon />
                          </Box>
                        </Box>
                      )
                    ) : (
                      <Box
                        component="div"
                        sx={{
                          display: isSetting ? 'block' : 'grid',
                          gap: '10px',
                          margin: '20px auto',
                          gridTemplateColumns: '1fr',
                        }}
                      >
                        {imageList.map((image, index) => {
                          return (
                            <Box
                              component="div"
                              key={`image-${index}`}
                              sx={{
                                position: 'relative',
                                borderRadius: isSetting ? '50%' : 'none',
                              }}
                            >
                              <CardMedia
                                image={
                                  typeof image === 'string'
                                    ? image
                                    : image?.data_url
                                }
                                title={`image`}
                                sx={{
                                  width: 160,
                                  height: 160,
                                  borderRadius: isSetting ? '50%' : 'none',
                                  margin: isSetting ? '0 auto' : 0,
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    onClick={onImageUpload}
                    sx={{
                      width: 360,
                      height: 137,
                      marginTop: '24px',
                      border: '1px dashed black',
                      borderRadius: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {images.length === 0 ? (
                      <Box
                        sx={{
                          width: 135,
                          height: 110,
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                          borderRadius: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            height: 42,
                            marginBottom: '10px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <UploadImage />
                        </Box>
                        <Typography
                          variant="caption"
                          gutterBottom
                          sx={{ display: 'block', color: 'white' }}
                        >
                          Add Token Image
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        component="div"
                        sx={{
                          display: isSetting ? 'block' : 'grid',
                          gap: '10px',
                          margin: '20px auto',
                          gridTemplateColumns: '1fr',
                        }}
                      >
                        {imageList.map((image, index) => {
                          return (
                            <Box
                              component="div"
                              key={`image-${index}`}
                              sx={{
                                position: 'relative',
                                borderRadius: isSetting ? '50%' : 'none',
                              }}
                            >
                              <CardMedia
                                image={
                                  typeof image === 'string'
                                    ? image
                                    : image?.data_url
                                }
                                title={`image`}
                                sx={{
                                  width: 135,
                                  height: 110,
                                  borderRadius: isSetting ? '50%' : 'none',
                                  margin: isSetting ? '0 auto' : 0,
                                  backgroundSize: 'contain',
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
            {errors && (
              <Box component="div">
                {errors.maxNumber && (
                  <Note mb={1} color={`red`}>
                    {t('Number of selected images exceed maxNumber')}
                  </Note>
                )}
                {errors.acceptType && (
                  <Note mb={1} color={`red`}>
                    {t('Your selected file type is not allowed')}
                  </Note>
                )}
                {errors.maxFileSize && (
                  <Note mb={1} color={`red`}>
                    {t('Selected file size exceed maxFileSize')}
                  </Note>
                )}
                {errors.resolution && (
                  <Note mb={1} color={`red`}>
                    {t('Selected file is not match your desired resolution')}
                  </Note>
                )}
              </Box>
            )}
          </Box>
        )}
      </ReactImageUploading>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Box>
  );
};

const Note = styled(Typography)<{ $inputType?: InputType }>`
  && {
    color: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#5c677e '};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    &.big {
      font-size: 14px;
      padding: 10px 100px;
      text-align-center;
      display: flex;
    }
  }
`;
