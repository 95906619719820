/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import { CommonContainer } from '@/components/layouts/content';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const PumpfunTokenList = () => {
  const location = useLocation();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    pumpFunList,
    requestResetToken,
    requestPumpFunList,
    getPumpFunListStatus,
    deleteTokenInfoStatus,
    requestDeleteToken,
  } = useTokenHook();

  const reloadData = () => {
    requestPumpFunList({});
  };

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getPumpFunListStatus === RequestStatus.ERROR) {
      setErrorMsg(['Something went wrong. Unable to fetch pumpfun']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPumpFunListStatus]);

  useEffect(() => {
    if (deleteTokenInfoStatus === RequestStatus.ERROR) {
      setErrorMsg(['Something went wrong. Unable to disable token']);
    }
    if (deleteTokenInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Token deleted successfully!']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTokenInfoStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    setTimeout(() => {
      reloadData();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const loading = useMemo(
    () => checkIsRequesting([getPumpFunListStatus, deleteTokenInfoStatus]),
    [getPumpFunListStatus, deleteTokenInfoStatus],
  );

  const showNoData = useMemo(
    () =>
      getPumpFunListStatus !== RequestStatus.IDLE &&
      (pumpFunList?.data?.length === 0 || !pumpFunList?.length),
    [pumpFunList, getPumpFunListStatus],
  );

  const deletePumpfun = (id: number) => {
    requestDeleteToken(id);
  };

  return (
    <CommonContainer className="top">
      {showNoData ? (
        <NoData />
      ) : (
        <>
          <CommonTable
            type={TableType.PUMPFUN_TOKEN}
            data={pumpFunList}
            onDelete={deletePumpfun}
          />
        </>
      )}

      <Loader show={loading} />
    </CommonContainer>
  );
};
