/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import { Loader } from '@/components/common/loader';
import NumberInput from '@/components/form/numberInput';
import { InputContainer } from '@/components/form/styled';
import { Title } from '@/components/login';
import { Container, JumboTron } from '@/components/token/components';
import { Text } from '@/components/token/components/info';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import { Alert, Box } from '@mui/material';
import * as solanaWeb3 from '@solana/web3.js';
import bs58 from 'bs58';
import * as buffer from 'buffer';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
window.Buffer = buffer.Buffer;

export interface WalletType {
  address: string;
  privateKey: string;
}
export const GenerateKey = () => {
  const { t } = useTranslation();

  const [generatedWallet, setGenerateWallet] = useState<any[]>([]);
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const { requestResetToken, sendSolStatus } = useTokenHook();

  const loading = useMemo(
    () => checkIsRequesting([sendSolStatus]),
    [sendSolStatus],
  );

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sendSolStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Sol transfered!')]);
    }

    if (sendSolStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to transfrer sol')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSolStatus]);

  // const onSubmit = (formDt: any) => {
  //   requestSendSol(formDt);
  // };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    walletCount: number;
  }>({
    defaultValues: {
      walletCount: 0,
    },
  });

  const submitForm = async (formDt: any) => {
    const totalWallet =
      formDt.walletCount && formDt.walletCount?.replace(/,/g, '');
    const wallets: WalletType[] = [];

    for (let walletNumber = 1; walletNumber <= totalWallet; walletNumber++) {
      const keypair = solanaWeb3.Keypair?.generate();

      let address = keypair?.publicKey?.toString();
      let privateKey = bs58.encode(keypair?.secretKey);
      wallets.push({
        address,
        privateKey,
      });
    }
    setGenerateWallet(wallets);
  };

  return (
    <Container className="big">
      {generatedWallet.length > 0 ? (
        <JumboTron mb={3}>
          <Alert
            severity="warning"
            variant="outlined"
            sx={{ backgroundColor: '#fff !important' }}
          >
            We do not retain the information below. Please keep it safe.
          </Alert>

          <Title>New Wallet</Title>
          {generatedWallet.map((wal: WalletType, idx) => (
            <>
              <Text className="white" key={idx}>
                {wal.address}
              </Text>
              <br />
            </>
          ))}
          <Title>Private key</Title>
          {generatedWallet.map((wal: WalletType, idx) => (
            <>
              <Text className="white" key={idx}>
                {wal.privateKey}
              </Text>
              <br />
            </>
          ))}
        </JumboTron>
      ) : (
        ''
      )}
      <Container className="tiny">
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
          <Box
            component="div"
            mb={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Title>{t('label.generateKey')}</Title>
          </Box>

          <InputContainer>
            <Controller
              control={control}
              name="walletCount"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <NumberInput
                    label={'Wallet Count'}
                    value={value}
                    onChange={onChange}
                    isRequired
                    error={errors?.walletCount?.message}
                    placeholder="Wallet Count"
                  />
                );
              }}
            />
          </InputContainer>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <CustomButton
              type="submit"
              buttonType={CustomButtonType.NO_ICON}
              title={t('label.generateKey')}
              className="multi_color_btn main_btn"
            />
          </Box>
        </Box>
      </Container>
      <Loader show={loading} />
    </Container>
  );
};
