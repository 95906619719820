/* eslint-disable max-lines */
import { PaginationProps } from '@/components/common/pagination';
import { Text } from '@/components/layouts/table/component';
import { getTotal } from '@/utils/helper';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

export enum TableType {
  VENUE = 'VENUE',
  PUMPFUN_TOKEN = 'PUMPFUN_TOKEN',
  TOP = 'TOP',
  DETAIL = 'DETAIL',
  BOOT_TX = 'BOOT_TX',
  BOOT_VOLUME = 'BOOT_VOLUME_TX',
  BOOT_BUY_SELL_TX = 'BOOT_TX_BUY_SELL',
  PUMP_BOOT_TX = 'PUMP_BOOT_TX',
  PUMP_BOOT_VOLUME = 'PUMP_BOOT_VOLUME',
  PUMP_BOOT_BUY_SELL_TX = 'PUMP_BOOT_BUY_SELL_TX',
  LIST_TRANSACTION = 'LIST_TRANSACTION',
  PUMPFUN_TRADER_DETAIL = 'PUMPFUN_TRADER_DETAIL',
  PUMPFUN_TRADER_LIST = 'PUMPFUN_TRADER_LIST',
}

export interface CommonTableProps {
  control?: any;
  type: TableType;
  data: Record<string, any>[];
  pagination?: PaginationProps;
  typeBoot?: string;
  onDelete?: (id: number) => void;
  onAction?: () => void;
  onReload?: () => void;
}

export interface TagProps {
  name: string;
  id: number;
}

export interface ICheckHeader {
  indeterminate?: boolean;
  onChange?: (e: any) => void;
}

export interface HeaderProps {
  actionHeader?: () => void;
  holders?: any[];
}

export const TokenHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top ">#</TableCell>
        <TableCell className="width_50">{t('Token Name')}</TableCell>
        {/* <TopTableHeader>{t('Image')}</TopTableHeader> */}
        <TableCell className="width_30">{t('Symbol')}</TableCell>
        <TableCell className="width_200">CA</TableCell>
        <TableCell className="width_100">{t('Supply')}</TableCell>
        <TableCell className="last">{t('Action')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const TopHeader = ({ actionHeader, holders }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center">#</TableCell>
        <TableCell className="width_600">{t('Address')}</TableCell>
        <TableCell className="width_200">{t('Quantity')}</TableCell>
        <TableCell className="width_100">
          {t('Percentage')}{' '}
          <Text>
            Total: <Text className="bold">{holders && getTotal(holders)}</Text>%
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export const PumpfunTokenHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top ">#</TableCell>
        <TableCell className="width_50">{t('Token Name')}</TableCell>
        {/* <TopTableHeader>{t('Image')}</TopTableHeader> */}
        <TableCell className="width_30">{t('Symbol')}</TableCell>
        <TableCell className="width_200">CA</TableCell>
        <TableCell className="width_100">{t('Supply')}</TableCell>
        <TableCell className="last">{t('Action')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const SnipHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center no">#</TableCell>
        <TableCell className="width_5">{t('Address')}</TableCell>
        <TableCell className="width_2">{t('Amount')}</TableCell>
        <TableCell className="width_1">{t('Status')} </TableCell>
        <TableCell className="">{t('TX')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const BootTxHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '5%' }}>#</TableCell>
        <TableCell sx={{ width: '55%' }}>Contract Address</TableCell>
        <TableCell sx={{ width: '15%' }}>Action</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const BootTxBuySellHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '5%' }}>#</TableCell>
        <TableCell sx={{ width: '75%' }}>Wallet</TableCell>
        <TableCell sx={{ width: '20%' }}>Volume</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const TransactionHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '100%' }}>Transaction</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const PumpfunTraderHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '5%' }}>No</TableCell>
        <TableCell sx={{ width: '25%' }}>Wallet</TableCell>
        <TableCell sx={{ width: '15%' }}>SOL Balance</TableCell>
        <TableCell sx={{ width: '15%' }}>Token</TableCell>
        <TableCell sx={{ width: '15%' }}>Profit</TableCell>
        <TableCell sx={{ width: '15%' }}>Active</TableCell>
        <TableCell sx={{ width: '10%' }}></TableCell>
      </TableRow>
    </TableHead>
  );
};
