/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import PopUp from '@/components/common/Popup';
import { InputType } from '@/components/form/customSelect';
import NumberInput from '@/components/form/numberInput';
import usePumpHook from '@/hooks/usePumpHook';
import { Box, Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

export interface confirmDeleteProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const CardContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #c4c6ce;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 3px 11px 15px -5px rgba(0, 0, 0, 0.2);
`;

const defaultTipValue = ['0.01', '0.001', '0.0001'];

export function ConfigTraderPopup({
  open,
  onClose,
  onSubmit,
}: confirmDeleteProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const [valueMEV, setValueMEV] = useState('');

  const { requestGetSettingTrade, settingTrade } = usePumpHook();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    slipBuyPercent?: number;
    slipSellPercent?: number;
    gasBuy: number;
    gasSell: number;
    tipMEV: string | number;
  }>({
    defaultValues: {},
  });

  const watchValueTipMEV = watch('tipMEV');

  const reloadData = () => {
    requestGetSettingTrade();
  };
  const onCancel = (e: any) => {
    e.preventDefault();
    onClose();
  };
  const handleSave = data => {
    onSubmit({ ...data, tipMEV: valueMEV });
  };

  /** fetch data based on query string */
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        reloadData();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    reset(settingTrade);
    if (defaultTipValue.includes(settingTrade.tipMEV)) {
      setValueMEV(settingTrade.tipMEV);
    } else {
      setValue('tipMEV', settingTrade.tipMEV);
    }
  }, [settingTrade]);

  useEffect(() => {
    if (watchValueTipMEV) {
      setValueMEV(watchValueTipMEV + '');
    }
  }, [watchValueTipMEV]);

  return (
    <PopUp title={'Config Trade'} onClose={onClose} open={open}>
      <Box component="form">
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Divider sx={{ marginBottom: 2 }}>Buy</Divider>
              <Controller
                control={control}
                name="slipBuyPercent"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      fromType={InputType.POP_UP}
                      label={'Slippage'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.slipBuyPercent?.message}
                      placeholder="Slippage"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="gasBuy"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      fromType={InputType.POP_UP}
                      label={'Gas'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.gasBuy?.message}
                      placeholder="Gas"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Divider sx={{ marginBottom: 2 }}>Sell</Divider>
              <Controller
                control={control}
                name="slipSellPercent"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      fromType={InputType.POP_UP}
                      label={'Slippage'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.slipSellPercent?.message}
                      placeholder="Slippage"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="gasSell"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      fromType={InputType.POP_UP}
                      label={'Gas'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.gasSell?.message}
                      placeholder="Gas"
                    />
                  );
                }}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider sx={{ marginBottom: 2 }}>MEV Protection</Divider>
                <Grid container>
                  <Grid item xs={9}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        '& .active': {
                          'background-color': '#2C2D30 !important',
                        },
                        marginTop: '8px',
                        marginRight: '8px',
                      }}
                    >
                      {defaultTipValue.map(value => {
                        let title;
                        if (value == '0.01') {
                          title = `Turbo (${value})`;
                        }
                        if (value == '0.001') {
                          title = `Fast (${value})`;
                        }
                        if (value == '0.0001') {
                          title = `Medium (${value})`;
                        }

                        return (
                          <CustomButton
                            width={'31%'}
                            key={value}
                            buttonType={CustomButtonType.CUSTOM}
                            className={value === valueMEV ? 'active' : ''}
                            title={title}
                            backgroundColorBtn="#a1a1aa"
                            textColor="#fff"
                            onClick={() => {
                              setValueMEV(value);
                              setValue('tipMEV', '');
                            }}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="tipMEV"
                      render={({ field }) => {
                        const { value, onChange } = field;
                        return (
                          <NumberInput
                            label={''}
                            value={value}
                            onChange={onChange}
                            placeholder="Tip MEV"
                            noBottom
                            error={errors?.tipMEV?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '150px 150px',
                gap: '20px',
                marginTop: '32px',
              }}
            >
              <button
                className="main_btn button new_btn multi_color_btn secondary_btn"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                className="main_btn button new_btn multi_color_btn"
                onClick={handleSubmit(handleSave)}
              >
                Save
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </PopUp>
  );
}
