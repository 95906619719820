/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { Container } from '@/components/token/components';
import {
  CREATE_MARKET_TYPE,
  Market,
} from '@/components/token/components/market';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import * as buffer from 'buffer';
import { useEffect, useMemo } from 'react';

window.Buffer = buffer.Buffer;

export interface WalletType {
  address: string;
  privateKey: string;
}

export const CreateMarketPage = () => {
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    requestResetToken,
    requestCreateBookMarket,
    createBookMarketStatus,
    marketData,
  } = useTokenHook();

  const loading = useMemo(
    () => checkIsRequesting([createBookMarketStatus]),
    [createBookMarketStatus],
  );

  const onCreateMarket = (formData: any) => {
    requestCreateBookMarket({
      privateKey: formData.privateKey,
      lotSize: formData.lotSize,
      tickSize: formData.tickSize,
      contractAddress: formData.contractAddress,
      type: formData.type,
    });
  };

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createBookMarketStatus === RequestStatus.ERROR) {
      setErrorMsg([
        'Something went wrong. Can not create OpenBook, please try again',
      ]);
    }
    if (createBookMarketStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['OpenBook has been created']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBookMarketStatus]);

  return (
    <Container className="big">
      <Market
        onCreate={onCreateMarket}
        type={CREATE_MARKET_TYPE.FROM_BOOK}
        data={marketData}
      />

      <Loader show={loading} />
    </Container>
  );
};
