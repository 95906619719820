import { ExportIcon, PlusIcon, UploadIcon } from '@/components/icons';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TransferIcon } from '../icons/Transfer';

export enum CustomButtonType {
  NO_ICON = 'NO_ICON',
  EXPORT_ICON = 'EXPORT_ICON',
  ADD_ICON = 'ADD_ICON',
  WHITE_BG = 'WHITE_BG',
  UPLOAD = 'UPLOAD',
  ADD_STATE = 'ADD_STATE',
  CUSTOM = 'CUSTOM',
  TRANSFER = 'TRANSFER',
}

export interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: CustomButtonType;
  title: string;
  backgroundColorBtn?: string;
  textColor?: string;
  width?: string;
}

const PageButton = styled.button<{ $buttonType: CustomButtonType }>`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  cursor: pointer;
  background: ${props =>
    props.$buttonType === CustomButtonType.WHITE_BG
      ? '#fff'
      : props.$buttonType === CustomButtonType.UPLOAD ||
        props.$buttonType === CustomButtonType.ADD_STATE
      ? '#E34A66'
      : '#3080EC'};
  border: ${props =>
    props.$buttonType === CustomButtonType.WHITE_BG
      ? '1px solid #DEE1EB'
      : 'none'};
  white-space: nowrap;
  span {
    color: #111;
  }
  svg * {
    opacity: 1;
  }
`;

const PumpfunButton = styled.button<{ $buttonType: CustomButtonType }>`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  cursor: pointer;
  background: ${props =>
    props.$buttonType === CustomButtonType.WHITE_BG
      ? '#fff'
      : props.$buttonType === CustomButtonType.UPLOAD ||
        props.$buttonType === CustomButtonType.ADD_STATE
      ? '#E34A66'
      : '#3080EC'};
  border: ${props =>
    props.$buttonType === CustomButtonType.WHITE_BG
      ? '1px solid #DEE1EB'
      : 'none'};
  white-space: nowrap;
  span {
    color: #111;
  }
  svg * {
    opacity: 1;
  }
`;

const Text = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const CustomButton: React.FC<CustomButtonProps> = ({
  buttonType,
  title = '',
  backgroundColorBtn,
  textColor,
  width,
  ...rest
}: CustomButtonProps) => {
  const renderButton = useMemo(() => {
    switch (buttonType) {
      case CustomButtonType.EXPORT_ICON:
        return (
          <>
            <ExportIcon />
            <Text>{title}</Text>
          </>
        );
      case CustomButtonType.ADD_STATE:
      case CustomButtonType.ADD_ICON:
        return (
          <>
            <PlusIcon />
            <Text>{title}</Text>
          </>
        );

      case CustomButtonType.UPLOAD:
        return (
          <>
            <UploadIcon />
            <Text>{title}</Text>
          </>
        );

      case CustomButtonType.NO_ICON:
        return <Text>{title}</Text>;

      case CustomButtonType.CUSTOM:
        return <Text style={{ color: `${textColor}` }}>{title}</Text>;

      case CustomButtonType.TRANSFER:
        return (
          <>
            <TransferIcon />
            <Text style={{ color: `${textColor}` }}>{title}</Text>
          </>
        );

      default:
        return <Text>{title}</Text>;
    }
  }, [buttonType, title]);

  return (
    <PageButton
      type={`button`}
      $buttonType={buttonType}
      style={{ backgroundColor: `${backgroundColorBtn}`, width: width }}
      {...rest}
    >
      {renderButton}
    </PageButton>
  );
};

export default CustomButton;
