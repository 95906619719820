import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

let namespaces: any = [];
function getAllFileLang() {
  const context = require.context('../locales', true, /\.json$/i);
  const messages = {};
  context.keys().forEach(key => {
    const match = key.match(
      /\.\/([a-zA-Z0-9-_]+)(\/([a-zA-Z0-9-_]+))?\.json$/i,
    );
    if (match) {
      const locale = match[1];
      const fileName = match[3] || 'translation'; // Default to 'translation' if no fileName

      if (!messages[locale]) {
        messages[locale] = {};
      }

      if (!messages[locale][fileName]) {
        messages[locale][fileName] = {};
      }

      const content = context(key);
      console.log(messages[locale]);

      messages[locale][fileName] = {
        ...messages[locale][fileName],
        ...content,
      };
    }
  });
  namespaces = getNameSpaceWithFallBack('en', messages);
  return messages;
}

const getNameSpaceWithFallBack = (fallbackLng = 'en', messages) => {
  return Object.keys(messages['en']);
};
const resources = getAllFileLang();
// Create the 'translations' object to provide full intellisense support for the static json files.
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: namespaces,
    defaultNS: 'translation',
    returnObjects: true,
    keySeparator: '.',
    nsSeparator: '.', // Change namespace separator to '.'
  });
export default i18next;
