// FIXME: Update this configuration file based on your project information

export const AppConfig = {
  site_name: 'SOL tool',
  title: 'SOL tool',
  description: 'SOL tool',
  locale: 'en',
  apiBase: `${process.env.REACT_APP_PUBLIC_API_BASE || ''}`,
  apiKey: process.env.REACT_APP_API_SECRET_KEY || '',
  ntfUploadKey: process.env.REACT_APP_NFT_STORAGE_KEY || '',
};
