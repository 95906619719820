import { useAppDispatch, useAppSelector } from '@/hooks';

import { LoginFormState } from '@/pages/login';
import {
  loginRequest,
  logoutRequest,
  signUpRequest,
  updateProfileRequest,
} from '@/stores/slices/auth/auth';
import { selectAuth } from '@/stores/slices/auth/selector';

const useAuthHook = () => {
  const {
    loginRequestStatus,
    userData,
    errorMess,
    message,
    updateProfileRequestStatus,
    updatePasswordRequestStatus,
    token,
    signUpRequestStatus,
  } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const requestLogin = (params: LoginFormState) =>
    dispatch(loginRequest(params));

  const requestSignUp = (params: LoginFormState) =>
    dispatch(signUpRequest(params));

  const requestUpdateProfile = (params: any) =>
    dispatch(updateProfileRequest(params));

  const logout = () => dispatch(logoutRequest());

  return {
    loginRequestStatus,
    requestLogin,
    userData,
    logout,
    requestUpdateProfile,
    errorMess,
    message,
    updateProfileRequestStatus,
    updatePasswordRequestStatus,
    token,
    signUpRequestStatus,
    requestSignUp,
  };
};

export default useAuthHook;
