/* eslint-disable max-lines */
import { LogoutIcon } from '@/components/icons/Logout';
import { MenuIcon } from '@/components/icons/Menu';
import { POPOVER_ID } from '@/constants';
import Path from '@/constants/path';
import useAuthHook from '@/hooks/useAuthHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import { AppBar, Box, IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { WalletPopup } from './popup';
import {
  CustomToolBar,
  HeaderContainer,
  HeaderContent,
  Username,
} from './styled';

export const Header = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);

  // const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const navigate = useNavigateHook().navigate;
  const { logout } = useAuthHook();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate(Path.LOGIN);
  };

  const { hasWallet, setSidebar, showSidebar } = useGlobalHook();
  const open = Boolean(anchorEl) && hasWallet;
  const id = open ? POPOVER_ID : undefined;

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: showSidebar ? 'calc(100% - 230px)' : '100%',
        }}
      >
        <CustomToolBar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setSidebar(!showSidebar)}
            sx={{
              '& svg': {
                fill: '#fff',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <HeaderContainer>
            <Box
              sx={{ display: 'flex', alignItems: 'space-between', gap: '10px' }}
            >
              {/* <HeaderContent aria-describedby={id} onClick={handleClick}>
                {hasWallet ? (
                  <button
                    className={`multi_color_btn button text_clipped main_button`}
                  >
                    ABC
                  </button>
                ) : (
                  <button
                    className={`multi_color_btn button  main_button`}
                    onClick={() => setOpenPopup(true)}
                  >
                    Connect wallet
                  </button>
                )}
              </HeaderContent> */}
              <HeaderContent>
                <button
                  className={`button  main_button logout`}
                  onClick={handleLogout}
                >
                  <LogoutIcon />
                </button>
              </HeaderContent>
            </Box>
          </HeaderContainer>
        </CustomToolBar>
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            padding: '15px 0',
            '& .password svg': {
              width: '20px',
            },
            '& .password svg path': {
              stroke: '#222222',
              fill: '#fff',
            },
          }}
        >
          <HeaderContent className="item border_bottom">
            <Username className="normal_txt">Change Wallet</Username>
          </HeaderContent>

          <HeaderContent onClick={handleLogout} className="item">
            <Username className="normal_txt">{t('Disconnect')}</Username>
          </HeaderContent>
        </Box>
      </Popover>
      <WalletPopup open={openPopup} onClose={() => setOpenPopup(false)} />
    </>
  );
};
