import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  activeWalletFailure,
  activeWalletRequest,
  activeWalletSuccess,
  buyPumpWalletFailure,
  buyPumpWalletRequest,
  buyPumpWalletSuccess,
  claimSolFailure,
  claimSolRequest,
  claimSolSuccess,
  deletePumpWalletFailure,
  deletePumpWalletRequest,
  deletePumpWalletSuccess,
  getListPumpTokenFailure,
  getListPumpTokenRequest,
  getListPumpTokenSuccess,
  getListPumpWalletFailure,
  getListPumpWalletRequest,
  getListPumpWalletSuccess,
  getListWalletBuyPumpFailure,
  getListWalletBuyPumpRequest,
  getListWalletBuyPumpSuccess,
  getListWalletSellPumpFailure,
  getListWalletSellPumpRequest,
  getListWalletSellPumpSuccess,
  getListWalletVolumePumpFailure,
  getListWalletVolumePumpRequest,
  getListWalletVolumePumpSuccess,
  getPumpBootTxListFailure,
  getPumpBootTxListRequest,
  getPumpBootTxListSuccess,
  getPumpBootVolumeTxListFailure,
  getPumpBootVolumeTxListRequest,
  getPumpBootVolumeTxListSuccess,
  getSettingTradeFailure,
  getSettingTradeRequest,
  getSettingTradeSuccess,
  importPumpBootTxFailure,
  importPumpBootTxRequest,
  importPumpBootTxSuccess,
  importPumpBootVolumeTxFailure,
  importPumpBootVolumeTxRequest,
  importPumpBootVolumeTxSuccess,
  importPumpTokenFailure,
  importPumpTokenRequest,
  importPumpTokenSuccess,
  importPumpWalletFailure,
  importPumpWalletRequest,
  importPumpWalletSuccess,
  importWalletBuyPumpFailure,
  importWalletBuyPumpRequest,
  importWalletBuyPumpSuccess,
  importWalletSellPumpFailure,
  importWalletSellPumpRequest,
  importWalletSellPumpSuccess,
  importWalletVolumePumpFailure,
  importWalletVolumePumpRequest,
  importWalletVolumePumpSuccess,
  sellPumpWalletFailure,
  sellPumpWalletRequest,
  sellPumpWalletSuccess,
  startFakeBuyPumpFailure,
  startFakeBuyPumpRequest,
  startFakeBuyPumpSuccess,
  startFakeSellPumpFailure,
  startFakeSellPumpRequest,
  startFakeSellPumpSuccess,
  startFakeVolumePumpFailure,
  startFakeVolumePumpRequest,
  startFakeVolumePumpSuccess,
  unActiveWalletFailure,
  unActiveWalletRequest,
  unActiveWalletSuccess,
  updateSettingTradeFailure,
  updateSettingTradeRequest,
  updateSettingTradeSuccess,
  updateVolumeBuyPumpFailure,
  updateVolumeBuyPumpRequest,
  updateVolumeBuyPumpSuccess,
  updateVolumeSellPumpFailure,
  updateVolumeSellPumpRequest,
  updateVolumeSellPumpSuccess,
  updateVolumeVolumePumpFailure,
  updateVolumeVolumePumpRequest,
  updateVolumeVolumePumpSuccess,
} from '../slices/pump/pump';

type IPayload = Record<string, any>;

function* importWalletSellPumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importWalletSellPump, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importWalletSellPumpSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importWalletSellPumpFailure.type });
    }
  } catch (error) {
    yield put({ type: importWalletSellPumpFailure.type });
  }
}

function* importWalletBuyPumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importWalletBuyPump, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importWalletBuyPumpSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importWalletBuyPumpFailure.type });
    }
  } catch (error) {
    yield put({ type: importWalletBuyPumpFailure.type });
  }
}
function* importBootVolumePumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importWalletVolumePump, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importWalletVolumePumpSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importWalletVolumePumpFailure.type });
    }
  } catch (error) {
    yield put({ type: importWalletVolumePumpFailure.type });
  }
}

function* getListWalletBuyPumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getListWalletBuyPump, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getListWalletBuyPumpSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getListWalletBuyPumpFailure.type });
    }
  } catch (error) {
    yield put({ type: getListWalletBuyPumpFailure.type });
  }
}

function* getListWalletSellPumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getListWalletSellPump, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getListWalletSellPumpSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getListWalletSellPumpFailure.type });
    }
  } catch (error) {
    yield put({ type: getListWalletSellPumpFailure.type });
  }
}
function* getListWalletVolumePumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getListWalletVolumePump, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getListWalletVolumePumpSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getListWalletVolumePumpFailure.type });
    }
  } catch (error) {
    yield put({ type: getListWalletVolumePumpFailure.type });
  }
}

function* startFakeBuyPumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.startFakeBuyPump, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: startFakeBuyPumpSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: startFakeBuyPumpFailure.type });
    }
  } catch (error) {
    yield put({ type: startFakeBuyPumpFailure.type });
  }
}

function* startFakeSellPumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.startFakeSellPump, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: startFakeSellPumpSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: startFakeSellPumpFailure.type });
    }
  } catch (error) {
    yield put({ type: startFakeSellPumpFailure.type });
  }
}

function* startFakeVolumePumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.startFakeVolumePump, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: startFakeVolumePumpSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: startFakeVolumePumpFailure.type });
    }
  } catch (error) {
    yield put({ type: startFakeVolumePumpFailure.type });
  }
}

function* updateVolumeBuyPumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateVolumeBuyPump, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateVolumeBuyPumpSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateVolumeBuyPumpFailure.type });
    }
  } catch (error) {
    yield put({ type: updateVolumeBuyPumpFailure.type });
  }
}

function* updateVolumeSellPumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateVolumeSellPump, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateVolumeSellPumpSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateVolumeSellPumpFailure.type });
    }
  } catch (error) {
    yield put({ type: updateVolumeSellPumpFailure.type });
  }
}

function* updateVolumeVolumePumpFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateVolumeVolumePump, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateVolumeVolumePumpSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateVolumeVolumePumpFailure.type });
    }
  } catch (error) {
    yield put({ type: updateVolumeVolumePumpFailure.type });
  }
}

function* getPumpBootTxListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getPumpBootTx, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getPumpBootTxListSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getPumpBootTxListFailure.type });
    }
  } catch (error) {
    yield put({ type: getPumpBootTxListFailure.type });
  }
}

function* importPumpBootTxFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importPumpBootTx, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importPumpBootTxSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importPumpBootTxFailure.type });
    }
  } catch (error) {
    yield put({ type: importPumpBootTxFailure.type });
  }
}

function* getPumpBootVolumeTxListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getPumpBootVolumeTx, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getPumpBootVolumeTxListSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getPumpBootVolumeTxListFailure.type });
    }
  } catch (error) {
    yield put({ type: getPumpBootVolumeTxListFailure.type });
  }
}

function* importPumpBootVolumeTxFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importPumpBootVolumeTx, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importPumpBootVolumeTxSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importPumpBootVolumeTxFailure.type });
    }
  } catch (error) {
    yield put({ type: importPumpBootVolumeTxFailure.type });
  }
}

function* claimSolFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.claimSol, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: claimSolSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: claimSolFailure.type });
    }
  } catch (error) {
    yield put({ type: claimSolFailure.type });
  }
}

function* getListPumpTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getListPumpToken, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getListPumpTokenSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getListPumpTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: getListPumpTokenFailure.type });
  }
}

function* importPumpTokenFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importPumpToken, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importPumpTokenSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importPumpTokenFailure.type });
    }
  } catch (error) {
    yield put({ type: importPumpTokenFailure.type });
  }
}

function* getListPumpWalletFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getListPumpWallet, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getListPumpWalletSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getListPumpWalletFailure.type });
    }
  } catch (error) {
    yield put({ type: getListPumpWalletFailure.type });
  }
}

function* importPumpWalletFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.importPumpWallet, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: importPumpWalletSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: importPumpWalletFailure.type });
    }
  } catch (error) {
    yield put({ type: importPumpWalletFailure.type });
  }
}

function* deletePumpWalletFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deletePumpWallet, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: deletePumpWalletSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deletePumpWalletFailure.type });
    }
  } catch (error) {
    yield put({ type: deletePumpWalletFailure.type });
  }
}

function* buyPumpWalletFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.buyPumpWallet, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: buyPumpWalletSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: buyPumpWalletFailure.type });
    }
  } catch (error) {
    yield put({ type: buyPumpWalletFailure.type });
  }
}

function* sellPumpWalletFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.sellPumpWallet, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: sellPumpWalletSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: sellPumpWalletFailure.type });
    }
  } catch (error) {
    yield put({ type: sellPumpWalletFailure.type });
  }
}

function* activeWalletFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.activeWallet, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: activeWalletSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: activeWalletFailure.type });
    }
  } catch (error) {
    yield put({ type: activeWalletFailure.type });
  }
}

function* unActiveWalletFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.unActiveWallet, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: unActiveWalletSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: unActiveWalletFailure.type });
    }
  } catch (error) {
    yield put({ type: unActiveWalletFailure.type });
  }
}

function* getSettingTradeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getSettingTrade, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getSettingTradeSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: getSettingTradeFailure.type });
    }
  } catch (error) {
    yield put({ type: getSettingTradeFailure.type });
  }
}

function* updateSettingTradeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateSettingTrade, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: updateSettingTradeSuccess.type,
        payload: response.data.data,
      });
    } else {
      yield put({ type: updateSettingTradeFailure.type });
    }
  } catch (error) {
    yield put({ type: updateSettingTradeFailure.type });
  }
}

function* pumpSaga() {
  yield takeLatest(importWalletSellPumpRequest, importWalletSellPumpFlow);
  yield takeLatest(importWalletBuyPumpRequest, importWalletBuyPumpFlow);
  yield takeLatest(importWalletVolumePumpRequest, importBootVolumePumpFlow);

  yield takeLatest(getListWalletBuyPumpRequest, getListWalletBuyPumpFlow);
  yield takeLatest(getListWalletSellPumpRequest, getListWalletSellPumpFlow);
  yield takeLatest(getListWalletVolumePumpRequest, getListWalletVolumePumpFlow);

  yield takeLatest(startFakeBuyPumpRequest, startFakeBuyPumpFlow);
  yield takeLatest(startFakeSellPumpRequest, startFakeSellPumpFlow);
  yield takeLatest(startFakeVolumePumpRequest, startFakeVolumePumpFlow);

  yield takeLatest(updateVolumeBuyPumpRequest, updateVolumeBuyPumpFlow);
  yield takeLatest(updateVolumeSellPumpRequest, updateVolumeSellPumpFlow);
  yield takeLatest(updateVolumeVolumePumpRequest, updateVolumeVolumePumpFlow);

  yield takeLatest(getPumpBootTxListRequest, getPumpBootTxListFlow);
  yield takeLatest(importPumpBootTxRequest, importPumpBootTxFlow);

  yield takeLatest(getPumpBootVolumeTxListRequest, getPumpBootVolumeTxListFlow);
  yield takeLatest(importPumpBootVolumeTxRequest, importPumpBootVolumeTxFlow);

  yield takeLatest(claimSolRequest, claimSolFlow);

  yield takeLatest(getListPumpTokenRequest, getListPumpTokenFlow);

  yield takeLatest(importPumpTokenRequest, importPumpTokenFlow);

  yield takeLatest(getListPumpWalletRequest, getListPumpWalletFlow);

  yield takeLatest(importPumpWalletRequest, importPumpWalletFlow);

  yield takeLatest(deletePumpWalletRequest, deletePumpWalletFlow);

  yield takeLatest(buyPumpWalletRequest, buyPumpWalletFlow);
  yield takeLatest(sellPumpWalletRequest, sellPumpWalletFlow);

  yield takeLatest(activeWalletRequest, activeWalletFlow);
  yield takeLatest(unActiveWalletRequest, unActiveWalletFlow);

  yield takeLatest(getSettingTradeRequest, getSettingTradeFlow);
  yield takeLatest(updateSettingTradeRequest, updateSettingTradeFlow);
}

export default pumpSaga;
