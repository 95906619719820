import styled from 'styled-components';

export const InputName = styled.div`
  color: var(--primary-color);
  margin-bottom: 8px;
  font-size: 16px;
`;

export const InputRequired = styled.span`
  color: #e34a66;
`;
