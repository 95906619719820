export const MaterialIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_951_96342)">
      <path
        d="M10.0002 0.833496C7.56901 0.833496 5.23743 1.79927 3.51835 3.51835C1.79927 5.23743 0.833496 7.56901 0.833496 10.0002C0.833496 12.4313 1.79927 14.7629 3.51835 16.482C5.23743 18.2011 7.56901 19.1668 10.0002 19.1668C10.8455 19.1668 11.5279 18.4844 11.5279 17.639C11.5279 17.2418 11.3752 16.8853 11.1307 16.6103C10.8965 16.3455 10.7437 15.9891 10.7437 15.602C10.7437 14.7566 11.4261 14.0742 12.2715 14.0742H14.0742C16.8853 14.0742 19.1668 11.7928 19.1668 8.98164C19.1668 4.47979 15.0622 0.833496 10.0002 0.833496ZM4.39831 10.0002C3.55294 10.0002 2.87053 9.31775 2.87053 8.47238C2.87053 7.62701 3.55294 6.94461 4.39831 6.94461C5.24368 6.94461 5.92609 7.62701 5.92609 8.47238C5.92609 9.31775 5.24368 10.0002 4.39831 10.0002ZM7.45387 5.92609C6.6085 5.92609 5.92609 5.24368 5.92609 4.39831C5.92609 3.55294 6.6085 2.87053 7.45387 2.87053C8.29924 2.87053 8.98164 3.55294 8.98164 4.39831C8.98164 5.24368 8.29924 5.92609 7.45387 5.92609ZM12.5465 5.92609C11.7011 5.92609 11.0187 5.24368 11.0187 4.39831C11.0187 3.55294 11.7011 2.87053 12.5465 2.87053C13.3918 2.87053 14.0742 3.55294 14.0742 4.39831C14.0742 5.24368 13.3918 5.92609 12.5465 5.92609ZM15.602 10.0002C14.7566 10.0002 14.0742 9.31775 14.0742 8.47238C14.0742 7.62701 14.7566 6.94461 15.602 6.94461C16.4474 6.94461 17.1298 7.62701 17.1298 8.47238C17.1298 9.31775 16.4474 10.0002 15.602 10.0002Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_951_96342">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
