/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import NumberInput from '@/components/form/numberInput';
import { InputContainer } from '@/components/form/styled';
import { AntSwitch } from '@/components/layouts/table/component';
import { Label, Title } from '@/components/login';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { useState } from 'react';
import { Container } from '.';

export interface RugFormProps {
  onSubmit?: (data: FormData) => void;
  isAutoRug?: boolean;
  volumeRug?: string;
}

export const RugForm = ({ onSubmit, isAutoRug, volumeRug }: RugFormProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    volume?: string;
    isAutoRug?: boolean;
  }>({
    defaultValues: {
      volume: volumeRug ?? '',
      isAutoRug: isAutoRug,
    },
  });

  const [checked, setChecked] = useState<boolean>(!!isAutoRug);

  const onChangeAutoRug = () => {
    setChecked(!checked);
    setValue('isAutoRug', !checked);
  };

  const submitForm = (formDt: any) => {
    formDt.volume = formDt.volume?.toString().replace(/,/g, '');
    formDt.volume = +formDt.volume;
    onSubmit &&
      onSubmit({
        ...formDt,
      });
  };

  return (
    <Container>
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <Box
          component="div"
          mb={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Title>Auto Rug</Title>
        </Box>

        <InputContainer className="mt1 ">
          <AntSwitch onChange={onChangeAutoRug} checked={checked} />
          <Label className="white">{checked ? 'Active' : 'Inactive'}</Label>
        </InputContainer>

        <InputContainer className="mt1 ">
          <Controller
            control={control}
            name="volume"
            rules={{
              required: 'This field is required',
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <NumberInput
                  label={'Volume(SOL)'}
                  value={value}
                  onChange={onChange}
                  isRequired
                  error={errors?.volume?.message}
                  placeholder="Volume(SOL)"
                />
              );
            }}
          />
        </InputContainer>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={`Auto Rug`}
            className="multi_color_btn main_btn"
          />
        </Box>
      </Box>
    </Container>
  );
};
