/* eslint-disable max-lines */
import PopUp from '@/components/common/Popup';
import { MetaMask } from '@/components/icons/MetaMask';
import { Trust } from '@/components/icons/Trust';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FlexBox, Title } from './styled';

// import { ImageInput } from '@/components/form/imageUploading';
export interface WalletPopupProps {
  open: boolean;
  onClose: () => void;
}

export function WalletPopup({ open, onClose }: WalletPopupProps) {
  const { t } = useTranslation();

  return (
    <PopUp title={t('Select wallet')} onClose={onClose} open={open}>
      <FlexBox className="wallet_box">
        <Box component="span" className="wallet_icon">
          <MetaMask />
        </Box>
        <Title>&nbsp; MetaMask</Title>
      </FlexBox>
      <FlexBox className="wallet_box">
        <Box component="span" className="wallet_icon">
          <Trust />
        </Box>
        <Title>&nbsp; Trust</Title>
      </FlexBox>
      <FlexBox className="wallet_box">
        <Box component="span" className="wallet_icon">
          <Trust />
        </Box>
        <Title>&nbsp; Trust</Title>
      </FlexBox>
    </PopUp>
  );
}
