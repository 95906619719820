import { Title } from '@/components/login';
import { TokenDetail } from '@/stores/slices/token/type';
import { Box } from '@mui/material';

import { TabProfile } from '.';

export interface MetaProps {
  data: TokenDetail;
  onUpdate: () => void;
}

export const Meta = ({ data, onUpdate }: MetaProps) => {
  return (
    <TabProfile>
      <Title>Metadata</Title>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}
      >
        <button
          className="main_btn multi_color_btn button new_btn min_150"
          onClick={onUpdate}
        >
          Edit
        </button>
      </Box>
    </TabProfile>
  );
};
