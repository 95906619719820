export const ACCESS_TOKEN_KEY = 'asi7yBUvjhKJ9ub';
export const DEFAULT_NULL_INDEX = -1;
export const INIT_MAX_NUMBER = 1000;
export const DEFAULT_DATA_URL_KEY = 'dataURL';
export const POPOVER_ID = 'popover_id';

export const USER_CACHED_DATA = 'USER_CACHED_DATA';
export const MAP_STATE_CACHED_DATA = 'MAP_STATE_CACHED_DATA';
export const MAP_ELEMENT_CACHED_DATA = 'MAP_ELEMENT_CACHED_DATA';
export const API_KEY = 'AIzaSyDUiBIdQhABpvEBXjDo1N4XSKehrjvT6Ug';
export * from './modes';
export * from './projectActions';
export * from './units';

export const KEYBOARD_BUTTON_CODE = {
  DELETE: 46,
  BACKSPACE: 8,
  ESC: 27,
  Z: 90,
  ALT: 18,
  C: 67,
  V: 86,
  CTRL: 17,
  ENTER: 13,
  TAB: 9,
};
export const TOKEN_ACCESS_KEY = 'tkdhdjahdADzkkkksj';
export const BASE_QUOTE = 'So11111111111111111111111111111111111111112';

export const SNIP_STATUS = ['UN_PROGRESS', 'IN_PROGRESS', 'DONE', 'FAILED'];

export const MAX_SNIP = 100;
export const MIN_SNIP = 0.1;
export const SOL_TYPES = [
  {
    label: '0.29 SOL',
    value: 'small',
  },
  {
    label: '1.4 SOL',
    value: 'normal',
  },
  {
    label: '2.7 SOL',
    value: 'large',
  },
];

export const GUILD_LINE_DATA = [
  {
    tokenSupply: '100k',
    orderSize: '0.01',
    tickSize: '0.0001',
  },
  {
    tokenSupply: '1M',
    orderSize: '0.1',
    tickSize: '0.00001',
  },
  {
    tokenSupply: '10M',
    orderSize: '1',
    tickSize: '0.000001',
  },
  {
    tokenSupply: '100M',
    orderSize: '10',
    tickSize: '0.0000001',
  },
  {
    tokenSupply: '1B',
    orderSize: '100',
    tickSize: '0.00000001',
  },
  {
    tokenSupply: '10B',
    orderSize: '1000',
    tickSize: '0.000000001',
  },
  {
    tokenSupply: '100B',
    orderSize: '10000',
    tickSize: '0.0000000001',
  },
  ,
];
