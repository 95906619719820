/* eslint-disable max-lines */
import { useAppDispatch, useAppSelector } from '@/hooks';

import { selectToken } from '@/stores/slices/token/selector';
import {
  autoRugPoolTokenRequest,
  burnTokenRequest,
  createBookInfoRequest,
  createBookMarketInfoRequest,
  createBuyInfoRequest,
  createPoolInfoRequest,
  createPoolInfoV2Request,
  createPumpFunRequest,
  createSellInfoRequest,
  createTokenAddressRequest,
  createTokenInfoRequest,
  deleteTokenInfoRequest,
  getBootTxListRequest,
  getBootVolumeTxListRequest,
  getContractAddressRequest,
  getListWalletBuyRequest,
  getListWalletSellRequest,
  getListWalletVolumeRequest,
  getPumpFunListRequest,
  getPumpfunMintRequest,
  getSnipSolRequest,
  getTokenDetailsRequest,
  getTokenHoldersRequest,
  getTokenListRequest,
  getTokenOptionsRequest,
  importBootTxRequest,
  importBootVolumeTxRequest,
  importTokenRequest,
  importWalletBuyRequest,
  importWalletSellRequest,
  importWalletVolumeRequest,
  refreshTokenRequest,
  resetTokenInfoRequestStatus,
  revokeFreezeTokenRequest,
  revokeMintTokenRequest,
  rugPoolTokenRequest,
  sendMultiSolRequest,
  sendMultiTokenRequest,
  sendSolRequest,
  sendSolV2Request,
  snipSolRequest,
  startFakeBuyRequest,
  startFakeSellRequest,
  startFakeVolumeRequest,
  updateTokenImageRequest,
  updateTokenInfoRequest,
  updateVolumeBuyRequest,
  updateVolumeSellRequest,
  updateVolumeVolumeRequest,
} from '@/stores/slices/token/token';
import _ from 'lodash';
import React, { useEffect } from 'react';

const useTokenHook: any = () => {
  const {
    getTokenDetailsStatus,
    getTokenListStatus,
    getTokenHolderStatus,
    getSnipSolStatus,
    updateTokenInfoStatus,
    createTokenInfoStatus,
    createTokenAddressStatus,
    deleteTokenInfoStatus,
    revokeMintTokenStatus,
    revokeFreezeTokenStatus,
    refreshTokenStatus,
    uploadTokenImageStatus,
    sendMultiSolStatus,
    tokenDetails,
    tokens,
    tokenOptions,
    getTokenOptionsStatus,
    holders,
    revokeMsg,
    burnTokenStatus,
    rugPoolTokenStatus,
    autoRugPoolTokenStatus,
    sendSolStatus,
    sendSolV2Status,
    snipSolStatus,
    snipMsg,
    snips,
    multiSenderData,
    marketData,
    createBookStatus,
    createBookMarketStatus,
    createPoolStatus,
    createPoolV2Status,
    getPumpFunListStatus,
    createPumpFunStatus,
    createBuyStatus,
    createSellStatus,
    pumpFunList,
    buy,
    sell,
    pumpfun,
    pumpfunMint,
    getPumpfunMintStatus,
    sendMultiTokenStatus,
    multiToken,
    importTokenStatus,
    getContractAddressStatus,
    contractAddress,
    listTransaction,
    importBootTxStatus,
    bootTxList,
    getBootTxListStatus,
    importWalletBuyStatus,
    importWalletSellStatus,
    importWalletVolumeStatus,
    walletBuy,
    walletSell,
    walletVolume,
    updateVolumeBuyStatus,
    updateVolumeSellStatus,
    updateVolumeVolumeStatus,
    getListWalletBuyStatus,
    getListWalletSellStatus,
    getListWalletVolumeStatus,
    startFakeBuyStatus,
    startFakeSellStatus,
    startFakeVolumeStatus,
    listWalletBuy,
    listWalletSell,
    listWalletVolume,
    bootVolumeTxList,
    importBootVolumeTxStatus,
    getBootVolumeListStatus,
    listSignatureBuy,
    listSignatureSell,
    listSignatureVolume,
  } = useAppSelector(selectToken);

  const dispatch = useAppDispatch();
  const [tokenOptionsList, setTokenOptionsList] = React.useState<any[]>([]);

  useEffect(() => {
    if (tokenOptions?.length) {
      const newOptions = _.map(tokenOptions, v => ({
        value: v.id,
        label: v.name,
      }));
      setTokenOptionsList(newOptions);
    }

    // eslint-burn-next-line react-hooks/exhaustive-deps
  }, [tokenOptions]);

  const requestTokenOptions = (params: any) =>
    dispatch(getTokenOptionsRequest(params));

  const requestTokenList = (params: any) =>
    dispatch(getTokenListRequest(params));

  const requestTokenHolders = (params: any) =>
    dispatch(getTokenHoldersRequest(params));

  const requestGetSnipSol = (params: any) =>
    dispatch(getSnipSolRequest(params));

  const requestTokenDetails = (params: any) =>
    dispatch(getTokenDetailsRequest(params));

  const requestUpdateTokenInfo = (params: any) =>
    dispatch(updateTokenInfoRequest(params));

  const requestUpdateTokenImage = (params: any) =>
    dispatch(updateTokenImageRequest(params));

  const requestCreateToken = (params: any) =>
    dispatch(createTokenInfoRequest(params));

  const requestCreateTokenAddress = (params: any) =>
    dispatch(createTokenAddressRequest(params));

  const requestDeleteToken = (params: any) =>
    dispatch(deleteTokenInfoRequest(params));

  const requestRevokeMintToken = (params: any) =>
    dispatch(revokeMintTokenRequest(params));

  const requestRevokeFreezeToken = (params: any) =>
    dispatch(revokeFreezeTokenRequest(params));

  const requestRugPoolToken = (params: any) =>
    dispatch(rugPoolTokenRequest(params));

  const requestAutoRugPoolToken = (params: any) =>
    dispatch(autoRugPoolTokenRequest(params));

  const requestburnToken = (params: any) => dispatch(burnTokenRequest(params));

  const requestRefreshToken = (params: any) =>
    dispatch(refreshTokenRequest(params));

  const requestSendSol = (params: any) => dispatch(sendSolRequest(params));

  const requestSendSolV2 = (params: any) => dispatch(sendSolV2Request(params));

  const requestSnipSol = (params: any) => dispatch(snipSolRequest(params));

  const requestSendMultiSol = (params: any) =>
    dispatch(sendMultiSolRequest(params));

  const requestCreateBook = (params: any) =>
    dispatch(createBookInfoRequest(params));

  const requestCreateBookMarket = (params: any) =>
    dispatch(createBookMarketInfoRequest(params));

  const requestCreatePool = (params: any) =>
    dispatch(createPoolInfoRequest(params));

  const requestCreatePoolV2 = (params: any) =>
    dispatch(createPoolInfoV2Request(params));

  const requestResetToken = () => dispatch(resetTokenInfoRequestStatus());

  const requestPumpFunList = (params: any) =>
    dispatch(getPumpFunListRequest(params));

  const requestCreatePumpFun = (params: any) =>
    dispatch(createPumpFunRequest(params));

  const requestCreateBuy = (params: any) =>
    dispatch(createBuyInfoRequest(params));

  const requestCreateSell = (params: any) =>
    dispatch(createSellInfoRequest(params));

  const requestGetPumpfunMintRequest = () => dispatch(getPumpfunMintRequest());

  const requestSendMultiToken = (params: any) =>
    dispatch(sendMultiTokenRequest(params));

  const requestImportToken = (params: any) =>
    dispatch(importTokenRequest(params));

  const requestContractAddress = (params: any) =>
    dispatch(getContractAddressRequest(params));

  const requestImportBootTx = (params: any) =>
    dispatch(importBootTxRequest(params));

  const requestBootTxList = (params: any) =>
    dispatch(getBootTxListRequest(params));

  const requestImportWalletBuy = (params: any) =>
    dispatch(importWalletBuyRequest(params));

  const requestImportWalletSell = (params: any) =>
    dispatch(importWalletSellRequest(params));

  const requestImportWalletVolume = (params: any) =>
    dispatch(importWalletVolumeRequest(params));

  const requestUpdateVolumeBuy = (params: any) =>
    dispatch(updateVolumeBuyRequest(params));
  const requestUpdateVolumeSell = (params: any) =>
    dispatch(updateVolumeSellRequest(params));
  const requestUpdateVolumeVolume = (params: any) =>
    dispatch(updateVolumeVolumeRequest(params));
  const requestGetListWalletBuy = (params: any) =>
    dispatch(getListWalletBuyRequest(params));
  const requestGetListWalletSell = (params: any) =>
    dispatch(getListWalletSellRequest(params));
  const requestGetListWalletVolume = (params: any) =>
    dispatch(getListWalletVolumeRequest(params));

  const requestStartFakeBuy = (params: any) =>
    dispatch(startFakeBuyRequest(params));
  const requestStartFakeSell = (params: any) =>
    dispatch(startFakeSellRequest(params));
  const requestStartFakeVolume = (params: any) =>
    dispatch(startFakeVolumeRequest(params));

  const requestImportBootVolumeTx = (params: any) =>
    dispatch(importBootVolumeTxRequest(params));

  const requestBootVolumeTxList = (params: any) =>
    dispatch(getBootVolumeTxListRequest(params));

  return {
    requestTokenOptions,
    requestTokenList,
    requestTokenDetails,
    requestUpdateTokenInfo,
    requestCreateToken,
    requestDeleteToken,
    requestResetToken,
    requestRevokeMintToken,
    requestRevokeFreezeToken,
    requestUpdateTokenImage,
    requestTokenHolders,
    requestCreateTokenAddress,
    requestRugPoolToken,
    requestburnToken,
    requestRefreshToken,
    requestGetSnipSol,
    requestSnipSol,
    requestSendSol,
    requestSendSolV2,
    requestSendMultiSol,
    requestCreateBook,
    requestCreatePool,
    requestCreatePoolV2,
    requestAutoRugPoolToken,
    requestCreateBookMarket,
    requestCreatePumpFun,
    requestCreateBuy,
    requestCreateSell,
    requestPumpFunList,
    requestGetPumpfunMintRequest,
    requestSendMultiToken,
    requestImportToken,
    requestContractAddress,
    requestImportBootTx,
    requestBootTxList,
    requestImportWalletBuy,
    requestImportWalletSell,
    requestImportWalletVolume,
    requestUpdateVolumeBuy,
    requestUpdateVolumeSell,
    requestUpdateVolumeVolume,
    requestGetListWalletBuy,
    requestGetListWalletSell,
    requestGetListWalletVolume,
    requestStartFakeBuy,
    requestStartFakeSell,
    requestStartFakeVolume,
    requestImportBootVolumeTx,
    requestBootVolumeTxList,
    getTokenDetailsStatus,
    revokeMintTokenStatus,
    revokeFreezeTokenStatus,
    uploadTokenImageStatus,
    getTokenListStatus,
    tokenOptions,
    tokenOptionsList,
    getTokenOptionsStatus,
    updateTokenInfoStatus,
    createTokenInfoStatus,
    createTokenAddressStatus,
    deleteTokenInfoStatus,
    getTokenHolderStatus,
    burnTokenStatus,
    rugPoolTokenStatus,
    autoRugPoolTokenStatus,
    refreshTokenStatus,
    sendMultiSolStatus,
    sendSolStatus,
    sendSolV2Status,
    snipSolStatus,
    getSnipSolStatus,
    tokenDetails,
    tokens,
    holders,
    revokeMsg,
    snipMsg,
    snips,
    multiSenderData,
    createBookStatus,
    createBookMarketStatus,
    createPoolStatus,
    createPoolV2Status,
    marketData,
    createPumpFunStatus,
    getPumpFunListStatus,
    pumpFunList,
    createBuyStatus,
    createSellStatus,
    buy,
    sell,
    pumpfun,
    pumpfunMint,
    getPumpfunMintStatus,
    sendMultiTokenStatus,
    multiToken,
    importTokenStatus,
    getContractAddressStatus,
    contractAddress,
    listTransaction,
    importBootTxStatus,
    bootTxList,
    getBootTxListStatus,
    importWalletBuyStatus,
    importWalletSellStatus,
    importWalletVolumeStatus,
    walletBuy,
    walletSell,
    walletVolume,
    updateVolumeBuyStatus,
    updateVolumeSellStatus,
    updateVolumeVolumeStatus,
    getListWalletBuyStatus,
    getListWalletSellStatus,
    getListWalletVolumeStatus,
    startFakeBuyStatus,
    startFakeSellStatus,
    startFakeVolumeStatus,
    listWalletBuy,
    listWalletSell,
    listWalletVolume,
    bootVolumeTxList,
    importBootVolumeTxStatus,
    getBootVolumeListStatus,
    listSignatureBuy,
    listSignatureSell,
    listSignatureVolume,
  };
};

export default useTokenHook;
