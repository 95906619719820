import { Box } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { styled } from 'styled-components';
import { DirectionType, InputType } from './customSelect';
import { ErrorMessage } from './input';
import { InputName, InputRequired } from './inputName';
import { InputContainer, InputWrapper } from './styled';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelName?: string;
  error?: string;
  isRequired?: boolean;
  inputType?: number;
  width?: number;
  value?: string | number;
  fromType?: InputType;
  direction?: DirectionType;
  verticalLabel?: boolean;
  noBottom?: boolean;
  max?: number;
}
export const NumberInputFormat = styled(
  NumericFormat<{
    $hasError?: boolean;
    $inputType?: number;
    $width?: number;
    $fromType?: InputType;
  }>,
)`
  box-sizing: border-box;
  width: ${props => (props.$width ? props.$width + 'px' : '100%')};
  padding: ${props =>
    props.$inputType === 1
      ? '8px 12px 8px 30px'
      : props.$fromType === InputType.POP_UP
      ? '8px 12px'
      : props.$fromType === InputType.CALCULATION
      ? '8px 200px 8px 12px'
      : '8px 12px 8px 60px'};
  border: 1px solid
    ${props =>
      props.$hasError
        ? '#e34a66'
        : props.$fromType === InputType.POP_UP
        ? 'var(--black)'
        : '#000'};
  border-radius: 4px;
  background: ${props =>
    props.$fromType === InputType.MAPPING
      ? '#40495C'
      : props.$fromType === InputType.DARK
      ? '#26282B'
      : '#fff'};
  color: ${props =>
    props.$fromType === InputType.MAPPING ? '#fff' : '#222222 '};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #ccc;
  }
`;

const NumberInput: React.FC<InputProps> = ({
  label,
  error,
  isRequired = false,
  inputType = 0,
  width = 0,
  fromType = InputType.POP_UP,
  direction = DirectionType.COLUMN,
  labelName,
  verticalLabel = true,
  onChange,
  noBottom,
  ...inputProps
}) => {
  return (
    <InputContainer
      style={{
        marginBottom: noBottom ? 0 : '2rem',
        width: width ? `${width}%` : 'auto',
      }}
    >
      <Box
        sx={{
          display: direction === DirectionType.COLUMN ? 'block' : 'flex',
        }}
      >
        {verticalLabel && (
          <InputName>
            {labelName || label}{' '}
            <InputRequired>{isRequired && '*'}</InputRequired>
          </InputName>
        )}
        <InputWrapper>
          {fromType === InputType.WITH_SUB && (
            <span className="text-slate-400 sm:text-sm">
              1e<sup>-x</sup>
            </span>
          )}
          <NumberInputFormat
            thousandSeparator=","
            onChange={onChange}
            $fromType={fromType}
            value={inputProps.value}
            placeholder={inputProps.placeholder}
            disabled={inputProps.disabled}
            max={inputProps.max}
          />
          {fromType === InputType.CALCULATION && (
            <span className="text-slate-400 rear-input sm:text-sm">
              {inputProps.value
                ? +inputProps.value?.toString()?.replace(/,/g, '') * 88
                : 44}{' '}
              bytes
            </span>
          )}
        </InputWrapper>
      </Box>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  );
};

export default NumberInput;
