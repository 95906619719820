import React from 'react';

import { MenuItem, SelectProps } from '@mui/material';
import { SelectIcon } from '../icons';
import { InputType } from './customSelect';
import {
  ErrorMessage,
  InputContainer,
  InputWrapper,
  OptionText,
  SelectField,
} from './styled';

interface SelectInputProps {
  label?: string;
  errorMess?: string;
  value?: string | number;
  isRequired?: boolean;
  width?: number;
  options: { value: string | number; label: string | number }[];
  inputType?: InputType;
}

const SelectItem: React.FC<SelectInputProps & SelectProps> = ({
  label,
  value,
  errorMess,
  isRequired = false,
  options = [],
  placeholder,
  width = 0,
  inputType = InputType.POP_UP,
  ...inputProps
}) => {
  return (
    <InputContainer>
      {/* {label && (
        <Label $fromType={inputType}>
          {label}
          {isRequired && <span>*</span>}
        </Label>
      )} */}

      <InputWrapper>
        <SelectField
          {...inputProps}
          $inputType={inputType}
          value={value ? value?.toString() : ''}
          IconComponent={props => <SelectIcon />}
          displayEmpty
          MenuProps={{
            disablePortal: true,
          }}
          disabled={!(options && options?.length)}
          $width={width}
          $hasError={!!errorMess}
          renderValue={(value: any) => {
            const selectedOption = options.find(
              it => it.value === value || it.value?.toString() === value,
            );

            if (value && selectedOption) {
              return selectedOption.label;
            }
            return (
              <OptionText $inputType={inputType}>{placeholder}</OptionText>
            );
          }}
        >
          {(options || []).map((v, k) => {
            return (
              <MenuItem value={v.value} key={k}>
                <OptionText
                  $inputType={inputType}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    minWidth: '500px',
                  }}
                  className="form_text"
                >
                  {v.label}
                </OptionText>
              </MenuItem>
            );
          })}
        </SelectField>
      </InputWrapper>
      {errorMess && <ErrorMessage>{errorMess}</ErrorMessage>}
    </InputContainer>
  );
};

export default SelectItem;
