import { Logo } from '@/components/layouts/logo';
import { SIDEBAR_ITEMS } from '@/constants/layout';
import Path from '@/constants/path';
import useGlobalHook from '@/hooks/useGlobalHook';
import { Box, Drawer } from '@mui/material';
import { useMemo } from 'react';

import { MaterialIcon } from '../icons';
import { AddTokenIcon } from '../icons/AddToken';
import { BootTxIcon } from '../icons/BootTx';
import { CategoryIcon } from '../icons/Category';
import { ClaimIcon } from '../icons/Claim';
import { GroupIcon } from '../icons/Group';
import { MoreIcon } from '../icons/More';
import { PillIcon } from '../icons/Pill';
import { PumpfunIcon } from '../icons/Pumpfun';
import { PumpfunListIcon } from '../icons/PumpfunList';
import { SendTokenIcon } from '../icons/SendToken';
import { TokenNewIcon } from '../icons/TokenIcon';
import { VolumeIcon } from '../icons/Volume';
import { WalletIcon } from '../icons/Wallet';
import { Header } from './header';
import { SidebarItem } from './sidebar/item';

interface LayoutProps {
  children: any;
}
const Layout = ({ children }: LayoutProps) => {
  const { showSidebar } = useGlobalHook();

  const renderMenu = useMemo(
    () => (
      <>
        <SidebarItem
          name={'Raydium zone'}
          icon={<TokenNewIcon />}
          to={''}
          isActive
          items={[
            {
              id: 1,
              name: SIDEBAR_ITEMS.token_list,
              icon: <CategoryIcon />,
              to: Path.TOKEN_LIST,
            },
            {
              id: 2,
              name: SIDEBAR_ITEMS.create_token,
              icon: <AddTokenIcon />,
              to: Path.CREATE_TOKEN,
            },
            {
              id: 3,
              name: SIDEBAR_ITEMS.boot_buy_tx,
              icon: <BootTxIcon />,
              to: Path.BOOT_BUY,
            },
            {
              id: 4,
              name: SIDEBAR_ITEMS.boot_volume_tx,
              icon: <VolumeIcon />,
              to: Path.BOOT_VOLUME,
            },
            {
              id: 5,
              name: SIDEBAR_ITEMS.create_book,
              icon: <MaterialIcon />,
              to: Path.CREATE_BOOK,
            },
          ]}
        />
        <SidebarItem
          name={'Pumpfun zone'}
          icon={<MoreIcon />}
          to={''}
          items={[
            {
              id: 1,
              name: SIDEBAR_ITEMS.create_pumpfun_token,
              icon: <PumpfunIcon />,
              to: Path.CREATE_PUMPFUN_TOKEN,
            },
            {
              id: 3,
              name: SIDEBAR_ITEMS.pumpfun_tokens,
              icon: <PumpfunListIcon />,
              to: Path.PUMPFUN_TOKENS,
            },
            {
              id: 5,
              name: SIDEBAR_ITEMS.pump_boot_buy_tx,
              icon: <BootTxIcon />,
              to: Path.BOOT_TX_PUMP,
            },
            {
              id: 5,
              name: SIDEBAR_ITEMS.Pump_boot_volume_tx,
              icon: <VolumeIcon />,
              to: Path.BOOT_VOLUME_PUMP,
            },
          ]}
        />
        <SidebarItem
          name={SIDEBAR_ITEMS.send_sol}
          icon={<SendTokenIcon />}
          to={Path.SEND_SOL}
        />
        <SidebarItem
          name={SIDEBAR_ITEMS.pumpfun_trader}
          icon={<PillIcon />}
          to={Path.TRADERS}
        />
        <SidebarItem
          name={SIDEBAR_ITEMS.gen_wallet}
          icon={<WalletIcon />}
          to={Path.GEN_WALLET}
        />
        <SidebarItem
          name={SIDEBAR_ITEMS.multi_sender}
          icon={<GroupIcon />}
          to={Path.MULTI_SENDER}
        />
        <SidebarItem
          name={SIDEBAR_ITEMS.claim_sol}
          icon={<ClaimIcon />}
          to={Path.CLAIM_SOL}
        />
      </>
    ),
    [],
  );

  return (
    <div style={{ display: 'flex' }}>
      <Header />
      <Drawer
        anchor="left"
        open={showSidebar}
        disableEnforceFocus
        hideBackdrop={true}
      >
        <Logo />
        <Box>{renderMenu}</Box>
      </Drawer>
      <main
        style={{
          flexGrow: 1,
          padding: showSidebar ? '64px 20px 64px 250px' : '64px 20px',
          background: 'var(--white)',
        }}
      >
        {/* <Toolbar /> */}
        {children}
      </main>
    </div>
  );
};

export default Layout;
