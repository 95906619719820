/* eslint-disable max-lines */
import PopUp from '@/components/common/Popup';
import { InputContainer } from '@/components/form/styled';
import TextArea from '@/components/form/textarea';
import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberInput from '@/components/form/numberInput';

import { TokenForm, TokenFormType } from './form';
import { TokenDetail } from '@/stores/slices/token/type';
import Input from '@/components/form/input';

// import { ImageInput } from '@/components/form/imageUploading';
export interface TokenPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: any) => void;
  data?: TokenDetail;
  type: POPUP;
}

export enum POPUP {
  HOLDER = 1,
  METADATA = 2,
  SNIP = 3,
}

export function TokenPopup({
  open,
  onClose,
  onSubmit,
  type = POPUP.HOLDER,
  data,
}: TokenPopupProps) {
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<{
    privateKeys: string;
    privateKey: string;
    amount?: number;
    website?: string;
    telegram?: string;
    twitter?: string;
  }>({
    defaultValues: {
      privateKeys: '',
      privateKey: '',
      amount: 0,
    },
  });

  const submitForm = (formDt: any) => {
    if (type === POPUP.HOLDER) {
      delete formDt.privateKey;
    } else {
      delete formDt.privateKeys;
    }
    formDt.amount = formDt.amount?.toString().replace(/,/g, '');
    onSubmit({
      ...formDt,
      amount: formDt.amount ? +formDt.amount : 0,
    });
    reset();
  };

  const onCancel = (e: any) => {
    e.preventDefault();
    onClose();
  };
  const title = useMemo(() => {
    switch (type) {
      case POPUP.HOLDER:
        return 'Add Wallet';
      case POPUP.SNIP:
        return 'Add Snip';
      default:
        return 'Edit Metadata';
    }
  }, [type]);

  const getMetaData = async (data: any) => {
    const res = await fetch(data.uri);
    if (res.ok) {
      const dt = await res.json();
      setValue('website', dt.extensions?.website);
      setValue('telegram', dt.extensions?.telegram);
      setValue('twitter', dt.extensions?.twitter);
    }
  };
  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    if (data && type === POPUP.METADATA) {
      getMetaData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <PopUp title={title} onClose={onClose} open={open}>
      {type === POPUP.HOLDER || type === POPUP.SNIP ? (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
          {type === POPUP.HOLDER ? (
            <InputContainer className="single">
              <Controller
                control={control}
                name="privateKeys"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <TextArea
                      label={t('Enter private key')}
                      isRequired
                      error={errors?.privateKeys?.message}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </InputContainer>
          ) : (
            <>
              <InputContainer className="single">
                <Controller
                  control={control}
                  name="privateKey"
                  rules={{
                    required: t('This field is required'),
                  }}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <Input
                        label={t('Enter private key')}
                        isRequired
                        error={errors?.privateKey?.message}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </InputContainer>
              <InputContainer className="single">
                <Controller
                  control={control}
                  name="amount"
                  rules={{
                    required: t('This field is required'),
                  }}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <NumberInput
                        label={'Amount'}
                        value={value}
                        onChange={onChange}
                        isRequired
                        error={errors?.amount?.message}
                        placeholder="Amount"
                      />
                    );
                  }}
                />
              </InputContainer>
            </>
          )}

          <Box
            component="div"
            sx={{
              margin: '40px 0 0',
              display: 'grid',
              gridTemplateColumns:
                i18n.resolvedLanguage === 'ja' ? '150px 210px' : '150px 160px',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '150px 150px',
                gap: '20px',
              }}
            >
              <button
                className="main_btn button new_btn multi_color_btn secondary_btn"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                className="main_btn button new_btn multi_color_btn"
                type="submit"
              >
                Submit
              </button>
            </Box>
          </Box>
        </Box>
      ) : (
        <TokenForm
          type={TokenFormType.EDIT}
          onSubmit={onSubmit}
          onCancel={onClose}
          data={data}
        />
      )}
    </PopUp>
  );
}
