import Editor from '@monaco-editor/react';
import { useRef } from 'react';
import styled from 'styled-components';
import { InputName, InputRequired } from './inputName';

type TextareaProps = {
  label: string | undefined;
  value: string;
  onChange: any;
  placeholder?: string;
  name?: string;
  error?: string;
  customError?: string;
  description?: string;
  subDescription?: string;
};

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;

const ErrorMessage = styled.span`
  color: #e34a66;
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin-top: 10px;
`;

const DescriptionMessage = styled.span`
  color: #444444;
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin-top: 10px;
  font-style: italic;
`;

const AreaField = styled.div<{
  $hasError?: boolean;
  $width?: number;
}>`
  font-family: 'Poppins';
  width: ${props => (props.$width ? props.$width - 24 + 'px' : '100%')};
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--black);
  background: '#2222';
  color: '#222222 ';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  &:focus {
    outline: none;
  }
`;

export const MonacoTextarea = ({
  label,
  error,
  value,
  onChange,
  customError,
  description,
  subDescription,
}: TextareaProps) => {
  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  return (
    <>
      <InputContainer>
        <InputName>
          {label}
          <InputRequired>{'*'}</InputRequired>
        </InputName>

        <InputWrapper>
          <AreaField>
            <Editor
              height="300px"
              value={value}
              onMount={handleEditorDidMount}
              onChange={onChange}
            />
          </AreaField>
        </InputWrapper>
        {description && <DescriptionMessage>{description}</DescriptionMessage>}
        {subDescription && (
          <DescriptionMessage>{subDescription}</DescriptionMessage>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {customError && <ErrorMessage>{customError}</ErrorMessage>}
      </InputContainer>
    </>
  );
};
