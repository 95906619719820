/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import NumberInput from '@/components/form/numberInput';
import usePumpHook from '@/hooks/usePumpHook';
import { TypeTrader } from '@/pages/pumpfunTraderDetail';
import { Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

export interface PumpfunCardProps {
  handleSubmitBuyAndSell: any;
}

const CardContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #c4c6ce;
  border-radius: 4px;
`;

const BoxPercentContainer = styled(Box)`
  width: 25%;
  background-color: #c4c6ce;
  display: flex;
  justify-content: center;
  padding: 10px 0 10px 0;
  border-radius: 4px;
`;

export function PumpfunCard({ handleSubmitBuyAndSell }: PumpfunCardProps) {
  const {
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    amount: number | string;
    percent: number | string;
  }>({
    defaultValues: {
      // amount: 0,
      // percent: 0,
    },
  });
  const { listTraderActive } = usePumpHook();
  const watchValueAmount = watch('amount');
  const watchValuePercent = watch('percent');

  const [buyValue, setBuyValue] = useState<string | number>(0);
  const [sellValue, setSellValue] = useState<string | number>(0);

  const onBuy = async () => {
    handleSubmitBuyAndSell(listTraderActive, String(buyValue), TypeTrader.BUY);
  };
  const onSell = async () => {
    handleSubmitBuyAndSell(
      listTraderActive,
      Number(sellValue),
      TypeTrader.SELL,
    );
  };
  useEffect(() => {
    if (watchValueAmount) {
      setBuyValue(watchValueAmount);
    }
    if (watchValuePercent) {
      setSellValue(watchValuePercent);
    }
  }, [watchValueAmount, watchValuePercent]);
  return (
    <Grid container spacing={2} sx={{ marginTop: '0px' }}>
      <Grid item xs={6}>
        <Box
          sx={{
            boxShadow: '6px 6px 9px 3px rgba(0,0,0,0.2)',
            borderRadius: '4px',
            padding: '14px',
            border: '1px solid rgba(0,0,0,0.2)',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6.5}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '& .active': {
                    'background-color': '#2C2D30 !important',
                  },
                  marginTop: '8px',
                }}
              >
                {[1, 2, 3].map(value => {
                  return (
                    <CustomButton
                      width={'31%'}
                      key={value}
                      buttonType={CustomButtonType.CUSTOM}
                      className={value === buyValue ? 'active' : ''}
                      title={`${value} SOL`}
                      backgroundColorBtn="#a1a1aa"
                      textColor="#fff"
                      onClick={() => {
                        setBuyValue(value);
                        setSellValue(0);
                        setValue('amount', '');
                      }}
                    />
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={3.5}>
              <Controller
                control={control}
                name="amount"
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      label={''}
                      value={value}
                      onChange={onChange}
                      placeholder="Amount"
                      noBottom
                      error={errors?.amount?.message}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '8px',
                }}
              >
                <Button
                  className="main_btn button new_button_padding multi_color_btn_white"
                  onClick={() => {
                    if (buyValue) onBuy();
                  }}
                >
                  BUY
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            boxShadow: '6px 6px 9px 3px rgba(0,0,0,0.2)',
            borderRadius: '4px',
            padding: '14px',
            border: '1px solid rgba(0,0,0,0.2)',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6.5}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '8px',
                  '& .active': {
                    'background-color': '#2C2D30 !important',
                  },
                }}
              >
                {[20, 50, 100].map(value => {
                  return (
                    <CustomButton
                      width={'30%'}
                      key={value}
                      buttonType={CustomButtonType.CUSTOM}
                      title={value === 100 ? 'All' : value + '%'}
                      backgroundColorBtn="#a1a1aa"
                      textColor="#fff"
                      className={value === sellValue ? 'active' : ''}
                      onClick={() => {
                        setSellValue(value);
                        setBuyValue(0);
                        setValue('percent', '');
                      }}
                    />
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={3.5}>
              <Controller
                control={control}
                name="percent"
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      label={''}
                      value={value}
                      onChange={onChange}
                      placeholder="Percent"
                      noBottom
                      error={errors?.percent?.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '8px',
                }}
              >
                <Button
                  className="main_btn button new_button_padding multi_color_btn_white"
                  size="small"
                  onClick={() => {
                    if (sellValue) onSell();
                  }}
                >
                  SELL
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
