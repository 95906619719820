/* eslint-disable max-lines */
import { Box } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PaginationContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
`;

export const TableContainer = styled.div`
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: auto;
  &.top {
    position: static;
    box-sizing: border-box;
    width: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr > td:nth-child(2) {
    font-weight: 700;
  }
  tr.selected {
    background: #e4f0ff !important;
  }
  tr:nth-child(2n) {
    background: #f8f9fc;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 4px #d9d9d9;
  }
`;

export const TopTable = styled(Box)`
  display: grid;
  grid-template-columns: 5fr 10fr 25fr 20fr 20fr 20fr;
  gap: 0;
  align-items: start;
`;

export const TokenTable = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 10fr 10fr 20fr 5fr 20fr 20fr;
  gap: 0;
  align-items: start;
`;
export const TopTableHeader = styled(Box)`
  padding: 16px;
  gap: 10px;
  color: #fff;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  background: #2c2d30;
  line-height: 22px;
  &.top {
    border-radius: 6px 0 0 0;
    background: #3f3f3f;
  }

  &.last {
    border-radius: 0px 6px 0px 0px;
    background: #3f3f3f;
    text-align: right;
  }
  &.width_5 {
    width: 500px;
  }
  &.width_4 {
    width: 400px;
  }
  &.width_2 {
    width: 200px;
  }
  &.width_1 {
    width: 150px;
  }
  &.no {
    width: 70px;
  }
`;

export const TopCell = styled(Box)`
  padding: 16px;
  gap: 10px;
  text-align: left;
  font-size: 16px;
  margin: 6px 0;
  background: #2c2d30;
  color: #fff;
  &.p_8 {
    padding: 8px 16px;
  }
  img {
    max-width: 32px;
  }
  &.top {
    border-radius: 6px 6px 0 0;
  }

  &.last {
    border-radius: 0px 0 6px 0px;
    text-align: right;
  }
  &.width_200 {
    width: 500px;
  }
  &.width_400 {
    width: 400px;
  }
  &.width_500 {
    width: 500px;
  }
  &.width_600 {
    width: 600px;
  }
  &.width_30 {
    width: 80px;
  }
  &.width_50 {
    width: 120px;
  }
  &.width_100 {
    width: 100px;
  }
  &.width_15 {
    width: 200px;
  }
  &.width_12 {
    width: 150px;
  }
  &.width_7 {
    width: 70px;
  }
  &.word_break {
    word-break: break-word;
  }
`;

export const TopRowContainer = styled(Box)`
  display: grid;
  grid-template-columns: 10fr 40fr 30fr 20fr;
  gap: 0;
  align-items: start;
  background: #2c2d30;
  &.first {
    border-radius: 6px 6px 0 0;
    background: #3f3f3f;
    > div {
      background: #3f3f3f;
    }
  }
  &.last {
    border-radius: 0 0 6px 6px;
    text-align: right;
  }
`;

export const SnipRowContainer = styled(Box)`
  display: grid;
  grid-template-columns: 5fr 35fr 15fr 8fr 32fr;
  gap: 0;
  align-items: start;
  background: #2c2d30;
  &.first {
    border-radius: 6px 6px 0 0;
    background: #3f3f3f;
    > div {
      background: #3f3f3f;
    }
  }
  &.last {
    border-radius: 0 0 6px 6px;
    text-align: right;
  }
`;

export const TokenRowContainer = styled(Box)`
  display: grid;
  grid-template-columns: 5fr 15fr 5fr 45fr 30fr 5fr;
  gap: 0;
  align-items: center;
  background: #2c2d30;
  max-width: calc(100vw - 260px);
  min-width: 1000px;
  &.first {
    border-radius: 6px 6px 0 0;
    background: #3f3f3f;
    > div {
      background: #3f3f3f;
    }
  }
  &.last {
    border-radius: 0 6px 0 0px;
    text-align: right;
  }
  &:hover {
    background: #3f3f3f;
    > div {
      background: #3f3f3f;
    }
  }
`;
export const PublishContainer = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
`;

export const TableHeader = styled.th`
  height: 44px;
  padding: 0px 16px;
  gap: 10px;
  align-self: stretch;
  background-color: #2c2d30;
  border: 1px solid #babec9;
  color: #fff;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;

  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.top {
    background: #3080ec;
  }
`;

export const TableCell = styled.td`
  padding: 16px;
  text-align: left;
  gap: 10px;
  align-self: stretch;
  border: 1px solid #e9ecf6;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > img {
    max-width: 40px;
  }
  ul {
    list-style-type: none;
  }
  li {
    display: block;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ClippedText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const ActionCell = styled.td`
  border: 1px solid #e9ecf6;
  padding: 10px;
  text-align: center;
  * {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
`;

export const TooltipWrapper = styled.span`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 220px;
  top: 10px;
  width: 24px;
  height: 24px;
  svg {
    fill: #fff;
  }
`;
export const Text = styled.span`
  padding-left: 10px;
  white-space: nowrap;
  color: #fff;
  &.danger {
    color: #eb5757;
  }
  &.bold {
    font-weight: bold;
  }
`;

export const IconText = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StatusTxt = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  &.blue {
    color: #03ba5e;
  }
`;

export const LinkText = styled(Link)`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  text-decoration: none;
  /* &:hover {
    text-decoration: underline;
  } */
  svg path {
    fill: #fff;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  svg path {
    stroke: #fff;
  }
`;

export const CustomLink = styled.a`
  font-size: 16px;
  color: #3080ec;
  line-height: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const AntSwitch = styled(Switch)`
  && {
    width: 40px;
    height: 20px;
    padding: 0;
    &:active {
      .MuiSwitch-thumb {
        width: 18px;
      }
      .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(15px);
      }
    }
    .MuiSwitch-switchBase {
      padding: 2px;
    }
    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(15px);
      color: #fff;
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: #a43399;
      }
    }
    .MuiSwitch-thumb {
      box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
      width: 20px;
      height: 15px;
      border-radius: 9px;
      transition: 1s ease out all;
    }
    .MuiSwitch-track {
      border-radius: 8px;
      opacity: 1;
      background-color: #222;
      box-sizing: border-box;
    }
  }
`;
