import { Header } from '@/components/layouts/header';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1 1 0;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
`;

export const CommonContainer = styled(Box)`
  && {
    max-width: 1440px;
    margin: 0px auto;
    padding: 0px 15px;
    &.top {
      margin-top: 30px;
    }
    &.top16 {
      margin-top: 16px;
    }
  }
`;

interface ContentProps {
  children: ReactNode;
}

export const Content = ({ children }: ContentProps) => {
  return (
    <Container>
      <Header />
      {children}
    </Container>
  );
};
