export const PillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 64 64"
    height="64"
    viewBox="0 0 64 64"
    width="64"
  >
    <path
      d="m64 20c0-11.047-8.953-20-20-20-5.449 0-10.375 2.191-13.98 5.723l-.02-.02-24.121 24.137c-3.629 3.621-5.879 8.625-5.879 14.16 0 11.047 8.953 20 20 20 5.535 0 10.539-2.25 14.16-5.879l24.137-24.121-.02-.02c3.532-3.605 5.723-8.535 5.723-13.98zm-35.504 32.469c-2.269 2.277-5.289 3.531-8.496 3.531-6.617 0-12-5.383-12-12 0-3.207 1.254-6.227 3.539-8.5l9.148-9.156 16.969 16.969zm24.066-24.086c-.137.141-.27.281-.391.43l-8.855 8.852-16.976-16.981 8.848-8.848c.145-.129.289-.262.43-.398 2.265-2.219 5.241-3.438 8.382-3.438 6.617 0 12 5.383 12 12 0 3.141-1.223 6.117-3.438 8.383z"
      fill="#394240"
    />
    <path
      d="m28.496 52.469c-2.269 2.277-5.289 3.531-8.496 3.531-6.617 0-12-5.383-12-12 0-3.207 1.254-6.227 3.539-8.5l9.148-9.156 16.969 16.969z"
      fill="#f76d57"
    />
    <path
      d="m52.562 28.383c-.137.141-.27.281-.391.43l-8.855 8.852-16.976-16.981 8.848-8.848c.145-.129.289-.262.43-.398 2.265-2.219 5.241-3.438 8.382-3.438 6.617 0 12 5.383 12 12 0 3.141-1.223 6.117-3.438 8.383z"
      fill="#f9ebb2"
    />
  </svg>
);
