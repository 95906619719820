import { TOKEN_ACCESS_KEY, USER_CACHED_DATA } from 'src/constants';

// <Access Token>
export const getToken = () => localStorage.getItem(TOKEN_ACCESS_KEY);

export const setToken = (token: string) =>
  localStorage.setItem(TOKEN_ACCESS_KEY, token);

export const removeToken = (): void => {
  localStorage.removeItem(TOKEN_ACCESS_KEY);
};
export const isHavingToken = () => !!getToken();
// </Access Token>

// <User Profile>
export const getUserCachedDataFromLocalStorage = (): any | null => {
  const data = localStorage.getItem(USER_CACHED_DATA);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};
export const setUserCachedDataFromLocalStorage = (userSessionData: any) =>
  localStorage.setItem(USER_CACHED_DATA, JSON.stringify(userSessionData));

export const removeUserCachedDataFromLocalStorage = () => {
  localStorage.removeItem(USER_CACHED_DATA);
};
