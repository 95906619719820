import { Box, Button, Paper, Typography } from '@mui/material';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  > div {
    width: calc(50% - 10px);
  }
  &.single {
    > div {
      width: 100%;
    }
  }
  &.mt1 {
    margin-top: 20px;
  }
`;

export const Container = styled(Box)`
  max-width: 600px;
  margin: 20px auto;
  &.big {
    max-width: 860px;
  }
  &.tiny {
    max-width: 300px;
  }
  &.more {
    max-width: 900px;
  }
  &.medium {
    max-width: 700px;
  }
`;

export const DeleteButton = styled(Button)`
  && {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #eb5757;
    background: #fff;
    color: #eb5757;
    font-size: 14px;
    text-transform: none;
    white-space: nowrap;
    &:hover {
      opacity: 0.8;
      background: #fff;
    }
  }
`;

export const TabProfile = styled(Paper)`
  && {
    padding: 24px;
    color: #fff;
    max-width: 600px;
    border-radius: 10px;
  }
`;

export const TabContainer = styled(Box)`
  && {
    width: '100%';
    margin: 40px 0;
    .MuiTabs-flexContainer {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiButtonBase-root.MuiTab-root {
      background: var(--secondary-color);
      color: #000;
      padding: 20px 30px;
      border-radius: 8px 8px 0 0;
      &.Mui-selected {
        background: var(--primary-color);
        font-weight: 700;
        color: var(--white);
      }
    }
  }
`;

export const BtnContainer = styled(Box)`
  && {
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 1fr;
    gap: 10px;
    align-self: start;
    .main_btn {
      max-width: 280px;
      cursor: pointer;
    }
    &.row {
      grid-template-columns: 1fr;
      gap: 20px;
    }
    &.two {
      grid-template-columns: 1fr 1fr;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ccc;
      text-decoration: none;
      width: 100%;
      margin-top: 10px;
    }
  }
`;

export const ButtonTabContainer = styled(Box)`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const JumboTron = styled(Box)`
  padding: 30px;
  border-radius: 10px;
  background: #fff;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  &.full {
    width: 100%;
  }
`;

export const FormSection = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  justify-content: start;
  .leftBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TwoFormItem = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  .no_mb {
    margin-bottom: 0;
  }
  &.gap {
    gap: 20px;
  }
`;

export const Para = styled(Typography)`
  && {
    font-size: 14px;
    color: #fff;
    &.small {
      margin-top: 24px;
      font-size: 12px;
    }
    &.big {
      margin-top: 10px;
      font-size: 20px;
      color: #a43399;
    }
  }
`;

export const PriceTag = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background: rgba(164, 51, 153, 0.2);
  color: #fff;
  font-size: 12px;
  width: 120px;
`;
