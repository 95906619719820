/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { CommonContainer } from '@/components/layouts/content';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Container } from '@/components/token/components';
import { ImportBootTxPopup } from '@/components/token/components/importBootTx';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import usePumpHook from '@/hooks/usePumpHook';
import { checkIsRequesting } from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const mockData = [
  {
    id: 1,
    contractAddress: '64TBnwxhci1aQWrJyFS45JeHVH76x478WePn2jyH4xTk',
  },
];
export const PumpfunTrader = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const {
    requestGetListPumpToken,
    getListPumpTokenStatus,
    listPumpToken,
    requestImportPumpToken,
    importPumpTokenStatus,
  } = usePumpHook();

  const reloadData = () => {
    requestGetListPumpToken();
  };

  const onImport = () => {
    setOpen(true);
  };

  const importToken = (payload: any) => {
    requestImportPumpToken({
      addresses: [payload.contractAddress],
    });
    setOpen(false);
  };

  const loading = useMemo(
    () => checkIsRequesting([getListPumpTokenStatus, importPumpTokenStatus]),
    [getListPumpTokenStatus, importPumpTokenStatus],
  );

  useEffect(() => {
    if (importPumpTokenStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to import token')]);
    }
    if (importPumpTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Token is imported!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importPumpTokenStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    setTimeout(() => {
      reloadData();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <Container className="big">
      <CommonContainer className="top">
        <Box
          mt={4}
          mb={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <button
            className="main_btn multi_color_btn button new_btn min_150"
            onClick={onImport}
          >
            Import token
          </button>
        </Box>
        <CommonTable
          type={TableType.PUMPFUN_TRADER_LIST}
          data={listPumpToken}
        />
        <ImportBootTxPopup
          open={open}
          onSubmit={importToken}
          onClose={() => setOpen(false)}
        />
        <Loader show={loading} />
      </CommonContainer>
    </Container>
  );
};
