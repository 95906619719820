import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';

import authReducer from '@/stores/slices/auth/auth';
import globalReducer from '@/stores/slices/global/global';
import pumpReducer from '@/stores/slices/pump/pump';
import tokenReducer from '@/stores/slices/token/token';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    global: globalReducer,
    token: tokenReducer,
    pump: pumpReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
