/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import { Loader } from '@/components/common/loader';
import Input from '@/components/form/input';
import { MonacoTextarea } from '@/components/form/monacoTextArea';
import { InputContainer } from '@/components/form/styled';
import { Title } from '@/components/login';
import { Container } from '@/components/token/components';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import usePumpHook from '@/hooks/usePumpHook';
import { checkIsRequesting, handleValidAddressAndNumber } from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ClaimFormForm {
  receive_address: string;
  wallet_private_key: string;
}

export const ClaimSolPage = () => {
  const { t } = useTranslation();
  const { requestClaimSol, claimSolStatus } = usePumpHook();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const [customValueError, setCustomValueError] = useState('');

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ClaimFormForm>({
    defaultValues: {
      receive_address: '',
      wallet_private_key: '',
    },
  });

  const watchValueWallet = watch('wallet_private_key');
  const valueRowWallet = watchValueWallet?.split(/\r?\n/) ?? [];

  const loading = useMemo(
    () => checkIsRequesting([claimSolStatus]),
    [claimSolStatus],
  );

  const handleSubmitForm = (value: any) => {
    const formatData = {
      destinationWalletAddress: value.receive_address,
      sourcePrivateKeys: valueRowWallet,
    };
    console.log('🚀 ~ handleSubmitForm ~ formatData:', formatData);
    //Call api claim width body
    requestClaimSol(formatData);
    setCustomValueError('');
  };

  useEffect(() => {
    const valueValid = handleValidAddressAndNumber(
      valueRowWallet,
      'please check wallet at number',
      'private',
    );
    if (!valueValid.isValid && watchValueWallet) {
      setCustomValueError(valueValid.messageError);
    } else {
      setCustomValueError('');
    }
  }, [watchValueWallet]);

  useEffect(() => {
    if (claimSolStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Claim sol success!']);
    }

    if (claimSolStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to claim sol')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimSolStatus]);

  return (
    <Container className="medium">
      <Box
        component="div"
        mb={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Title>Claim Sol</Title>
      </Box>
      <Box component="form" onSubmit={handleSubmit(handleSubmitForm)}>
        <InputContainer>
          <Controller
            control={control}
            name="receive_address"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={'Receive Address'}
                  isRequired
                  error={errors?.receive_address?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>
        <InputContainer className="single">
          <Controller
            control={control}
            name="wallet_private_key"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <MonacoTextarea
                  label={'Wallet private key'}
                  value={value}
                  onChange={onChange}
                  error={errors?.wallet_private_key?.message}
                  customError={customValueError}
                />
              );
            }}
          />
        </InputContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={'Claim'}
            className="multi_color_btn main_btn"
          />
        </Box>
      </Box>

      <Loader show={loading} />
    </Container>
  );
};
