export const DownIcon = () => (
  <svg
    fill="#fff"
    width="16px"
    height="16px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>down</title>
    <path d="M11.125 16.313l7.688-7.688 3.594 3.719-11.094 11.063-11.313-11.313 3.5-3.531z"></path>
  </svg>
);
