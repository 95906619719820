/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { InputType } from '@/components/form/customSelect';
import Input from '@/components/form/input';
import NumberInput from '@/components/form/numberInput';
import { InputContainer } from '@/components/form/styled';
import { Title } from '@/components/login';
import { FormSection, JumboTron } from '@/components/token/components';
import { Text } from '@/components/top';
import { BASE_QUOTE, SOL_TYPES } from '@/constants';
import { TokenDetail } from '@/stores/slices/token/type';
import { getAddressFromPrivateKey, validateKey } from '@/utils/helper';
import { Alert, Box } from '@mui/material';
import * as buffer from 'buffer';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GuildLine } from './guildline';

window.Buffer = buffer.Buffer;

export interface WalletType {
  address: string;
  privateKey: string;
}
export enum CREATE_MARKET_TYPE {
  FROM_DETAILS = 1,
  FROM_BOOK = 2,
}

export interface MarketProps {
  onCreate: (data: any) => void;
  data?: TokenDetail;
  type?: CREATE_MARKET_TYPE;
}
export const Market = ({
  data,
  onCreate,
  type = CREATE_MARKET_TYPE.FROM_DETAILS,
}: MarketProps) => {
  const { t } = useTranslation();
  const [marketID, setMarket] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [address, setAddress] = useState<string | undefined>('');
  const [validErrorValue, setValidErrorValue] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    register,
    clearErrors,
  } = useForm<{
    baseMint: string;
    mintPublicKey?: string;
    lotSize: number;
    tickSize: number;
    contractAddress?: string;
    privateKey?: string;
    type?: string;
  }>({
    defaultValues: {
      baseMint: data?.contract_address,
      mintPublicKey: BASE_QUOTE,
    },
  });

  const watchValuePrivateKey = watch('privateKey');
  const isValidKey = watchValuePrivateKey && validateKey(watchValuePrivateKey);

  const submitForm = (formDt: any) => {
    setOpen(true);
  };

  const sendPool = () => {
    const data: any = watch();
    data.lotSize = data.lotSize?.toString().replace(/,/g, '');
    data.tickSize = data.tickSize?.toString().replace(/,/g, '');
    data.lotSize = +data.lotSize;
    data.tickSize = +data.tickSize;
    onCreate(data);
    if (type === CREATE_MARKET_TYPE.FROM_BOOK) {
      setValue('privateKey', '');
      setValue('contractAddress', '');
    }
  };

  const selectFee = (e: any, value: string) => {
    e.preventDefault();
    e.stopPropagation();
    setValue('type', value, { shouldValidate: true });
    clearErrors('type'); // Clear the error when a fee is selected
  };

  useEffect(() => {
    if (data) {
      try {
        const marketData = JSON.parse(data?.market);
        if (marketData?.marketId) {
          setMarket(marketData?.marketId);
        }
      } catch (e) {}
    }

    reset({
      baseMint: data?.contract_address,
      mintPublicKey: BASE_QUOTE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const selectedFee: any = watch('type');

  const handleValueMarketFee = () => {
    const found = Object.values(SOL_TYPES).find(
      item => item.value === selectedFee,
    );
    return found ? found.label : '';
  };

  useEffect(() => {
    if (type === CREATE_MARKET_TYPE.FROM_BOOK) {
      const address =
        watchValuePrivateKey && getAddressFromPrivateKey(watchValuePrivateKey);
      if (watchValuePrivateKey) {
        if (isValidKey) {
          setValidErrorValue('');
        } else {
          setValidErrorValue('Private key is not valid');
        }
      }
      setAddress(address);
    }
  }, [watchValuePrivateKey]);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <Box
          component="div"
          mb={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            '& hr': {
              marginBottom: '30px',
            },
          }}
        >
          <Title className="smal primary-color">
            Create OpenBook Market ID
          </Title>
          <JumboTron mt={2} className="full">
            {marketID || data?.marketId ? (
              <Title className="bt">
                OpenBook Market ID : {data?.marketId || marketID}
              </Title>
            ) : (
              ''
            )}
            <FormSection>
              {type === CREATE_MARKET_TYPE.FROM_DETAILS ? (
                <Box>
                  <InputContainer>
                    <Controller
                      control={control}
                      name="baseMint"
                      rules={{
                        required: t('This field is required'),
                      }}
                      render={({ field }) => {
                        const { value, onChange } = field;
                        return (
                          <Input
                            label={'Base Mint'}
                            value={value}
                            onChange={onChange}
                            isRequired
                            error={errors?.baseMint?.message}
                            placeholder="Base Mint"
                            disabled={!!marketID}
                          />
                        );
                      }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Controller
                      control={control}
                      name="mintPublicKey"
                      rules={{
                        required: t('This field is required'),
                      }}
                      render={({ field }) => {
                        const { value, onChange } = field;
                        return (
                          <Input
                            label={'Base Mint'}
                            value={value}
                            onChange={onChange}
                            isRequired
                            error={errors?.mintPublicKey?.message}
                            placeholder="Base Mint"
                            disabled
                          />
                        );
                      }}
                    />
                  </InputContainer>
                </Box>
              ) : (
                <Box>
                  <InputContainer>
                    <Controller
                      control={control}
                      name="privateKey"
                      rules={{
                        required: t('This field is required'),
                      }}
                      render={({ field }) => {
                        const { value, onChange } = field;
                        return (
                          <Input
                            label={'Private Key'}
                            value={value}
                            onChange={onChange}
                            isRequired
                            error={
                              validErrorValue || errors?.privateKey?.message
                            }
                            placeholder="Private Key"
                            type={'password'}
                            addressWallet={address}
                          />
                        );
                      }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Controller
                      control={control}
                      name="contractAddress"
                      rules={{
                        required: t('This field is required'),
                      }}
                      render={({ field }) => {
                        const { value, onChange } = field;
                        return (
                          <Input
                            label={'Contract Address'}
                            value={value}
                            onChange={onChange}
                            isRequired
                            error={errors?.contractAddress?.message}
                            placeholder="Contract Address"
                          />
                        );
                      }}
                    />
                  </InputContainer>
                </Box>
              )}
            </FormSection>
            <FormSection mt={2}>
              <Box>
                <InputContainer>
                  <Controller
                    control={control}
                    name="lotSize"
                    rules={{
                      required: t('This field is required'),
                    }}
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <NumberInput
                          fromType={InputType.WITH_SUB}
                          label={'Min. Order Size'}
                          value={value}
                          onChange={onChange}
                          isRequired
                          error={errors?.lotSize?.message}
                          placeholder="Min. Order Size"
                          disabled={!!marketID}
                        />
                      );
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <Controller
                    control={control}
                    name="tickSize"
                    rules={{
                      required: t('This field is required'),
                    }}
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <NumberInput
                          fromType={InputType.WITH_SUB}
                          label={'Price Tick'}
                          value={value}
                          onChange={onChange}
                          isRequired
                          error={errors?.tickSize?.message}
                          placeholder="Price Tick"
                          disabled={!!marketID}
                        />
                      );
                    }}
                  />
                </InputContainer>

                {type === CREATE_MARKET_TYPE.FROM_BOOK && (
                  <InputContainer>
                    <Text className="small_top primary-color">
                      {t('label.marketFee')}
                    </Text>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {SOL_TYPES.map((it, idx) => (
                        <button
                          type="button"
                          key={idx}
                          className={`main_btn multi_color_btn button new_btn ${
                            selectedFee === it.value ? 'selected' : 'danger'
                          }`}
                          onClick={e => selectFee(e, it.value)}
                        >
                          {it.label}
                        </button>
                      ))}

                      <input
                        type="hidden"
                        {...register('type', {
                          required: t('message.error.requiredMarketFee'),
                        })}
                      />
                    </Box>
                    {errors.type && (
                      <p
                        style={{
                          color: '#e34a66',
                          fontSize: '10px',
                          lineHeight: '14px',
                          display: 'block',
                          marginTop: '10px',
                        }}
                      >
                        {errors.type.message}
                      </p>
                    )}
                  </InputContainer>
                )}
              </Box>
            </FormSection>
          </JumboTron>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={'Create'}
            className="multi_color_btn main_btn"
            disabled={!!marketID}
          />
        </Box>
        <ConfirmPopUp
          open={open}
          onClose={() => setOpen(false)}
          title="Create OpenBook"
          actionTitle="Create"
          onSubmit={sendPool}
        >
          <Alert severity="warning">
            {type === CREATE_MARKET_TYPE.FROM_DETAILS
              ? t('alert.confirmCreateMarket', { fee: '2.7 SOL' })
              : t('alert.confirmCreateMarket', {
                  fee: handleValueMarketFee(),
                })}
          </Alert>
        </ConfirmPopUp>
      </Box>
      <GuildLine />
    </>
  );
};
