import { Typography } from '@mui/material';

export interface NoDataProps {
  message?: string;
}
export const NoData = ({ message = 'There is no item' }: NoDataProps) => {
  return (
    <Typography fontSize={18} color={'#ccc'} textAlign={`center`} mt={4}>
      {message}
    </Typography>
  );
};
