/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import Input from '@/components/form/input';
import { MonacoTextarea } from '@/components/form/monacoTextArea';
import { InputContainer } from '@/components/form/styled';
import TextArea from '@/components/form/textarea';
import { Title } from '@/components/login';
import useConnectWallet from '@/hooks/useConnectWallet';
import Connect2Phantom from '@/pages/token/Connect2Phantom';
import {
  getAddressFromPrivateKey,
  validateKey,
  validateNumberInRange,
  validatePublicKey,
} from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Import the NFTStorage class and File constructor from the 'nft.storage' package
export enum SolFormType {
  TRANSFER = 1,
  SWAP = 2,
}

export interface SolFormProps {
  type?: SolFormType;
  data?: any;
  onCancel?: () => void;
  onSubmit?: (data: FormData) => void;
}

export const SolForm = ({
  type = SolFormType.TRANSFER,
  data,
  onCancel,
  onSubmit,
}: SolFormProps) => {
  const { t } = useTranslation();
  const [customValueError, setCustomValueError] = useState('');
  const [address, setAddress] = useState<string | undefined>('');
  const [validErrorValue, setValidErrorValue] = useState('');
  const { publicKey } = useConnectWallet();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<{
    privateKey: string;
    addresses: string;
  }>({});

  const watchValueWallet = watch('addresses');
  const valueRowWallet = watchValueWallet?.split(/\r?\n/) ?? [];
  const watchValuePrivateKey = watch('privateKey');
  const isValidKey = validateKey(watchValuePrivateKey);

  const submitForm = (formDt: any) => {
    const valueValid = handleValidAddressAndNumber(valueRowWallet);
    if (type === SolFormType.TRANSFER && !valueValid.isValid) {
      return;
    }

    onSubmit &&
      onSubmit({
        ...formDt,
        handleValue: valueValid.handleValue,
      });

    if (type === SolFormType.SWAP) {
      reset({
        privateKey: '',
        addresses: '',
      });
    }
    setCustomValueError('');
  };

  const handleValidAddressAndNumber = value => {
    let errorCount = 0;
    let errorLines: any = [];

    const handleValue = value?.map((item, index) => {
      const [address, amount] = value[index].split(',');

      const validateKeyAndNumber =
        validatePublicKey(address) && validateNumberInRange(amount);

      if (!validateKeyAndNumber) {
        errorCount++;
        errorLines.push(index + 1);
      }
      return {
        receiver: address.trim(),
        amount: amount,
      };
    });

    return {
      isValid: errorCount === 0,
      errorLines: errorLines,
      messageError: `Check the address or SOL amount on line ${errorLines.join(
        ' , ',
      )}`,
      handleValue,
    };
  };

  useEffect(() => {
    const valueValid = handleValidAddressAndNumber(valueRowWallet);
    if (!valueValid.isValid && watchValueWallet) {
      setCustomValueError(valueValid.messageError);
    } else {
      setCustomValueError('');
    }
  }, [watchValueWallet]);

  useEffect(() => {
    const address = getAddressFromPrivateKey(watchValuePrivateKey);
    if (watchValuePrivateKey) {
      if (isValidKey) {
        setValidErrorValue('');
      } else {
        setValidErrorValue('Private key is not valid');
      }
    }
    setAddress(address);
  }, [watchValuePrivateKey]);

  return (
    <Box component="form">
      <Box
        component="div"
        mb={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Title>
          {type === SolFormType.TRANSFER ? 'Send Sol' : 'Send Token'}
        </Title>
      </Box>

      {type === SolFormType.SWAP && (
        <InputContainer className="mt1 ">
          <Controller
            control={control}
            name="privateKey"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={'Owner Private Key'}
                  isRequired
                  error={validErrorValue || errors?.privateKey?.message}
                  value={value}
                  onChange={onChange}
                  type={'password'}
                  addressWallet={address}
                />
              );
            }}
          />
        </InputContainer>
      )}

      {type === SolFormType.TRANSFER && (
        <Box sx={{ marginBottom: '16px' }}>
          <Connect2Phantom />
        </Box>
      )}

      <InputContainer className="single">
        <Controller
          control={control}
          name="addresses"
          rules={{
            required: t('This field is required'),
          }}
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <>
                {type === SolFormType.TRANSFER ? (
                  <MonacoTextarea
                    label={t('Wallet')}
                    value={value}
                    onChange={onChange}
                    error={errors?.addresses?.message}
                    customError={customValueError}
                    description={'*Please enter: address,sol (0.00001 ~ 100)'}
                    subDescription={
                      'example: FPRpqZcJoXKHuw3Gpr58qRDUccvJVBTvEDbh3mSpD6xu,0.1'
                    }
                  />
                ) : (
                  <TextArea
                    label={t('Wallet')}
                    isRequired
                    error={errors?.addresses?.message}
                    value={value}
                    onChange={onChange}
                  />
                )}
              </>
            );
          }}
        />
      </InputContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <CustomButton
          type="submit"
          buttonType={CustomButtonType.NO_ICON}
          title={type === SolFormType.TRANSFER ? 'Send Sol' : 'Send Token'}
          className="multi_color_btn main_btn"
          onClick={handleSubmit(submitForm)}
          disabled={
            type === SolFormType.TRANSFER && (!publicKey || !watchValueWallet)
          }
        />
      </Box>
    </Box>
  );
};
