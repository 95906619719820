import { Button, TextField } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50%;
  background: transparent linear-gradient(10deg, #9945ff 0%, #0de68c 100%) 10%
    0% no-repeat padding-box !important;
`;

export const LoginForm = styled.form``;

export const LoginContainer = styled.div`
  width: 360px;
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 22px 64px 0px rgba(0, 0, 0, 0.22);
  margin: 20px 20px 20px;
`;
export const LoginWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`;

export const Title = styled.h3`
  color: var(--primary-color);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 10px 0;
  &.smal {
    font-size: 18px;
  }
  &.bt {
    margin-bottom: 15px;
    font-size: 18px;
  }
  &.primary-color {
    color: var(--primary-color);
  }
`;

export const FormGroup = styled.div`
  margin-top: 25px;
`;

export const Label = styled.label`
  color: #5c677e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
  &.white {
    color: #fff;
    padding-left: 10px;
  }
`;

export const Input = styled.input<{ error: string | undefined }>`
  all: unset;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #dee1eb;
  background: #fff;
  ${props => props.error && 'border-color: red;'}
`;
export const CustomTextField = styled(TextField)`
  all: unset;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #dee1eb;
  background: #fff;
`;

export const PasswordInputContainer = styled.div`
  position: relative; /* Ensure proper positioning of the icon */
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

export const SubmitButton = styled.button`
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  width: 100%;
  max-width: 320px;
  outline: none;
  appearance: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 40px;
  text-transform: uppercase;
`;
export const SignUpButton = styled(Button)`
  && {
    &.btn_sign_up {
      border: 1px solid #b9b8b8;
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      color: var(--black);
      border-radius: 4px;
      width: 100%;
      background: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-top: 16px;
      text-transform: uppercase;
    }
  }
`;

export const PasswordToggleIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  height: 24px;
  transform: translateY(-50%);
`;
