import { all } from 'redux-saga/effects';
import loginSaga from './login';
import pumpSaga from './pump';
import tokenSaga from './token';

function* rootSaga() {
  yield all([
    loginSaga(),
    tokenSaga(),
    pumpSaga(),
    // add other saga watcher
  ]);
}
export default rootSaga;
