import useConnectWallet from '@/hooks/useConnectWallet';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import PhantomIcon from '../../components/icons/phantom_icon.png';

const Connect2Phantom: FC = () => {
  const { publicKey, handleConnect, handleDisconnect, connected } =
    useConnectWallet();

  const handleConnectPhantom = (event: any) => {
    event.preventDefault();
    handleConnect();
  };

  const handleDisconnectPhantom = (event: any) => {
    event.preventDefault();
    handleDisconnect();
  };

  return (
    <div>
      <Box
        sx={{ display: 'flex', maxWidth: '700px', justifyContent: 'flex-end' }}
      >
        {connected ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#dcdcdc',
              // padding: '0 32px',
              width: '100%',
              borderRadius: '4px 0 0 4px',
              marginRight: '-2px',
              zIndex: 999,
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              {publicKey?.toBase58()}
            </Typography>
          </Box>
        ) : null}
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {connected ? (
            <Box
              className="main_btn multi_color_btn button new_btn "
              sx={{
                display: 'flex',
                width: '200px',
                justifyContent: 'flex-end',
              }}
              onClick={handleDisconnectPhantom}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '32px',
                  height: '32px',
                }}
              >
                <img
                  src={PhantomIcon}
                  style={{ width: '32px', height: '32px' }}
                />
              </Box>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Disconnect from Phantom
              </button>
            </Box>
          ) : (
            <Box
              className="main_btn multi_color_btn button new_btn"
              sx={{
                display: 'flex',
                width: '200px',
                justifyContent: 'flex-end',
              }}
              onClick={handleConnectPhantom}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '32px',
                  height: '32px',
                }}
              >
                <img
                  src={PhantomIcon}
                  style={{ width: '32px', height: '32px' }}
                />
              </Box>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Connect to Phantom
              </button>
            </Box>
          )}
        </div>
      </Box>
    </div>
  );
};

export default Connect2Phantom;
