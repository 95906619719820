import { TLog } from '@/type';
import createTransferTransaction from '@/utils/createTransferTransaction';
import getProvider from '@/utils/getProvider';
import pollSignatureStatus from '@/utils/pollSignatureStatus';
import signAllTransactions from '@/utils/signAllTransactions';
import signAndSendAllTransactions from '@/utils/signAndSendAllTransaction';
import signAndSendTransaction from '@/utils/signAndSendTransaction';
import signTransaction from '@/utils/signTransaction';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { useCallback, useEffect, useState } from 'react';
import useGlobalHook from './useGlobalHook';

interface Props {
  publicKey: PublicKey | null;
  handleConnect: () => Promise<void>;
  logs: TLog[];
  clearLogs: () => void;
  handleDisconnect: () => Promise<void>;
  connected: boolean;
  handleSignAllTransactions: (listTransaction: Transaction[]) => Promise<void>;
  handleSignAndSendAllTransaction: (
    listTransaction: Transaction[],
  ) => Promise<void>;
  isLoading: boolean;
}

// alternatively, use clusterApiUrl;
// const NETWORK = 'https://api.devnet.solana.com';
const NETWORK = process.env.REACT_APP_RFC_URL;
const provider = getProvider();
const connection = new Connection(NETWORK as string);

const useConnectWallet = (): Props => {
  const [logs, setLogs] = useState<TLog[]>([]);
  const [connected, setConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const createLog = useCallback(
    (log: TLog) => {
      return setLogs(logs => [...logs, log]);
    },
    [setLogs],
  );

  const clearLogs = useCallback(() => {
    setLogs([]);
  }, [setLogs]);

  useEffect(() => {
    if (!provider) return;

    provider.on('connect', (publicKey: PublicKey) => {
      createLog({
        status: 'success',
        method: 'connect',
        message: `Connected to account ${publicKey.toBase58()}`,
      });
      setConnected(true);
    });

    provider.on('disconnect', () => {
      createLog({
        status: 'warning',
        method: 'disconnect',
        message: '👋',
      });
      setConnected(false);
    });

    provider.on('accountChanged', (publicKey: PublicKey | null) => {
      if (publicKey) {
        createLog({
          status: 'info',
          method: 'accountChanged',
          message: `Switched to account ${publicKey.toBase58()}`,
        });
      } else {
        /**
         * In this case dApps could...
         *
         * 1. Not do anything
         * 2. Only re-connect to the new account if it is trusted
         *
         * ```
         * provider.connect({ onlyIfTrusted: true }).catch((err) => {
         *  // fail silently
         * });
         * ```
         *
         * 3. Always attempt to reconnect
         */

        createLog({
          status: 'info',
          method: 'accountChanged',
          message: 'Attempting to switch accounts.',
        });

        provider.connect().catch(error => {
          createLog({
            status: 'error',
            method: 'accountChanged',
            message: `Failed to re-connect: ${error.message}`,
          });
        });
      }
    });

    return () => {
      provider.disconnect();
    };
  }, [createLog]);

  /** SignAndSendTransaction */
  const handleSignAndSendTransaction = useCallback(async () => {
    if (!provider) return;

    try {
      const transaction = await createTransferTransaction(
        provider.publicKey as PublicKey,
        connection,
      );
      createLog({
        status: 'info',
        method: 'signAndSendTransaction',
        message: `Requesting signature for: ${JSON.stringify(transaction)}`,
      });
      const signature = await signAndSendTransaction(provider, transaction);
      createLog({
        status: 'info',
        method: 'signAndSendTransaction',
        message: `Signed and submitted transaction ${signature}.`,
      });
      pollSignatureStatus(signature, connection, createLog);
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signAndSendTransaction',
        message: '',
      });
    }
  }, [createLog]);

  /** SignTransaction */
  const handleSignTransaction = useCallback(async () => {
    if (!provider) return;

    try {
      const transaction = await createTransferTransaction(
        provider.publicKey as PublicKey,
        connection,
      );
      createLog({
        status: 'info',
        method: 'signTransaction',
        message: `Requesting signature for: ${JSON.stringify(transaction)}`,
      });
      const signedTransaction = await signTransaction(provider, transaction);
      createLog({
        status: 'success',
        method: 'signTransaction',
        message: `Transaction signed: ${JSON.stringify(signedTransaction)}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signTransaction',
        message: '',
      });
    }
  }, [createLog]);

  /** SignAllTransactions */
  const handleSignAllTransactions = useCallback(
    async (listTransaction: Transaction[]) => {
      if (!provider) return;

      try {
        createLog({
          status: 'info',
          method: 'signAllTransactions',
          message: `Requesting signature for: ${JSON.stringify(
            listTransaction,
          )}`,
        });
        const signedTransactions = await signAllTransactions(
          provider,
          listTransaction,
        );
        createLog({
          status: 'success',
          method: 'signAllTransactions',
          message: `Transactions signed: ${JSON.stringify(signedTransactions)}`,
        });
      } catch (error) {
        createLog({
          status: 'error',
          method: 'signAllTransactions',
          message: '',
        });
      }
    },
    [createLog],
  );

  /** Connect */
  const handleConnect = useCallback(async () => {
    if (!provider) return;

    try {
      await provider.connect();
    } catch (error) {
      createLog({
        status: 'error',
        method: 'connect',
        message: '',
      });
    }
  }, [createLog]);

  /** SignAndSendAllTransaction */
  const handleSignAndSendAllTransaction = useCallback(
    async (listTransaction: Transaction[]) => {
      setIsLoading(true);
      if (!provider) return;

      try {
        createLog({
          status: 'info',
          method: 'signAndSendTransaction',
          message: `Requesting signature for: ${JSON.stringify(
            listTransaction,
          )}`,
        });
        const signatures = await signAndSendAllTransactions(
          provider,
          listTransaction,
        );
        createLog({
          status: 'info',
          method: 'signAndSendTransaction',
          message: `Signed and submitted transaction ${signatures}.`,
        });
        // pollSignatureStatus(signature, connection, createLog);
        const value: any = await connection.getSignatureStatuses(signatures);
        const allConfirmed = value?.value.every(
          item => item.confirmationStatus === 'confirmed',
        );
        if (allConfirmed) {
          setSuccessMsg(['Transfer successful']);
          setIsLoading(false);
        } else {
          setErrorMsg(['Transfer failed']);
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMsg(['Transfer failed']);
        setIsLoading(false);
        createLog({
          status: 'error',
          method: 'signAndSendTransaction',
          message: '',
        });
      }
    },
    [createLog],
  );

  /** Disconnect */
  const handleDisconnect = useCallback(async () => {
    if (!provider) return;

    try {
      await provider.disconnect();
    } catch (error) {
      createLog({
        status: 'error',
        method: 'disconnect',
        message: '',
      });
    }
  }, [createLog]);

  return {
    publicKey: provider?.publicKey || null,
    handleConnect,
    logs,
    clearLogs,
    handleDisconnect,
    connected,
    handleSignAllTransactions,
    handleSignAndSendAllTransaction,
    isLoading,
  };
};

export default useConnectWallet;
