export const TransferIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.5 16.5H6M5.5 8.5L19 8.5" stroke="#fff" stroke-width="1.2" />
    <path
      d="M16 13L19.5 16.5L16 20M9 12L5.5 8.5L9 5"
      stroke="#fff"
      stroke-width="1.2"
    />
  </svg>
);
