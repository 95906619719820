export const PumpfunListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path d="M15.5 6.5C15.5 5.66 17 4 17 4s1.5 1.66 1.5 2.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5m4 8.5c1.38 0 2.5-1.12 2.5-2.5 0-1.67-2.5-4.5-2.5-4.5S17 10.83 17 12.5c0 1.38 1.12 2.5 2.5 2.5M13 14h-2v-2H9v2H7v2h2v2h2v-2h2zm3-2v10H4V12c0-2.97 2.16-5.43 5-5.91V4H7V2h6c1.13 0 2.15.39 2.99 1.01l-1.43 1.43C14.1 4.17 13.57 4 13 4h-2v2.09c2.84.48 5 2.94 5 5.91"></path>
  </svg>
);
