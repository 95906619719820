import { RequestStatus } from '@/constants/API';
import { IPageDataResponse } from '@/types/common';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TokenDetail, TokenHolder } from './type';

export interface TokenState {
  getTokenOptionsStatus: RequestStatus;
  getTokenListStatus: RequestStatus;
  createTokenInfoStatus: RequestStatus;
  createTokenAddressStatus: RequestStatus;
  updateTokenInfoStatus: RequestStatus;
  uploadTokenImageStatus: RequestStatus;
  deleteTokenInfoStatus: RequestStatus;
  revokeMintTokenStatus: RequestStatus;
  sendSolStatus: RequestStatus;
  sendSolV2Status: RequestStatus;
  sendMultiSolStatus: RequestStatus;
  snipSolStatus: RequestStatus;
  getSnipSolStatus: RequestStatus;
  getTokenDetailsStatus: RequestStatus;
  getTokenHolderStatus: RequestStatus;
  revokeFreezeTokenStatus: RequestStatus;
  burnTokenStatus: RequestStatus;
  rugPoolTokenStatus: RequestStatus;
  autoRugPoolTokenStatus: RequestStatus;
  createBookStatus: RequestStatus;
  createBookMarketStatus: RequestStatus;
  refreshTokenStatus: RequestStatus;
  createPoolStatus: RequestStatus;
  createPoolV2Status: RequestStatus;
  getPumpFunListStatus: RequestStatus;
  createPumpFunStatus: RequestStatus;
  createBuyStatus: RequestStatus;
  createSellStatus: RequestStatus;
  getPumpfunMintStatus: RequestStatus;
  sendMultiTokenStatus: RequestStatus;
  importTokenStatus: RequestStatus;
  getContractAddressStatus: RequestStatus;
  importBootTxStatus: RequestStatus;
  getBootTxListStatus: RequestStatus;
  importWalletBuyStatus: RequestStatus;
  importWalletSellStatus: RequestStatus;
  importWalletVolumeStatus: RequestStatus;
  updateVolumeBuyStatus: RequestStatus;
  updateVolumeSellStatus: RequestStatus;
  updateVolumeVolumeStatus: RequestStatus;
  getListWalletBuyStatus: RequestStatus;
  getListWalletSellStatus: RequestStatus;
  getListWalletVolumeStatus: RequestStatus;
  startFakeBuyStatus: RequestStatus;
  startFakeSellStatus: RequestStatus;
  startFakeVolumeStatus: RequestStatus;
  importBootVolumeTxStatus: RequestStatus;
  getBootVolumeTxListStatus: RequestStatus;
  tokens: IPageDataResponse<TokenDetail> | null;
  revokeMsg?: string;
  snipMsg?: string;
  tokenOptions: any[];
  tokenDetails: TokenDetail | null;
  holders: TokenHolder[];
  pumpFunList: any[];
  snips: any[];
  multiSenderData?: any;
  marketData?: any;
  buy: string[];
  sell: string[];
  pumpfun: any;
  pumpfunMint: string;
  multiToken?: any;
  contractAddress: string[];
  listTransaction: any[];
  bootTxList: any;
  walletBuy: any;
  walletSell: any;
  walletVolume: any;
  listWalletBuy: any[];
  listWalletSell: any[];
  listWalletVolume: any[];
  bootVolumeTxList: any[];
  listSignatureBuy: any[];
  listSignatureSell: any[];
  listSignatureVolume: any[];
}

const initialState: TokenState = {
  getTokenOptionsStatus: RequestStatus.IDLE,
  getTokenListStatus: RequestStatus.IDLE,
  rugPoolTokenStatus: RequestStatus.IDLE,
  uploadTokenImageStatus: RequestStatus.IDLE,
  createTokenAddressStatus: RequestStatus.IDLE,
  createTokenInfoStatus: RequestStatus.IDLE,
  updateTokenInfoStatus: RequestStatus.IDLE,
  deleteTokenInfoStatus: RequestStatus.IDLE,
  revokeMintTokenStatus: RequestStatus.IDLE,
  revokeFreezeTokenStatus: RequestStatus.IDLE,
  createBookMarketStatus: RequestStatus.IDLE,
  getPumpFunListStatus: RequestStatus.IDLE,
  createPumpFunStatus: RequestStatus.IDLE,
  createBuyStatus: RequestStatus.IDLE,
  createSellStatus: RequestStatus.IDLE,
  sendSolStatus: RequestStatus.IDLE,
  sendSolV2Status: RequestStatus.IDLE,
  sendMultiSolStatus: RequestStatus.IDLE,
  snipSolStatus: RequestStatus.IDLE,
  getSnipSolStatus: RequestStatus.IDLE,
  getTokenDetailsStatus: RequestStatus.IDLE,
  getTokenHolderStatus: RequestStatus.IDLE,
  burnTokenStatus: RequestStatus.IDLE,
  autoRugPoolTokenStatus: RequestStatus.IDLE,
  refreshTokenStatus: RequestStatus.IDLE,
  getPumpfunMintStatus: RequestStatus.IDLE,
  createBookStatus: RequestStatus.IDLE,
  createPoolStatus: RequestStatus.IDLE,
  createPoolV2Status: RequestStatus.IDLE,
  sendMultiTokenStatus: RequestStatus.IDLE,
  importTokenStatus: RequestStatus.IDLE,
  getContractAddressStatus: RequestStatus.IDLE,
  importBootTxStatus: RequestStatus.IDLE,
  getBootTxListStatus: RequestStatus.IDLE,
  importWalletBuyStatus: RequestStatus.IDLE,
  importWalletSellStatus: RequestStatus.IDLE,
  importWalletVolumeStatus: RequestStatus.IDLE,
  updateVolumeBuyStatus: RequestStatus.IDLE,
  updateVolumeSellStatus: RequestStatus.IDLE,
  updateVolumeVolumeStatus: RequestStatus.IDLE,
  getListWalletBuyStatus: RequestStatus.IDLE,
  getListWalletSellStatus: RequestStatus.IDLE,
  getListWalletVolumeStatus: RequestStatus.IDLE,
  startFakeBuyStatus: RequestStatus.IDLE,
  startFakeSellStatus: RequestStatus.IDLE,
  startFakeVolumeStatus: RequestStatus.IDLE,
  importBootVolumeTxStatus: RequestStatus.IDLE,
  getBootVolumeTxListStatus: RequestStatus.IDLE,
  tokens: null,
  tokenOptions: [],
  tokenDetails: null,
  holders: [],
  snips: [],
  pumpFunList: [],
  sell: [],
  buy: [],
  pumpfun: {},
  pumpfunMint: '',
  contractAddress: [],
  listTransaction: [],
  bootTxList: null,
  walletBuy: [],
  walletSell: [],
  walletVolume: [],
  listWalletBuy: [],
  listWalletSell: [],
  listWalletVolume: [],
  bootVolumeTxList: [],
  listSignatureBuy: [],
  listSignatureSell: [],
  listSignatureVolume: [],
};

export const tokenSlice: any = createSlice({
  name: 'token',
  initialState,
  reducers: {
    getTokenOptionsRequest: (state, action: PayloadAction<any>) => {
      state.getTokenOptionsStatus = RequestStatus.REQUESTING;
    },
    getTokenOptionsSuccess: (state, action: PayloadAction<any>) => {
      state.getTokenOptionsStatus = RequestStatus.SUCCESS;
      state.tokenOptions = action.payload;
    },
    getTokenOptionsFailure: state => {
      state.getTokenOptionsStatus = RequestStatus.ERROR;
    },
    getTokenListRequest: (state, action: PayloadAction<any>) => {
      state.getTokenListStatus = RequestStatus.REQUESTING;
    },
    getTokenListSuccess: (state, action: PayloadAction<any>) => {
      state.getTokenListStatus = RequestStatus.SUCCESS;
      state.tokens = action.payload;
    },
    getTokenListFailure: state => {
      state.getTokenListStatus = RequestStatus.ERROR;
    },
    getTokenHoldersRequest: (state, action: PayloadAction<any>) => {
      state.getTokenHolderStatus = RequestStatus.REQUESTING;
    },
    getTokenHoldersSuccess: (state, action: PayloadAction<any>) => {
      state.getTokenHolderStatus = RequestStatus.SUCCESS;
      state.holders = action.payload;
    },
    getTokenHoldersFailure: state => {
      state.getTokenHolderStatus = RequestStatus.ERROR;
    },
    getPumpfunMintRequest: (state, action: PayloadAction<any>) => {
      state.getPumpfunMintStatus = RequestStatus.REQUESTING;
      state.pumpfunMint = '';
    },
    getPumpfunMintSuccess: (state, action: PayloadAction<any>) => {
      state.getPumpfunMintStatus = RequestStatus.SUCCESS;
      state.pumpfunMint = action.payload;
    },
    getPumpfunMintFailure: state => {
      state.getPumpfunMintStatus = RequestStatus.ERROR;
    },
    getTokenDetailsRequest: (state, action: PayloadAction<any>) => {
      state.getTokenDetailsStatus = RequestStatus.REQUESTING;
    },
    getTokenDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.getTokenDetailsStatus = RequestStatus.SUCCESS;
      state.tokenDetails = action.payload;
    },
    getTokenDetailsFailure: state => {
      state.getTokenDetailsStatus = RequestStatus.ERROR;
    },
    createTokenInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createTokenInfoStatus = RequestStatus.REQUESTING;
    },
    createTokenInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createTokenInfoStatus = RequestStatus.SUCCESS;
    },
    createTokenInfoFailure: (state, action: PayloadAction<any>) => {
      state.createTokenInfoStatus = RequestStatus.ERROR;
    },

    createTokenAddressRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createTokenAddressStatus = RequestStatus.REQUESTING;
    },
    createTokenAddressSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createTokenAddressStatus = RequestStatus.SUCCESS;
    },
    createTokenAddressFailure: (state, action: PayloadAction<any>) => {
      state.createTokenAddressStatus = RequestStatus.ERROR;
    },
    updateTokenInfoRequest: (state, action: PayloadAction<any>) => {
      state.updateTokenInfoStatus = RequestStatus.REQUESTING;
      state.revokeMsg = undefined;
    },
    updateTokenInfoSuccess: (state, action: PayloadAction<any>) => {
      state.updateTokenInfoStatus = RequestStatus.SUCCESS;
      state.revokeMsg = action.payload;
    },
    updateTokenInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateTokenInfoStatus = RequestStatus.ERROR;
      state.revokeMsg = undefined;
    },
    updateTokenImageRequest: (state, action: PayloadAction<any>) => {
      state.uploadTokenImageStatus = action.payload;
    },
    deleteTokenInfoRequest: (state, action: PayloadAction<any>) => {
      state.deleteTokenInfoStatus = RequestStatus.REQUESTING;
    },
    deleteTokenInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteTokenInfoStatus = RequestStatus.SUCCESS;
    },
    deleteTokenInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteTokenInfoStatus = RequestStatus.ERROR;
    },
    createBookInfoRequest: (state, action: PayloadAction<any>) => {
      state.createBookStatus = RequestStatus.REQUESTING;
    },
    createBookInfoSuccess: (state, action: PayloadAction<any>) => {
      state.createBookStatus = RequestStatus.SUCCESS;
      state.marketData = action.payload;
    },
    createBookInfoFailure: (state, action: PayloadAction<any>) => {
      state.createBookStatus = RequestStatus.ERROR;
    },
    createBookMarketInfoRequest: (state, action: PayloadAction<any>) => {
      state.createBookMarketStatus = RequestStatus.REQUESTING;
    },
    createBookMarketInfoSuccess: (state, action: PayloadAction<any>) => {
      state.createBookMarketStatus = RequestStatus.SUCCESS;
      state.marketData = action.payload;
    },
    createBookMarketInfoFailure: (state, action: PayloadAction<any>) => {
      state.createBookMarketStatus = RequestStatus.ERROR;
    },
    createBuyInfoRequest: (state, action: PayloadAction<any>) => {
      state.createBuyStatus = RequestStatus.REQUESTING;
      state.buy = [];
    },
    createBuyInfoSuccess: (state, action: PayloadAction<any>) => {
      state.createBuyStatus = RequestStatus.SUCCESS;
      state.buy = action.payload;
    },
    createBuyInfoFailure: (state, action: PayloadAction<any>) => {
      state.createBuyStatus = RequestStatus.ERROR;
    },
    createSellInfoRequest: (state, action: PayloadAction<any>) => {
      state.createSellStatus = RequestStatus.REQUESTING;
      state.sell = [];
    },
    createSellInfoSuccess: (state, action: PayloadAction<any>) => {
      state.createSellStatus = RequestStatus.SUCCESS;
      state.sell = action.payload;
    },
    createSellInfoFailure: (state, action: PayloadAction<any>) => {
      state.createSellStatus = RequestStatus.ERROR;
    },
    createPoolInfoRequest: (state, action: PayloadAction<any>) => {
      state.createPoolStatus = RequestStatus.REQUESTING;
    },
    createPoolInfoSuccess: (state, action: PayloadAction<any>) => {
      state.createPoolStatus = RequestStatus.SUCCESS;
    },
    createPoolInfoFailure: (state, action: PayloadAction<any>) => {
      state.createPoolStatus = RequestStatus.ERROR;
    },
    createPoolInfoV2Request: (state, action: PayloadAction<any>) => {
      state.createPoolV2Status = RequestStatus.REQUESTING;
    },
    createPoolInfoV2Success: (state, action: PayloadAction<any>) => {
      state.createPoolV2Status = RequestStatus.SUCCESS;
    },
    createPoolInfoV2Failure: (state, action: PayloadAction<any>) => {
      state.createPoolV2Status = RequestStatus.ERROR;
    },
    revokeMintTokenRequest: (state, action: PayloadAction<any>) => {
      state.revokeMintTokenStatus = RequestStatus.REQUESTING;
      state.revokeMsg = undefined;
    },
    revokeMintTokenSuccess: (state, action: PayloadAction<any>) => {
      state.revokeMintTokenStatus = RequestStatus.SUCCESS;
      state.revokeMsg = action.payload;
    },
    revokeMintTokenFailure: (state, action: PayloadAction<any>) => {
      state.revokeMintTokenStatus = RequestStatus.ERROR;
      state.revokeMsg = undefined;
    },
    revokeFreezeTokenRequest: (state, action: PayloadAction<any>) => {
      state.revokeFreezeTokenStatus = RequestStatus.REQUESTING;
      state.revokeMsg = undefined;
    },
    revokeFreezeTokenSuccess: (state, action: PayloadAction<any>) => {
      state.revokeFreezeTokenStatus = RequestStatus.SUCCESS;
      state.revokeMsg = action.payload;
    },
    revokeFreezeTokenFailure: (state, action: PayloadAction<any>) => {
      state.revokeFreezeTokenStatus = RequestStatus.ERROR;
      state.revokeMsg = undefined;
    },
    rugPoolTokenRequest: (state, action: PayloadAction<any>) => {
      state.rugPoolTokenStatus = RequestStatus.REQUESTING;
    },
    rugPoolTokenSuccess: (state, action: PayloadAction<any>) => {
      state.rugPoolTokenStatus = RequestStatus.SUCCESS;
    },
    rugPoolTokenFailure: (state, action: PayloadAction<any>) => {
      state.rugPoolTokenStatus = RequestStatus.ERROR;
    },
    autoRugPoolTokenRequest: (state, action: PayloadAction<any>) => {
      state.autoRugPoolTokenStatus = RequestStatus.REQUESTING;
    },
    autoRugPoolTokenSuccess: (state, action: PayloadAction<any>) => {
      state.autoRugPoolTokenStatus = RequestStatus.SUCCESS;
    },
    autoRugPoolTokenFailure: (state, action: PayloadAction<any>) => {
      state.autoRugPoolTokenStatus = RequestStatus.ERROR;
    },
    sendSolRequest: (state, action: PayloadAction<any>) => {
      state.sendSolStatus = RequestStatus.REQUESTING;
    },
    sendSolSuccess: (state, action: PayloadAction<any>) => {
      state.sendSolStatus = RequestStatus.SUCCESS;
    },
    sendSolFailure: (state, action: PayloadAction<any>) => {
      state.sendSolStatus = RequestStatus.ERROR;
    },
    sendSolV2Request: (state, action: PayloadAction<any>) => {
      state.sendSolV2Status = RequestStatus.REQUESTING;
    },
    sendSolV2Success: (state, action: PayloadAction<any>) => {
      state.sendSolV2Status = RequestStatus.SUCCESS;
      state.listTransaction = action.payload;
    },
    sendSolV2Failure: (state, action: PayloadAction<any>) => {
      state.sendSolV2Status = RequestStatus.ERROR;
    },
    sendMultiSolRequest: (state, action: PayloadAction<any>) => {
      state.sendMultiSolStatus = RequestStatus.REQUESTING;
    },
    sendMultiSolSuccess: (state, action: PayloadAction<any>) => {
      state.sendMultiSolStatus = RequestStatus.SUCCESS;
      state.multiSenderData = action.payload;
    },
    sendMultiSolFailure: (state, action: PayloadAction<any>) => {
      state.sendMultiSolStatus = RequestStatus.ERROR;
      state.multiSenderData = undefined;
    },
    snipSolRequest: (state, action: PayloadAction<any>) => {
      state.snipSolStatus = RequestStatus.REQUESTING;
      state.snipMsg = undefined;
    },
    snipSolSuccess: (state, action: PayloadAction<any>) => {
      state.snipSolStatus = RequestStatus.SUCCESS;
      state.snipMsg = action.payload;
    },
    snipSolFailure: (state, action: PayloadAction<any>) => {
      state.snipSolStatus = RequestStatus.ERROR;
      state.snipMsg = undefined;
    },
    getSnipSolRequest: (state, action: PayloadAction<any>) => {
      state.getSnipSolStatus = RequestStatus.REQUESTING;
    },
    getSnipSolSuccess: (state, action: PayloadAction<any>) => {
      state.getSnipSolStatus = RequestStatus.SUCCESS;
      state.snips = action.payload;
    },
    getSnipSolFailure: state => {
      state.getSnipSolStatus = RequestStatus.ERROR;
    },
    burnTokenRequest: (state, action: PayloadAction<any>) => {
      state.burnTokenStatus = RequestStatus.REQUESTING;
      state.revokeMsg = undefined;
    },
    burnTokenSuccess: (state, action: PayloadAction<any>) => {
      state.burnTokenStatus = RequestStatus.SUCCESS;
      state.revokeMsg = action.payload;
    },
    burnTokenFailure: (state, action: PayloadAction<any>) => {
      state.burnTokenStatus = RequestStatus.ERROR;
      state.revokeMsg = undefined;
    },
    refreshTokenRequest: (state, action: PayloadAction<any>) => {
      state.refreshTokenStatus = RequestStatus.REQUESTING;
    },
    refreshTokenSuccess: (state, action: PayloadAction<any>) => {
      state.refreshTokenStatus = RequestStatus.SUCCESS;
    },
    refreshTokenFailure: (state, action: PayloadAction<any>) => {
      state.refreshTokenStatus = RequestStatus.ERROR;
    },
    getPumpFunListRequest: (state, action: PayloadAction<any>) => {
      state.getPumpFunListStatus = RequestStatus.REQUESTING;
    },
    getPumpFunListSuccess: (state, action: PayloadAction<any>) => {
      state.getPumpFunListStatus = RequestStatus.SUCCESS;
      state.pumpFunList = action.payload;
    },
    getPumpFunListFailure: state => {
      state.getPumpFunListStatus = RequestStatus.ERROR;
    },
    createPumpFunRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createPumpFunStatus = RequestStatus.REQUESTING;
      state.pumpfun = {};
    },
    createPumpFunSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createPumpFunStatus = RequestStatus.SUCCESS;
      state.pumpfun = action.payload;
    },
    createPumpFunFailure: (state, action: PayloadAction<any>) => {
      state.createPumpFunStatus = RequestStatus.ERROR;
    },

    importTokenRequest: (state, action: PayloadAction<any>) => {
      state.importTokenStatus = RequestStatus.REQUESTING;
    },
    importTokenSuccess: (state, action: PayloadAction<any>) => {
      state.importTokenStatus = RequestStatus.SUCCESS;
    },
    importTokenFailure: (state, action: PayloadAction<any>) => {
      state.importTokenStatus = RequestStatus.ERROR;
    },

    sendMultiTokenRequest: (state, action: PayloadAction<any>) => {
      state.sendMultiTokenStatus = RequestStatus.REQUESTING;
    },
    sendMultiTokenSuccess: (state, action: PayloadAction<any>) => {
      state.sendMultiTokenStatus = RequestStatus.SUCCESS;
      state.multiToken = action.payload;
    },
    sendMultiTokenFailure: (state, action: PayloadAction<any>) => {
      state.sendMultiTokenStatus = RequestStatus.ERROR;
      state.multiToken = undefined;
    },
    getContractAddressRequest: (state, action: PayloadAction<any>) => {
      state.getContractAddressStatus = RequestStatus.REQUESTING;
    },
    getContractAddressSuccess: (state, action: PayloadAction<any>) => {
      state.getContractAddressStatus = RequestStatus.SUCCESS;
      state.contractAddress = action.payload;
    },
    getContractAddressFailure: state => {
      state.getContractAddressStatus = RequestStatus.ERROR;
    },
    importBootTxRequest: (state, action: PayloadAction<any>) => {
      state.importBootTxStatus = RequestStatus.REQUESTING;
    },
    importBootTxSuccess: (state, action: PayloadAction<any>) => {
      state.importBootTxStatus = RequestStatus.SUCCESS;
    },
    importBootTxFailure: (state, action: PayloadAction<any>) => {
      state.importBootTxStatus = RequestStatus.ERROR;
    },
    getBootTxListRequest: (state, action: PayloadAction<any>) => {
      state.getBootTxListStatus = RequestStatus.REQUESTING;
    },
    getBootTxListSuccess: (state, action: PayloadAction<any>) => {
      state.getBootTxListStatus = RequestStatus.SUCCESS;
      state.bootTxList = action.payload;
    },
    getBootTxListFailure: state => {
      state.getBootTxListStatus = RequestStatus.ERROR;
    },
    importWalletBuyRequest: (state, action: PayloadAction<any>) => {
      state.importWalletBuyStatus = RequestStatus.REQUESTING;
    },
    importWalletBuySuccess: (state, action: PayloadAction<any>) => {
      state.importWalletBuyStatus = RequestStatus.SUCCESS;
      state.walletBuy = action.payload;
    },
    importWalletBuyFailure: (state, action: PayloadAction<any>) => {
      state.importWalletBuyStatus = RequestStatus.ERROR;
    },
    importWalletSellRequest: (state, action: PayloadAction<any>) => {
      state.importWalletSellStatus = RequestStatus.REQUESTING;
    },
    importWalletSellSuccess: (state, action: PayloadAction<any>) => {
      state.importWalletSellStatus = RequestStatus.SUCCESS;
      state.walletSell = action.payload;
    },
    importWalletSellFailure: (state, action: PayloadAction<any>) => {
      state.importWalletSellStatus = RequestStatus.ERROR;
    },
    importWalletVolumeRequest: (state, action: PayloadAction<any>) => {
      state.importWalletVolumeStatus = RequestStatus.REQUESTING;
    },
    importWalletVolumeSuccess: (state, action: PayloadAction<any>) => {
      state.importWalletVolumeStatus = RequestStatus.SUCCESS;
      state.walletVolume = action.payload;
    },
    importWalletVolumeFailure: (state, action: PayloadAction<any>) => {
      state.importWalletVolumeStatus = RequestStatus.ERROR;
    },
    updateVolumeBuyRequest: (state, action: PayloadAction<any>) => {
      state.updateVolumeBuyStatus = RequestStatus.REQUESTING;
    },
    updateVolumeBuySuccess: (state, action: PayloadAction<any>) => {
      state.updateVolumeBuyStatus = RequestStatus.SUCCESS;
    },
    updateVolumeBuyFailure: (state, action: PayloadAction<any>) => {
      state.updateVolumeBuyStatus = RequestStatus.ERROR;
    },
    updateVolumeSellRequest: (state, action: PayloadAction<any>) => {
      state.updateVolumeSellStatus = RequestStatus.REQUESTING;
    },
    updateVolumeSellSuccess: (state, action: PayloadAction<any>) => {
      state.updateVolumeSellStatus = RequestStatus.SUCCESS;
    },
    updateVolumeSellFailure: (state, action: PayloadAction<any>) => {
      state.updateVolumeSellStatus = RequestStatus.ERROR;
    },
    updateVolumeVolumeRequest: (state, action: PayloadAction<any>) => {
      state.updateVolumeVolumeStatus = RequestStatus.REQUESTING;
    },
    updateVolumeVolumeSuccess: (state, action: PayloadAction<any>) => {
      state.updateVolumeVolumeStatus = RequestStatus.SUCCESS;
    },
    updateVolumeVolumeFailure: (state, action: PayloadAction<any>) => {
      state.updateVolumeVolumeStatus = RequestStatus.ERROR;
    },
    getListWalletBuyRequest: (state, action: PayloadAction<any>) => {
      state.getListWalletBuyStatus = RequestStatus.REQUESTING;
    },
    getListWalletBuySuccess: (state, action: PayloadAction<any>) => {
      state.getListWalletBuyStatus = RequestStatus.SUCCESS;
      state.listWalletBuy = action.payload;
    },
    getListWalletBuyFailure: (state, action: PayloadAction<any>) => {
      state.getListWalletBuyStatus = RequestStatus.ERROR;
    },
    getListWalletSellRequest: (state, action: PayloadAction<any>) => {
      state.getListWalletSellStatus = RequestStatus.REQUESTING;
    },
    getListWalletSellSuccess: (state, action: PayloadAction<any>) => {
      state.getListWalletSellStatus = RequestStatus.SUCCESS;
      state.listWalletSell = action.payload;
    },
    getListWalletSellFailure: (state, action: PayloadAction<any>) => {
      state.getListWalletSellStatus = RequestStatus.ERROR;
    },
    getListWalletVolumeRequest: (state, action: PayloadAction<any>) => {
      state.getListWalletVolumeStatus = RequestStatus.REQUESTING;
    },
    getListWalletVolumeSuccess: (state, action: PayloadAction<any>) => {
      state.getListWalletVolumeStatus = RequestStatus.SUCCESS;
      state.listWalletVolume = action.payload;
    },
    getListWalletVolumeFailure: (state, action: PayloadAction<any>) => {
      state.getListWalletVolumeStatus = RequestStatus.ERROR;
    },
    startFakeBuyRequest: (state, action: PayloadAction<any>) => {
      state.startFakeBuyStatus = RequestStatus.REQUESTING;
    },
    startFakeBuySuccess: (state, action: PayloadAction<any>) => {
      state.startFakeBuyStatus = RequestStatus.SUCCESS;
      state.listSignatureBuy = action.payload;
    },
    startFakeBuyFailure: (state, action: PayloadAction<any>) => {
      state.startFakeBuyStatus = RequestStatus.ERROR;
    },
    startFakeSellRequest: (state, action: PayloadAction<any>) => {
      state.startFakeSellStatus = RequestStatus.REQUESTING;
    },
    startFakeSellSuccess: (state, action: PayloadAction<any>) => {
      state.startFakeSellStatus = RequestStatus.SUCCESS;
      state.listSignatureSell = action.payload;
    },
    startFakeSellFailure: (state, action: PayloadAction<any>) => {
      state.startFakeSellStatus = RequestStatus.ERROR;
    },
    startFakeVolumeRequest: (state, action: PayloadAction<any>) => {
      state.startFakeVolumeStatus = RequestStatus.REQUESTING;
    },
    startFakeVolumeSuccess: (state, action: PayloadAction<any>) => {
      state.startFakeVolumeStatus = RequestStatus.SUCCESS;
      state.listSignatureVolume = action.payload;
    },
    startFakeVolumeFailure: (state, action: PayloadAction<any>) => {
      state.startFakeVolumeStatus = RequestStatus.ERROR;
    },
    importBootVolumeTxRequest: (state, action: PayloadAction<any>) => {
      state.importBootVolumeTxStatus = RequestStatus.REQUESTING;
    },
    importBootVolumeTxSuccess: (state, action: PayloadAction<any>) => {
      state.importBootVolumeTxStatus = RequestStatus.SUCCESS;
    },
    importBootVolumeTxFailure: (state, action: PayloadAction<any>) => {
      state.importBootVolumeTxStatus = RequestStatus.ERROR;
    },
    getBootVolumeTxListRequest: (state, action: PayloadAction<any>) => {
      state.getBootVolumeTxListStatus = RequestStatus.REQUESTING;
    },
    getBootVolumeTxListSuccess: (state, action: PayloadAction<any>) => {
      state.getBootVolumeTxListStatus = RequestStatus.SUCCESS;
      state.bootVolumeTxList = action.payload;
    },
    getBootVolumeTxListFailure: state => {
      state.getBootVolumeTxListStatus = RequestStatus.ERROR;
    },
    resetTokenInfoRequestStatus: state => {
      state.getTokenListStatus = RequestStatus.IDLE;
      state.createPumpFunStatus = RequestStatus.IDLE;
      state.getPumpFunListStatus = RequestStatus.IDLE;
      state.createTokenInfoStatus = RequestStatus.IDLE;
      state.updateTokenInfoStatus = RequestStatus.IDLE;
      state.uploadTokenImageStatus = RequestStatus.IDLE;
      state.deleteTokenInfoStatus = RequestStatus.IDLE;
      state.revokeMintTokenStatus = RequestStatus.IDLE;
      state.revokeFreezeTokenStatus = RequestStatus.IDLE;
      state.getTokenDetailsStatus = RequestStatus.IDLE;
      state.rugPoolTokenStatus = RequestStatus.IDLE;
      state.autoRugPoolTokenStatus = RequestStatus.IDLE;
      state.burnTokenStatus = RequestStatus.IDLE;
      state.sendSolStatus = RequestStatus.IDLE;
      state.sendMultiSolStatus = RequestStatus.IDLE;
      state.snipSolStatus = RequestStatus.IDLE;
      state.getSnipSolStatus = RequestStatus.IDLE;
      state.createPoolStatus = RequestStatus.IDLE;
      state.createPoolV2Status = RequestStatus.IDLE;
      state.createBookStatus = RequestStatus.IDLE;
      state.createBookMarketStatus = RequestStatus.IDLE;
      state.createBuyStatus = RequestStatus.IDLE;
      state.createSellStatus = RequestStatus.IDLE;
      state.getPumpfunMintStatus = RequestStatus.IDLE;
      state.sendMultiTokenStatus = RequestStatus.IDLE;
      state.importTokenStatus = RequestStatus.IDLE;
      state.importBootTxStatus = RequestStatus.IDLE;
      state.getBootTxListStatus = RequestStatus.IDLE;
      state.importWalletBuyStatus = RequestStatus.IDLE;
      state.importWalletSellStatus = RequestStatus.IDLE;
      state.importWalletVolumeStatus = RequestStatus.IDLE;
      state.updateVolumeBuyStatus = RequestStatus.IDLE;
      state.updateVolumeSellStatus = RequestStatus.IDLE;
      state.updateVolumeVolumeStatus = RequestStatus.IDLE;
      state.getListWalletBuyStatus = RequestStatus.IDLE;
      state.getListWalletSellStatus = RequestStatus.IDLE;
      state.getListWalletVolumeStatus = RequestStatus.IDLE;
      state.startFakeBuyStatus = RequestStatus.IDLE;
      state.startFakeSellStatus = RequestStatus.IDLE;
      state.startFakeVolumeStatus = RequestStatus.IDLE;
      state.importBootVolumeTxStatus = RequestStatus.IDLE;
      state.getBootVolumeTxListStatus = RequestStatus.IDLE;
      state.revokeMsg = undefined;
      state.snipMsg = undefined;
      state.multiSenderData = undefined;
      state.buy = [];
      state.sell = [];
      state.multiToken = undefined;
      state.pumpfun = {};
      state.pumpfunMint = '';
      state.getContractAddressStatus = RequestStatus.IDLE;
      state.listTransaction = [];
      state.listSignatureBuy = [];
      state.listSignatureSell = [];
      state.listSignatureVolume = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getTokenOptionsRequest,
  getTokenOptionsSuccess,
  getTokenOptionsFailure,
  getTokenListRequest,
  getTokenListSuccess,
  getTokenListFailure,
  getTokenHoldersRequest,
  getTokenHoldersSuccess,
  getTokenHoldersFailure,
  createTokenInfoRequest,
  createTokenInfoSuccess,
  createTokenInfoFailure,
  createTokenAddressRequest,
  createTokenAddressSuccess,
  createTokenAddressFailure,
  updateTokenInfoRequest,
  updateTokenInfoSuccess,
  updateTokenInfoFailure,
  deleteTokenInfoRequest,
  deleteTokenInfoSuccess,
  deleteTokenInfoFailure,
  revokeMintTokenRequest,
  revokeMintTokenSuccess,
  revokeMintTokenFailure,
  revokeFreezeTokenRequest,
  revokeFreezeTokenSuccess,
  revokeFreezeTokenFailure,
  updateTokenImageRequest,
  getTokenDetailsRequest,
  getTokenDetailsSuccess,
  getTokenDetailsFailure,
  burnTokenRequest,
  burnTokenSuccess,
  burnTokenFailure,
  rugPoolTokenRequest,
  rugPoolTokenSuccess,
  rugPoolTokenFailure,
  autoRugPoolTokenRequest,
  autoRugPoolTokenSuccess,
  autoRugPoolTokenFailure,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,
  sendSolRequest,
  sendSolSuccess,
  sendSolFailure,
  sendSolV2Request,
  sendSolV2Success,
  sendSolV2Failure,
  snipSolRequest,
  snipSolSuccess,
  snipSolFailure,
  getSnipSolRequest,
  getSnipSolSuccess,
  getSnipSolFailure,
  sendMultiSolRequest,
  sendMultiSolSuccess,
  sendMultiSolFailure,
  createBookInfoRequest,
  createBookInfoFailure,
  createBookInfoSuccess,
  createBookMarketInfoRequest,
  createBookMarketInfoFailure,
  createBookMarketInfoSuccess,
  createPoolInfoRequest,
  createPoolInfoFailure,
  createPoolInfoSuccess,
  createPoolInfoV2Request,
  createPoolInfoV2Failure,
  createPoolInfoV2Success,
  getPumpFunListRequest,
  getPumpFunListSuccess,
  getPumpFunListFailure,
  createPumpFunRequest,
  createPumpFunSuccess,
  createPumpFunFailure,
  createSellInfoRequest,
  createSellInfoSuccess,
  createSellInfoFailure,
  createBuyInfoRequest,
  createBuyInfoSuccess,
  createBuyInfoFailure,
  getPumpfunMintRequest,
  getPumpfunMintSuccess,
  getPumpfunMintFailure,
  sendMultiTokenRequest,
  sendMultiTokenSuccess,
  sendMultiTokenFailure,
  importTokenRequest,
  importTokenSuccess,
  importTokenFailure,
  resetTokenInfoRequestStatus,
  getContractAddressRequest,
  getContractAddressSuccess,
  getContractAddressFailure,
  importBootTxRequest,
  importBootTxSuccess,
  importBootTxFailure,
  getBootTxListRequest,
  getBootTxListSuccess,
  getBootTxListFailure,
  importWalletBuyRequest,
  importWalletBuySuccess,
  importWalletBuyFailure,
  importWalletSellRequest,
  importWalletSellSuccess,
  importWalletSellFailure,
  importWalletVolumeRequest,
  importWalletVolumeSuccess,
  importWalletVolumeFailure,
  updateVolumeBuyRequest,
  updateVolumeBuySuccess,
  updateVolumeBuyFailure,
  updateVolumeSellRequest,
  updateVolumeSellSuccess,
  updateVolumeSellFailure,
  updateVolumeVolumeRequest,
  updateVolumeVolumeSuccess,
  updateVolumeVolumeFailure,
  getListWalletBuyRequest,
  getListWalletBuySuccess,
  getListWalletBuyFailure,
  getListWalletSellRequest,
  getListWalletSellSuccess,
  getListWalletSellFailure,
  getListWalletVolumeRequest,
  getListWalletVolumeSuccess,
  getListWalletVolumeFailure,
  startFakeBuyRequest,
  startFakeBuySuccess,
  startFakeBuyFailure,
  startFakeSellRequest,
  startFakeSellSuccess,
  startFakeSellFailure,
  startFakeVolumeRequest,
  startFakeVolumeSuccess,
  startFakeVolumeFailure,
  importBootVolumeTxRequest,
  importBootVolumeTxSuccess,
  importBootVolumeTxFailure,
  getBootVolumeTxListRequest,
  getBootVolumeTxListSuccess,
  getBootVolumeTxListFailure,
} = tokenSlice.actions;

export default tokenSlice.reducer;
