/* eslint-disable max-lines */
import {
  Box,
  FormHelperText,
  Typography,
  useAutocomplete,
} from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import { SelectIcon } from 'src/components/icons';
import {
  IconContainer,
  InputWrapper,
  LabelPrimary,
  ListBox,
  Root,
  StyledTag,
} from './component';

export enum InputType {
  POP_UP = 1,
  MAPPING = 2,
  WITH_SUB = 3,
  CALCULATION = 4,
  CUSTOM = 5,
  DARK = 6,
}

export enum DirectionType {
  ROW = 1,
  COLUMN = 2,
}

interface IMultipleSelectCustomizeProps {
  label?: string;
  options: any[];
  onChange: (value: any) => void;
  value?: any;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  sx?: any;
  inputType?: InputType;
  isRequired?: boolean;
  isMulti?: boolean;
  placeholder?: string;
}

export default function MultipleSelectCustomize({
  label,
  options,
  onChange,
  value,
  error,
  helperText,
  disabled = false,
  isRequired = false,
  sx,
  inputType = InputType.POP_UP,
  isMulti = true,
  placeholder = '',
}: IMultipleSelectCustomizeProps) {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    value,
    multiple: isMulti,
    options: options,
    getOptionLabel: option => option.title ?? '',
    isOptionEqualToValue: (option, value) => {
      return option.title === value.title;
    },
    autoHighlight: true,
    onChange: (option, value) => onChange(value),
  });

  const renderListBox = () => {
    if (groupedOptions.length) {
      return (
        <ListBox {...getListboxProps()} $inputType={inputType}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              {renderTextOption(option)}
            </li>
          ))}
        </ListBox>
      );
    }
    return null;
  };

  const renderTextOption = (option: {
    title:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | null
      | undefined;
  }) => {
    let data = { ...getInputProps() };
    return (
      <>
        <Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
            }}
            component={'span'}
            style={{
              fontWeight:
                data?.value &&
                option.title?.toString().includes(data?.value?.toString())
                  ? 700
                  : 400,
            }}
          >
            {option.title}
          </Typography>
        </Typography>
      </>
    );
  };

  return (
    <Root>
      <Box sx={sx} {...getRootProps()} component="div">
        {label && (
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={'8px'}
          >
            {typeof label === 'string' && (
              <Box
                component="div"
                className={'labelPrimaryContainer'}
                display="flex"
                alignItems="center"
              >
                <LabelPrimary
                  $inputType={inputType}
                  sx={{
                    fontSize: '14px',
                    color: '#5c677e',
                    fontWeight: '500 !important',
                    fontFamily: 'inherit',
                    '& > span': {
                      color: '#e34a66',
                      paddingLeft: '5px',
                      verticalAlign: 'middle',
                    },
                  }}
                >
                  {label} {isRequired && <span>*</span>}
                </LabelPrimary>
              </Box>
            )}
          </Box>
        )}
        <InputWrapper
          $inputType={inputType}
          component="div"
          ref={setAnchorEl}
          sx={{
            border: helperText
              ? '1px solid #d32f2f'
              : inputType === InputType.MAPPING
              ? 'none'
              : '1px solid #DEE1EB',
            wordBreak: 'break-word',
          }}
          className={
            focused ? 'focused multiple-container' : 'multiple-container'
          }
        >
          {Array.isArray(value)
            ? value.map((option: any, index: number) => (
                <StyledTag
                  label={
                    _.get(
                      _.find(
                        options,
                        it => it.value === option || it.value === option.value,
                      ),
                      'label',
                    ) ?? option.title
                  }
                  {...getTagProps({ index })}
                  disabled={disabled}
                  $inputType={inputType}
                />
              ))
            : options.find(it => it.value === value)?.title}
          <input
            {...getInputProps()}
            autoComplete="off"
            disabled={disabled}
            placeholder={placeholder}
            value={
              getInputProps().value && getInputProps().value !== 'undefined'
                ? getInputProps().value
                : ''
            }
          />
          <IconContainer $inputType={inputType}>
            <SelectIcon />
          </IconContainer>
        </InputWrapper>
        {helperText && (
          <FormHelperText
            sx={{
              color: '#e34a66',
              fontSize: '10px',
            }}
            className="helper-text"
          >
            {helperText}
          </FormHelperText>
        )}
      </Box>
      {renderListBox()}
    </Root>
  );
}
