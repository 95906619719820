/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { Container } from '@/components/token/components';
import { TokenForm } from '@/components/token/components/form';
import { RequestStatus } from '@/constants/API';
import Path from '@/constants/path';
import useGlobalHook from '@/hooks/useGlobalHook';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const CreateTokenPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigateHook();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    createTokenInfoStatus,
    requestCreateToken,
    requestResetToken,
    uploadTokenImageStatus,
    requestContractAddress,
  } = useTokenHook();

  const loading = useMemo(
    () => checkIsRequesting([createTokenInfoStatus, uploadTokenImageStatus]),
    [createTokenInfoStatus, uploadTokenImageStatus],
  );

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createTokenInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Token created!')]);
      navigate.navigate(Path.TOKEN_LIST);
    }

    if (createTokenInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to create token')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTokenInfoStatus]);

  useEffect(() => {
    requestContractAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (formDt: any) => {
    requestCreateToken(formDt);
  };

  return (
    <Container className="big">
      <TokenForm onSubmit={onSubmit} />
      <Loader show={loading} />
    </Container>
  );
};
