import { Title } from '@/components/login';
import { TokenDetail } from '@/stores/slices/token/type';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { TabProfile } from '.';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy_ic.svg';
import { copyToClipboard, formatNumber } from '@/utils/helper';

export interface InfoType {
  data: TokenDetail;
}

export const Text = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  &.white {
    color: var(--black);
    word-break: break-word;
  }
  &.bold {
    font-weight: bold;
  }
  &.gray {
    color: #aaa;
    padding-left: 15px;
  }
  &.word_break {
    word-break: break-word;
  }
  &.flex {
    display: flex;
    gap: 10px;
  }
  &.full_txt {
    display: grid;
    width: 100%;
    gap: 6px;
    grid-template-columns: 1fr auto;
    svg {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
`;

export const CopyButton = styled.span`
  cursor: pointer;
`;

export const Info = ({ data }: InfoType) => {
  return (
    <TabProfile>
      <Title>Profile</Title>
      <Box mb={1}>
        <Text>
          Token name : <Text className="gray">{data?.name}</Text>
        </Text>
      </Box>
      <Box mb={1}>
        <Text>
          Symbol : <Text className="gray">{data?.symbol}</Text>
        </Text>
      </Box>
      <Box mb={1}>
        <Text className="flex">
          <Text>CA : </Text>
          <Text className="flex">
            <Text className="gray word_break">{data?.contract_address}</Text>
            <CopyButton>
              <CopyIcon
                onClick={() => copyToClipboard(data?.contract_address ?? '')}
              />
            </CopyButton>
          </Text>
        </Text>
      </Box>
      <Box mb={1}>
        <Text>
          Supply : <Text className="gray">{formatNumber(data?.supply)}</Text>
        </Text>
      </Box>
    </TabProfile>
  );
};
