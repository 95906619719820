import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { pumpSlice } from './pump';

const selectSlice = (state: RootState) =>
  state.pump || pumpSlice.getInitialState;

export const selectPump = createSelector([selectSlice], pumpState => ({
  ...pumpState,
}));
