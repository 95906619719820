import { Input, Slider } from '@mui/material';
import * as React from 'react';

import { InputType } from './customSelect';
import { InputName, InputRequired } from './inputName';
import { ErrorMessage, InputContainer, SliderWrapper } from './styled';

interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string | number;
  label?: string;
  placeholder?: string;
  error?: string;
  isRequired?: boolean;
  inputType?: number;
  width?: number;
  fromType?: InputType;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const SliderItem = ({
  label,
  value,
  error,
  isRequired = false,
  placeholder,
  width = 0,
  inputType = InputType.POP_UP,
  onChange,
  ...inputProps
}: SliderProps) => {
  const [val, setVal] = React.useState<number>(value ? +value : 0);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setVal(newValue as number);
    const triggerEvent = {
      target: {
        value: newValue as unknown as string,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange && onChange(triggerEvent);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const opacity = event.target.value === '' ? 0 : Number(event.target.value);
    setVal(opacity);
    onChange && onChange(event);
  };

  const handleBlur = () => {
    if (val < 0) {
      setVal(0);
    } else if (val > 100) {
      setVal(100);
    }
  };
  return (
    <InputContainer>
      {label && (
        <InputName>
          {label}
          <InputRequired>{isRequired && '*'}</InputRequired>
        </InputName>
      )}

      <SliderWrapper fromType={inputType}>
        <Slider
          value={Number(val)}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
        />
        <Input
          value={val}
          size="small"
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
        />
      </SliderWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  );
};

export default SliderItem;
