import { SearchIcon } from '@/components/icons';
import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { DirectionType, InputType } from './customSelect';
import { InputName, InputRequired } from './inputName';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | undefined;
  labelName?: string;
  error?: string;
  isRequired?: boolean;
  inputType?: number;
  width?: number;
  value?: string | number;
  fromType?: InputType;
  direction?: DirectionType;
  horizontalLabel?: boolean;
  verticalLabel?: boolean;
  addressWallet?: string;
}

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;

export const Label = styled.label<{ $fromType?: InputType }>`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  color: #ccc;
  span {
    color: #e34a66;
    vertical-align: middle;
    margin-right: 5px;
  }
  .bold {
    font-weight: bold;
  }
`;

export const ErrorMessage = styled.span`
  color: #e34a66;
  font-size: 10px;
  line-height: 14px;
  display: block;
  margin-top: 10px;
`;

export const ErrorMessageLarge = styled.span`
  color: #e34a66;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  fontstyle: italic;
`;

export const InputField = styled.input<{
  $hasError?: boolean;
  $inputType?: number;
  $width?: number;
  $fromType?: InputType;
}>`
  box-sizing: border-box;
  width: ${props => (props.$width ? props.$width + 'px' : '100%')};
  padding: ${props =>
    props.$inputType === 1 ? '8px 12px 8px 30px' : '8px 12px'};
  border: 1px solid
    ${props =>
      props.$hasError
        ? '#e34a66'
        : props.$fromType === InputType.POP_UP
        ? 'var(--black)'
        : 'transparent'};
  border-radius: 4px;
  background: ${props =>
    props.$fromType === InputType.MAPPING ? '#40495C' : '#fff'};
  color: ${props =>
    props.$fromType === InputType.MAPPING ? '#fff' : '#222222 '};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #ccc;
  }
  &:hover {
    box-shadow: 0 2px 8px 0 #20212447;
  }
`;

const Input: React.FC<InputProps> = ({
  label,
  error,
  isRequired = false,
  inputType = 0,
  width = 0,
  fromType = InputType.POP_UP,
  direction = DirectionType.COLUMN,
  onChange,
  verticalLabel = true,
  labelName,
  addressWallet,
  ...inputProps
}) => {
  return (
    <InputContainer>
      <Box
        sx={{
          display: direction === DirectionType.COLUMN ? 'block' : 'flex',
          '& > label': {
            marginBottom: direction === DirectionType.COLUMN ? '0.5rem' : '',
            paddingTop: direction === DirectionType.COLUMN ? '0' : '8px',
            paddingRight: direction === DirectionType.COLUMN ? '0' : '5px',
          },
        }}
      >
        {verticalLabel && (
          <InputName>
            {labelName || label}{' '}
            <InputRequired>{isRequired && '*'}</InputRequired>
          </InputName>
        )}
        <InputWrapper>
          {inputType === 1 && <SearchIcon />}

          <InputField
            $inputType={inputType}
            $fromType={fromType}
            {...inputProps}
            $hasError={!!error}
            $width={width}
            onChange={onChange}
            placeholder={label ?? ''}
          />
        </InputWrapper>
      </Box>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {addressWallet && (
        <Box
          sx={{
            marginTop: '12px',
            marginLeft: '10px',
            fontSize: '12px',
            fontStyle: 'italic',
          }}
        >
          {`Address: ${addressWallet}`}
        </Box>
      )}
    </InputContainer>
  );
};

export default Input;
