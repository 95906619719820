/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import { CommonContainer } from '@/components/layouts/content';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { ImportTokenPopup } from '@/components/token/components/importPopup';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import { Box } from '@mui/material';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

export interface DeleteForm {
  input: string;
}
export const TokenPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queries = useParams();
  const [open, setOpen] = useState<boolean>(false);

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    getTokenListStatus,
    requestTokenList,
    requestDeleteToken,
    deleteTokenInfoStatus,
    tokens,
    requestImportToken,
    importTokenStatus,
    requestResetToken,
  } = useTokenHook();

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    requestTokenList(
      queries?.customerId
        ? { ...params, customerId: queries?.customerId }
        : { ...params },
    );
  };

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getTokenListStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to fetch tokens')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTokenListStatus]);

  useEffect(() => {
    if (deleteTokenInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to disable token')]);
    }
    if (deleteTokenInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Token deleted successfully!!')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTokenInfoStatus]);

  useEffect(() => {
    if (importTokenStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to import token')]);
    }
    if (importTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Token is imported!')]);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTokenStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    setTimeout(() => {
      reloadData();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getTokenListStatus,
        deleteTokenInfoStatus,
        importTokenStatus,
      ]),
    [getTokenListStatus, deleteTokenInfoStatus, importTokenStatus],
  );

  const showNoData = useMemo(
    () =>
      getTokenListStatus !== RequestStatus.IDLE &&
      (tokens?.data?.length === 0 || !tokens),
    [tokens, getTokenListStatus],
  );

  const deleteToken = (id: number) => {
    requestDeleteToken(id);
  };

  const onImport = () => {
    setOpen(true);
  };
  const importToken = (payload: any) => {
    requestImportToken(payload);
    setOpen(false);
  };

  return (
    <CommonContainer className="top">
      <Box
        mt={4}
        mb={4}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <button
          className="main_btn multi_color_btn button new_btn min_150"
          onClick={onImport}
        >
          Import token
        </button>
      </Box>
      {showNoData ? (
        <NoData />
      ) : (
        <>
          <CommonTable
            type={TableType.VENUE}
            data={tokens}
            onDelete={deleteToken}
            onReload={reloadData}
          />
        </>
      )}
      <ImportTokenPopup
        open={open}
        onSubmit={importToken}
        onClose={() => setOpen(false)}
      />
      <Loader show={loading} />
    </CommonContainer>
  );
};
