/* eslint-disable max-lines */
import PopUp from '@/components/common/Popup';
import { Box } from '@mui/material';

export interface confirmDeleteProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  content?: string;
}

export function ConfirmDeletePopup({
  open,
  onClose,
  onSubmit,
  content,
}: confirmDeleteProps) {
  const onCancel = (e: any) => {
    e.preventDefault();
    onClose();
  };
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <PopUp title={'Confirm Delete'} onClose={onClose} open={open}>
      <Box component="form">
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              letterSpacing: '0px',
              color: '#000',
              fontSize: '18px',
              lineHeight: '36px',
            }}
          >
            {content || 'Are you sure you want to delete this?'}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '150px 150px',
                gap: '20px',
              }}
            >
              <button
                className="main_btn button new_btn multi_color_btn secondary_btn"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                className="main_btn button new_btn multi_color_btn danger_btn"
                onClick={handleSubmit}
              >
                Delete
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </PopUp>
  );
}
