/* eslint-disable max-lines */
import { Box, Button, Checkbox, Select, Stack } from '@mui/material';
import styled from 'styled-components';
import { InputType } from './customSelect';

export const InputContainer = styled.div`
  margin-bottom: 2rem;
  input,
  textarea {
    &:hover {
      box-shadow: 0 2px 8px 0 #20212447;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  .text-slate-400 {
    position: absolute;
    color: #514f4f;
    top: 14px;
    left: 10px;
    font-size: 14px;
  }
  .rear-input {
    left: auto;
    right: 10px;
  }
  sup {
    top: -0.5em;
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
`;

export const SliderWrapper = styled(Stack)<{
  fromType?: InputType;
}>`
  && {
    flex-direction: row;
    gap: 15px;
  }
  .MuiSlider-thumb {
    width: 12px;
    height: 12px;
  }
  .MuiSlider-rail {
    background: currentColor;
  }
  .MuiInput-root {
    font-family: inherit !important;
    &:after,
    &:before {
      display: none;
    }
  }
  .MuiInput-input {
    padding: 8px;
    border-radius: 2px;
    background: ${props =>
      props.fromType === InputType.MAPPING ? '#40495C' : '#fff'};
    color: ${props =>
      props.fromType === InputType.MAPPING ? '#fff' : '#222222 '};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
  }
`;

export const ErrorMessage = styled.span`
  color: #e34a66;
  font-size: 10px;
  line-height: 14px;
  display: block;
  margin-top: 10px;
`;

export const SelectField = styled(Select)<{
  $hasError?: boolean;
  $width?: number;
  $inputType?: InputType;
}>`
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  width: ${props => (props.$width ? props.$width + 'px' : '100%')};
  padding: 8px 12px;
  min-width: 100%;
  height: 40px;
  border: 1px solid
    ${props =>
      props.$hasError
        ? '#e34a66 !important'
        : props.$inputType === InputType.POP_UP
        ? '#dee1eb !important'
        : 'transparent !important'};
  border-radius: 2px !important;
  background: #fff !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    display: none;
  }
  &:focus {
    outline: none;
  }
  .MuiSelect-select {
    padding: 6px 12px 6px 0;
    color: #222222;
  }
  & .MuiSelect-select:focus {
    background: #fff !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiPaper-root {
    background: #fff !important;
  }
  svg path {
    stroke: #333333;
  }
  &:hover {
    box-shadow: 0 2px 8px 0 #20212447;
  }
`;
export const OptionText = styled(Box)<{ $inputType?: InputType }>`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const Span = styled.span`
  padding-right: 10px;
`;

export const CustomCheckbox = styled(Checkbox)`
  && {
    color: #dee1eb;
    padding: 2px;
    width: 17px;
    height: 17px;
    border: 1px solid #555c6a;
    border-radius: 2px;
    &.Mui-checked {
      border: none;
      background: #e34a66;
      & svg {
        display: inline-block;
      }
      & svg path {
        fill: #fff;
        stroke: #fff;
      }
    }
    & svg {
      width: 12px;
      height: 8px;
      outline: none;
      border: none;
      fill: currentcolor;
      display: none;
    }
  }
`;

export const UploadButton = styled(Button)`
  && {
    display: inline-block;
    margin: 20px 0 0px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #dee1eb;
    background: #fff;
    color: #ccc;
    svg {
      margin-right: 10px;
    }
  }
`;

export const AutoCompleteBox = styled(Box)`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const DropDownBox = styled.ul`
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

export const DropDownItem = styled.li`
    padding: 10px;
    cursor: pointer;
    &:hover: {
      backgroundColor: #f4f4f4;
    },
`;
