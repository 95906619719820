import TreeView from '@mui/lab/TreeView';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ItemWrapper = styled(Link)<{
  selected: boolean;
  active?: boolean;
  isSubMenu?: boolean;
}>`
  align-self: stretch;
  padding: 12px 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  background: ${props =>
    props.selected ? 'var(--secondary-color)' : '#2c2d30'};
  color: ${props => (props.selected ? '#A43399' : '#fff')};
  font-weight: ${props => (props.selected ? '700' : '400')};
  cursor: pointer;
  text-decoration: none;
  position: relative;
  border-radius: 6px;
  width: 90%;
  &.sub_item {
    padding-left: 36px;
  }
  &:hover {
    ${props => (props?.active ? 'background: var(--secondary-color)' : '')}
  }
  svg * {
    fill: ${props => (props.selected ? '#A43399' : '#A43399')};
  }

  .icon_wrapper {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    svg * {
      stroke: ${props => (props.selected ? '#E34A66' : '#555c6a')};
      fill: transparent;
    }
  }
`;

export const FloorWrapper = styled(Box)<{ selected: boolean }>`
  align-self: stretch;
  padding: 12px 12px 12px 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  width: 100%;
  background: ${props => (props.selected ? '#FFC8D2' : 'inherit')};
  color: ${props => (props.selected ? '#ccc' : '#555c6a')};
  font-weight: ${props => (props.selected ? '700' : '400')};
  cursor: pointer;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  &.sub_item {
    padding-left: 36px;
  }
  .icon_wrapper {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    svg * {
      stroke: ${props => (props.selected ? '#E34A66' : '#555c6a')};
      fill: transparent;
    }
  }
  svg * {
    fill: ${props => (props.selected ? '#E34A66' : '#555c6a')};
  }
`;

export const AddItem = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px dashed #3080ec;
  background: #bfdaff;
  margin: 10px 12px;
  cursor: pointer;
  svg path {
    stroke: #ccc;
  }
  .icon_wrapper {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
`;

export const ItemName = styled.div`
  font-size: 12px;
  font-family: Poppins, serif;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
  &.category_icon svg {
    width: 20px;
    height: auto;
  }
`;

export const SubContainer = styled(Box)`
  && {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      -webkit-box-shadow: inset 0 0 6px #d9d9d9;
    }
  }
`;

export const Badge = styled(Typography)`
  && {
    font-size: 10px;
    line-height: 13px;
    color: #333;
    font-weight: bold;
  }
`;

export const LogoWrapper = styled(Link)`
  display: flex;
  gap: 6px;
  align-items: center;
  text-decoration: none;
  img,
  svg {
    width: 100%;
  }
  &.big_logo img {
    width: 230px;
  }
  img {
    max-width: 120px;
  }
`;

export const MenuItem = styled(Box)`
  && {
    background: #2c2d30;
    padding: 5px;
  }
`;

export const MenuItemTree = styled(TreeView)`
  && {
    align-self: stretch;
    padding: 12px 20px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    background: '#2c2d30';
    color: '#fff;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    box-sizing: border-box;
    &.sub_item {
      padding-left: 36px;
    }
  }
`;
