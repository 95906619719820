import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import { setUserSessionData } from '@/stores/slices/global/global';
import {
  getProfileFailure,
  getProfileRequest,
  getProfileSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  logoutRequest,
  signUpFailure,
  signUpRequest,
  signUpSuccess,
  updateProfileFailure,
  updateProfileRequest,
  updateProfileSuccess,
} from '../slices/auth/auth';

import { TOKEN_ACCESS_KEY } from '@/constants';
import { removeToken } from '@/utils/localStorage';

type IPayload = Record<string, any>;

function* loginFlow({ payload }: IPayload): any {
  let errorMess = ['Ops..Login failed!'];
  try {
    const response = yield call(SERVICES.login, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      localStorage.setItem(TOKEN_ACCESS_KEY, response.data?.data?.access_token);
      yield put({
        type: loginSuccess.type,
        payload: response.data?.data?.access_token,
      });
    } else {
      errorMess = response.data?.message;
      yield put({ type: loginFailure.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: loginFailure.type, payload: { errorMess } });
  }
}
function* signUpFlow({ payload }: IPayload): any {
  let errorMess = ['Ops..SignUp failed!'];
  try {
    const response = yield call(SERVICES.signUp, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: signUpSuccess.type,
        payload: response.data?.message,
      });
    } else {
      errorMess = response.data?.message;
      yield put({ type: signUpFailure.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: signUpFailure.type, payload: { errorMess } });
  }
}

function* logoutFlow({ payload }: IPayload): any {
  yield removeToken();
}

function* getProfileFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getProfile, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getProfileSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getProfileFailure.type });
    }
  } catch (error) {
    yield put({ type: getProfileFailure.type });
  }
}

function* updateProfileFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateProfile, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateProfileSuccess.type,
        payload: response.data,
      });
      yield put({
        type: setUserSessionData.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateProfileFailure.type });
    }
  } catch (error) {
    yield put({ type: updateProfileFailure.type });
  }
}

function* loginSaga() {
  yield takeLatest(loginRequest, loginFlow);
  yield takeLatest(signUpRequest, signUpFlow);
  yield takeLatest(logoutRequest, logoutFlow);
  yield takeLatest(getProfileRequest, getProfileFlow);
  yield takeLatest(updateProfileRequest, updateProfileFlow);
}

export default loginSaga;
