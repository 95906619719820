import { Bounce, toast, ToastContent } from 'react-toastify';
import isString from 'lodash/isString';

const ToastMessage = {
  success(msg: ToastContent) {
    toast.success(`${msg}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Bounce,
    });
  },
  inform(msg: ToastContent) {
    toast.info(`${msg}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Bounce,
    });
  },
  warn(msg: ToastContent) {
    toast.warn(`${msg}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Bounce,
    });
  },
  error(msg: ToastContent) {
    const toastId = isString(msg) ? msg?.toString() : 1;
    toast.error(`${msg}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Bounce,
      toastId,
    });
  },
};

export default ToastMessage;
