import { Box } from '@mui/material';

import CustomButton, { CustomButtonType } from './Button';
import PopUp from './Popup';

export interface IPopUp {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  sx?: any;
  children?: any;
  className?: string;
  title?: string;
  actionTitle?: string;
}

const ConfirmPopUp = ({
  className,
  children,
  title,
  sx,
  open,
  onClose,
  onSubmit,
  actionTitle = 'Delete',
  ...props
}: IPopUp) => {
  const onAgree = () => {
    onSubmit();
    onClose();
  };

  return (
    <PopUp
      open={open}
      className={`confirm_modal`}
      title={title}
      onClose={onClose}
      sx={{}}
    >
      {children}

      <Box
        component="div"
        sx={{
          margin: '20px 0 0',
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CustomButton
          buttonType={CustomButtonType.WHITE_BG}
          title={`Cancel`}
          onClick={onClose}
          className="main_btn"
        />
        <CustomButton
          buttonType={CustomButtonType.NO_ICON}
          title={actionTitle === 'Delete' ? 'Delete' : actionTitle}
          onClick={onAgree}
          className="multi_color_btn main_btn"
        />
      </Box>
    </PopUp>
  );
};

export default ConfirmPopUp;
