/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { Container, JumboTron } from '@/components/token/components';
import { PumpfunForm } from '@/components/token/components/pumpfunForm';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import { Alert } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

export const CreatePumpfunToken = () => {
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    requestResetToken,
    createPumpFunStatus,
    requestCreatePumpFun,
    uploadTokenImageStatus,
    pumpfun,
    getPumpfunMintStatus,
    requestContractAddress,
  } = useTokenHook();

  const [valuePumpfunMint, setValuePumpfunMint] = useState<string>('');

  const loading = useMemo(
    () =>
      checkIsRequesting([
        createPumpFunStatus,
        uploadTokenImageStatus,
        getPumpfunMintStatus,
      ]),
    [createPumpFunStatus, uploadTokenImageStatus, getPumpfunMintStatus],
  );

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    requestContractAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createPumpFunStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Pumpfun token created!']);
    }

    if (createPumpFunStatus === RequestStatus.ERROR) {
      setErrorMsg(['Something went wrong. Unable to create Pumpfun']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPumpFunStatus]);

  const onSubmit = (formDt: any) => {
    let { buys } = formDt;
    buys = buys
      .filter((item: any) => item.buyPrivateKey && item.amount)
      .map((it: any) => ({
        buyPrivateKey: it.buyPrivateKey,
        amount: +it.amount?.replace(/,/g, ''),
      }));

    const pumpfunMint = formDt.address;
    delete formDt.address;

    requestCreatePumpFun({
      ...formDt,
      buys,
      mint: pumpfunMint,
      devAmount: +formDt.devAmount?.replace(/,/g, ''),
    });
  };

  return (
    <Container className="big">
      {valuePumpfunMint ? (
        <JumboTron>
          <Alert sx={{ color: '#A43399', fontSize: '18px' }}>
            CA: {valuePumpfunMint}
          </Alert>
        </JumboTron>
      ) : (
        ''
      )}

      {pumpfun?.mint ? (
        <Alert
          severity="success"
          sx={{
            margin: '10px 0 30px',
            '& a': {
              color: '#fff',
            },
            '& span': {
              color: '#fff',
              paddingRight: '5px',
            },
          }}
        >
          <span>Success create token: {pumpfun?.mint} at tx</span>
          <a
            href={`https://solscan.io/tx/${pumpfun?.tx}`}
            target="_blank"
            rel="noreferrer"
          >{`https://solscan.io/tx/${pumpfun?.tx}`}</a>
        </Alert>
      ) : (
        ''
      )}
      <PumpfunForm
        onSubmit={onSubmit}
        setValuePumpfunMint={setValuePumpfunMint}
      />
      <Loader show={loading} />
    </Container>
  );
};
