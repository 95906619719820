import { Transaction } from '@solana/web3.js';

import { PhantomProvider } from '../type';

/**
 * Signs an array of transactions
 * @param   {PhantomProvider} provider     a Phantom provider
 * @param   {Transaction}     transaction1 a transaction to sign
 * @param   {Transaction}     transaction2 a transaction to sign
 * @returns {Transaction[]}                an array of signed transactions
 */
const signAndSendAllTransactions = async (
  provider: PhantomProvider,
  listTransaction: Transaction[],
): Promise<string[]> => {
  try {
    const { signatures } =
      await provider.signAndSendAllTransactions(listTransaction);

    return signatures;
  } catch (error) {
    console.warn(error);
    throw new Error();
  }
};

export default signAndSendAllTransactions;
