/* eslint-disable max-lines */
import PopUp from '@/components/common/Popup';
import { MonacoTextarea } from '@/components/form/monacoTextArea';
import { InputContainer } from '@/components/form/styled';
import { handleValidAddressAndNumber } from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export interface ImportTokenProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: any) => void;
}

export function ImportWalletPopup({
  open,
  onClose,
  onSubmit,
}: ImportTokenProps) {
  const [customValueError, setCustomValueError] = useState('');

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<{
    privateKeys: string;
  }>({
    defaultValues: {
      privateKeys: '',
    },
  });
  const watchValueWallet = watch('privateKeys');
  const valueRowWallet = watchValueWallet?.split(/\r?\n/) ?? [];

  const submitForm = (formDt: any) => {
    formDt.privateKeys = formDt.privateKeys?.split(/\r?\n/) ?? [];

    const valueValid = handleValidAddressAndNumber(
      formDt.privateKeys,
      'please check wallet at number',
      'private',
    );

    if (!valueValid.isValid) {
      setCustomValueError(valueValid.messageError);
      return;
    }

    onSubmit(formDt);
    setCustomValueError('');
    reset();
  };

  const onCancel = (e: any) => {
    e.preventDefault();
    setCustomValueError('');
    reset();
    onClose();
  };

  useEffect(() => {
    const valueValid = handleValidAddressAndNumber(
      valueRowWallet,
      'please check wallet at number',
      'private',
    );
    if (!valueValid.isValid && watchValueWallet) {
      setCustomValueError(valueValid.messageError);
    } else {
      setCustomValueError('');
    }
  }, [watchValueWallet]);

  return (
    <PopUp
      title={'Import Wallet'}
      onClose={onClose}
      open={open}
      className="modal-default"
    >
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <InputContainer className="single">
          <Controller
            control={control}
            name="privateKeys"
            rules={{
              required: 'This field is required',
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <MonacoTextarea
                  label={'Private key'}
                  value={value}
                  onChange={onChange}
                  error={errors?.privateKeys?.message}
                  customError={customValueError}
                />
              );
            }}
          />
        </InputContainer>

        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '20px',
              width: '100%',
            }}
          >
            <button
              className="main_btn button new_btn multi_color_btn secondary_btn"
              onClick={onCancel}
            >
              Cancel
            </button>

            <button
              className="main_btn button new_btn multi_color_btn"
              type="submit"
            >
              Submit
            </button>
          </Box>
        </Box>
      </Box>
    </PopUp>
  );
}
