import logo from '@/assets/logo_mm.png';
import Path from '@/constants/path';
import styled from 'styled-components';
import { LogoWrapper } from '../sidebar/components';

const LogoContainer = styled.div`
  width: 230px;
  padding: 10px;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
`;

const LogoImage = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  img {
    height: 60px;
  }
`;

export const Logo = () => (
  <LogoContainer>
    <LogoImage>
      <LogoWrapper to={Path.TOKEN_LIST}>
        <img src={logo} className="App-logo" alt="logo" />
      </LogoWrapper>
    </LogoImage>
  </LogoContainer>
);
