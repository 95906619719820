export const VolumeIcon = () => (
  <svg
    fill="#000000"
    height="800px"
    width="800px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <g>
          <path
            d="M505.748,6.248c-8.331-8.331-21.839-8.331-30.17,0l-41.927,41.927L229.499,77.334c-4.57,0.653-8.805,2.77-12.069,6.035
				L6.251,294.569c-8.331,8.331-8.33,21.838,0.001,30.169l181.013,181.013c8.331,8.331,21.839,8.331,30.17,0l211.179-211.179
				c3.264-3.264,5.381-7.497,6.034-12.066l29.182-204.169l41.92-41.92C514.08,28.087,514.08,14.58,505.748,6.248z M393.416,269.431
				L202.35,460.497L51.506,309.653l191.067-191.086l176-25.138L393.416,269.431z"
          />
          <path
            d="M319.997,234.667c23.573,0,42.667-19.093,42.667-42.667s-19.093-42.667-42.667-42.667
				c-23.573,0-42.667,19.093-42.667,42.667S296.423,234.667,319.997,234.667z"
          />
        </g>
      </g>
    </g>
  </svg>
);
