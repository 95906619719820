import React from 'react';
import styled from 'styled-components';
import { InputType } from './customSelect';
import { InputName, InputRequired } from './inputName';

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string | undefined;
  labelName?: string;
  error?: string;
  isRequired?: boolean;
  width?: number;
  inputType?: InputType;
  verticalLabel?: boolean;
  customError?: string;
}

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;

const ErrorMessage = styled.span`
  color: #e34a66;
  font-size: 10px;
  line-height: 14px;
  display: block;
  margin-top: 10px;
`;

const AreaField = styled.textarea<{
  $hasError?: boolean;
  $width?: number;
  $inputType?: InputType;
}>`
  font-family: 'Poppins';
  width: ${props => (props.$width ? props.$width - 24 + 'px' : '100%')};
  padding: 8px 12px;
  border-radius: 4px;
  border: ${props =>
    props.$inputType === InputType.MAPPING ? 'none' : '1px solid var(--black)'};
  background: ${props =>
    props.$inputType === InputType.MAPPING ? '#40495C' : '#fff'};
  color: ${props =>
    props.$inputType === InputType.MAPPING ? '#fff' : '#222222 '};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const TextArea: React.FC<TextAreaProps> = ({
  label,
  error,
  isRequired = false,
  width = 0,
  rows = 3,
  inputType = InputType.POP_UP,
  verticalLabel = true,
  labelName,
  placeholder,
  customError,
  ...textAreaProps
}) => {
  return (
    <InputContainer>
      {verticalLabel && (
        <InputName>
          {labelName || label}
          <InputRequired>{isRequired && '*'}</InputRequired>
        </InputName>
      )}
      <InputWrapper>
        <AreaField
          {...textAreaProps}
          $hasError={!!error}
          $width={width}
          rows={rows}
          $inputType={inputType}
          placeholder={placeholder ?? label}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {customError && <ErrorMessage>{customError}</ErrorMessage>}
    </InputContainer>
  );
};

export default TextArea;
