/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import CustomDateTimePicker from '@/components/form/datetimepicker';
import Input, { Label } from '@/components/form/input';
import NumberInput from '@/components/form/numberInput';
import { InputContainer } from '@/components/form/styled';
import { Title } from '@/components/login';
import { convertStringToNumber } from '@/utils/helper';

import { JumboTron, TwoFormItem } from '@/components/token/components';
import { BASE_QUOTE, MAX_SNIP, MIN_SNIP } from '@/constants';
import { TokenDetail } from '@/stores/slices/token/type';
import { Alert, Box } from '@mui/material';
import * as buffer from 'buffer';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

window.Buffer = buffer.Buffer;

export interface WalletType {
  address: string;
  privateKey: string;
}
export enum CREATE_MARKET_TYPE {
  NEW = 1,
  EXISTING = 2,
}

export enum POOL_TYPE {
  MARKET = 1,
  SNIP = 2,
}

export interface PoolProps {
  onCreate: (data: any) => void;
  data: TokenDetail;
  marketValue?: string;
  type?: POOL_TYPE;
}

export const Pool = ({
  onCreate,
  data,
  marketValue,
  type = POOL_TYPE.MARKET,
}: PoolProps) => {
  const { t } = useTranslation();
  const [poolID, setPool] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    watch,
  } = useForm<{
    baseToken: string;
    quoteToken: string;
    marketId: string;
    time: string;
    baseTokenAmount: number;
    quoteTokenAmount: number;
    volume1: number;
    privateKey1: string;
    volume2: number;
    privateKey2: string;
    volume3: number;
    privateKey3: string;
    volume4: number;
    privateKey4: string;
  }>({
    defaultValues: {
      quoteToken: BASE_QUOTE,
      baseToken: data?.contract_address,
      marketId: marketValue ?? '',
    },
  });

  useEffect(() => {
    try {
      const poolData = JSON.parse(data?.pool);
      if (poolData?.id) {
        setPool(poolData?.id);
      }
    } catch (e) {}
    reset({
      baseToken: data?.contract_address,
      quoteToken: BASE_QUOTE,
      marketId: marketValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, marketValue]);

  const submitForm = (formDt: any) => {
    validate(formDt, 'volume1');
    validate(formDt, 'volume2');
    validate(formDt, 'volume3');
    validate(formDt, 'volume4');
    setOpen(true);
  };

  const validate = (
    formDt: any,
    field: 'volume1' | 'volume2' | 'volume3' | 'volume4',
  ) => {
    const volume = formDt[field]?.toString().replace(/,/g, '');
    if (volume > MAX_SNIP || volume < MIN_SNIP) {
      setError(field, {
        message: `Volume Snip should be between ${MIN_SNIP} and ${MAX_SNIP}`,
      });
      return;
    }
  };

  const sendPool = () => {
    const data: any = watch();
    data.baseTokenAmount = data.baseTokenAmount?.toString().replace(/,/g, '');
    data.baseTokenAmount = +data.baseTokenAmount;
    data.quoteTokenAmount = data.quoteTokenAmount?.toString().replace(/,/g, '');

    data.quoteTokenAmount = +data.quoteTokenAmount;
    if (type === POOL_TYPE.SNIP) {
      data.volume1 = convertStringToNumber(data.volume1);
      data.volume2 = convertStringToNumber(data.volume2);
      data.volume3 = convertStringToNumber(data.volume3);
      data.volume4 = convertStringToNumber(data.volume4);
    }

    if (data.time && data.time.isValid()) {
      data.time = data.time.valueOf();
    } else {
      data.time = Date.now();
    }
    onCreate(data);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(submitForm)}>
      <Box
        component="div"
        mb={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          '& hr': {
            marginBottom: '30px',
          },
          '& .MuiAlert-root': {
            background: '#A43399',
          },
          '& .MuiAlert-icon, & .MuiAlert-message': {
            color: 'orange !important',
          },
        }}
      >
        <JumboTron mt={2} className="full">
          {poolID ? <Title className="bt">Pool ID : {poolID}</Title> : ''}
          <TwoFormItem className="gap">
            <InputContainer className="no_mb">
              <Label>
                <span>*</span>
                Base token
              </Label>
              <Controller
                control={control}
                name="baseToken"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <Input
                      label={'Base Token'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.baseToken?.message}
                      placeholder={'Base Token'}
                      disabled
                    />
                  );
                }}
              />
            </InputContainer>
            <InputContainer className="no_mb">
              <Label>
                <span>*</span>
                Quote token
              </Label>
              <Controller
                control={control}
                name="quoteToken"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <Input
                      label={'Quote Token'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.quoteToken?.message}
                      placeholder={'Quote Token'}
                      disabled
                    />
                  );
                }}
              />
            </InputContainer>
          </TwoFormItem>
          <TwoFormItem className="gap">
            <InputContainer className="no_mb">
              <Label>
                <span>*</span>
                Base Token Initial Liquidity
              </Label>
              <Controller
                control={control}
                name="baseTokenAmount"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      label={'Base Token Initial Liquidity'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.baseTokenAmount?.message}
                      placeholder="Base Token Initial Liquidity"
                      disabled={!!poolID}
                    />
                  );
                }}
              />
            </InputContainer>

            <InputContainer className="no_mb">
              <Label>
                <span>*</span>
                Quote Token Initial Liquidity
              </Label>
              <Controller
                control={control}
                name="quoteTokenAmount"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      label={'Quote Token Initial Liquidity'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.quoteTokenAmount?.message}
                      placeholder="Quote Token Initial Liquidity"
                      disabled={!!poolID}
                    />
                  );
                }}
              />
            </InputContainer>
          </TwoFormItem>

          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px',
                '&  .MuiSwitch-switchBase.Mui-checked': {
                  color: '#A43399',
                },
              }}
            >
              <Label>
                <span>*</span>
                MARKET ID (Only one liquidity pool can be created for a market
                ID)
              </Label>
              {/* <button
                className={`button  main_button logout white_border`}
                onClick={() => navigate(Path.CREATE_BOOK)}
              >
                Create new market
              </button> */}
            </Box>
            <InputContainer className="no_mb">
              <Controller
                control={control}
                name="marketId"
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <Input
                      label={''}
                      value={value}
                      onChange={onChange}
                      error={errors?.marketId?.message}
                      placeholder={'Market ID '}
                      disabled
                    />
                  );
                }}
              />
            </InputContainer>
          </Box>
          <TwoFormItem className="gap">
            <InputContainer>
              <Label>
                Start time (Optional) <span className="bold">UTC+0</span>
              </Label>
              <Controller
                control={control}
                name="time"
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <CustomDateTimePicker
                      value={value}
                      onChange={onChange}
                      disabled={!!poolID}
                    />
                  );
                }}
              />
            </InputContainer>
            <Box></Box>
          </TwoFormItem>
          {type === POOL_TYPE.SNIP && (
            <>
              <TwoFormItem className="gap">
                <InputContainer className="no_mb">
                  <Label>Volume Snip ( SOL)</Label>
                  <Controller
                    control={control}
                    name="volume1"
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <NumberInput
                          label={'Volume Snip ( SOL)'}
                          value={value}
                          onChange={onChange}
                          error={errors?.volume1?.message}
                          placeholder="Volume Snip ( SOL)"
                          disabled={!!poolID}
                        />
                      );
                    }}
                  />
                </InputContainer>
                <InputContainer className="no_mb">
                  <Label>Private Key</Label>
                  <Controller
                    control={control}
                    name="privateKey1"
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <Input
                          label={'Private key'}
                          value={value}
                          onChange={onChange}
                          error={errors?.privateKey1?.message}
                          placeholder="Private key"
                        />
                      );
                    }}
                  />
                </InputContainer>
              </TwoFormItem>
              <TwoFormItem className="gap">
                <InputContainer className="no_mb">
                  <Label>Volume Snip ( SOL)</Label>
                  <Controller
                    control={control}
                    name="volume2"
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <NumberInput
                          label={'Volume Snip ( SOL)'}
                          value={value}
                          onChange={onChange}
                          error={errors?.volume2?.message}
                          placeholder="Volume Snip ( SOL)"
                          disabled={!!poolID}
                        />
                      );
                    }}
                  />
                </InputContainer>
                <InputContainer className="no_mb">
                  <Label>Private Key</Label>
                  <Controller
                    control={control}
                    name="privateKey2"
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <Input
                          label={'Private key'}
                          value={value}
                          onChange={onChange}
                          error={errors?.privateKey2?.message}
                          placeholder="Private key"
                        />
                      );
                    }}
                  />
                </InputContainer>
              </TwoFormItem>
              <TwoFormItem className="gap">
                <InputContainer className="no_mb">
                  <Label>Volume Snip ( SOL)</Label>
                  <Controller
                    control={control}
                    name="volume3"
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <NumberInput
                          label={'Volume Snip ( SOL)'}
                          value={value}
                          onChange={onChange}
                          error={errors?.volume3?.message}
                          placeholder="Volume Snip ( SOL)"
                          disabled={!!poolID}
                        />
                      );
                    }}
                  />
                </InputContainer>
                <InputContainer className="no_mb">
                  <Label>Private Key</Label>
                  <Controller
                    control={control}
                    name="privateKey3"
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <Input
                          label={'Private key'}
                          value={value}
                          onChange={onChange}
                          error={errors?.privateKey3?.message}
                          placeholder="Private key"
                        />
                      );
                    }}
                  />
                </InputContainer>
              </TwoFormItem>
              <TwoFormItem className="gap">
                <InputContainer className="no_mb">
                  <Label>Volume Snip ( SOL)</Label>
                  <Controller
                    control={control}
                    name="volume4"
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <NumberInput
                          label={'Volume Snip ( SOL)'}
                          value={value}
                          onChange={onChange}
                          error={errors?.volume4?.message}
                          placeholder="Volume Snip ( SOL)"
                          disabled={!!poolID}
                        />
                      );
                    }}
                  />
                </InputContainer>
                <InputContainer className="no_mb">
                  <Label>Private Key</Label>
                  <Controller
                    control={control}
                    name="privateKey4"
                    render={({ field }) => {
                      const { value, onChange } = field;
                      return (
                        <Input
                          label={'Private key'}
                          value={value}
                          onChange={onChange}
                          error={errors?.privateKey4?.message}
                          placeholder="Private key"
                        />
                      );
                    }}
                  />
                </InputContainer>
              </TwoFormItem>
            </>
          )}
        </JumboTron>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <CustomButton
          type="submit"
          buttonType={CustomButtonType.NO_ICON}
          title={'Create Liquidity AMM Pool'}
          className="multi_color_btn main_btn"
          disabled={!!poolID}
        />
      </Box>
      <ConfirmPopUp
        open={open}
        onClose={() => setOpen(false)}
        title="Create pool"
        actionTitle="Create"
        onSubmit={sendPool}
      >
        <Alert severity="warning">Confirm to create pool!</Alert>
      </ConfirmPopUp>
    </Box>
  );
};
