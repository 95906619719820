/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import { ImageInput } from '@/components/form/imageUploading';
import Input from '@/components/form/input';
import { InputName } from '@/components/form/inputName';
import NumberInput from '@/components/form/numberInput';
import SelectItem from '@/components/form/select';
import { InputContainer } from '@/components/form/styled';
import TextArea from '@/components/form/textarea';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { TokenDetail, TokenDetailsForm } from '@/stores/slices/token/type';
import { getAddressFromPrivateKey, validateKey } from '@/utils/helper';
import { Box, Grid } from '@mui/material';
import { File } from 'nft.storage';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Import the NFTStorage class and File constructor from the 'nft.storage' package
export enum TokenFormType {
  CREATE = 1,
  EDIT = 2,
}

export interface TokenFormProps {
  type?: TokenFormType;
  data?: TokenDetail;
  onCancel?: () => void;
  onSubmit?: (data: FormData) => void;
}

export const TokenForm = ({
  type = TokenFormType.CREATE,
  data,
  onCancel,
  onSubmit,
}: TokenFormProps) => {
  const { t } = useTranslation();
  const [uploadJsonUri, setUploadJsonUri] = useState<string>('');
  const [address, setAddress] = useState<string | undefined>('');
  const [validErrorValue, setValidErrorValue] = useState('');

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TokenDetailsForm>({
    defaultValues: {
      decimal: 9,
      supply: 100000000,
    },
  });
  const watchValuePrivateKey = watch('ownerPrivateKey');

  const { setErrorMsg } = useGlobalHook();
  const { requestUpdateTokenImage, contractAddress } = useTokenHook();
  const isValidKey = watchValuePrivateKey && validateKey(watchValuePrivateKey);

  const submitForm = async (formDt: any) => {
    requestUpdateTokenImage(RequestStatus.REQUESTING);
    try {
      const formData = new FormData();

      const metadata = {
        name: formDt.name,
        symbol: formDt.symbol,
        image: formDt.image,
        description: formDt.description,
        tags: ['meme'],
        extensions: {
          twitter: formDt.twitter ?? '',
          telegram: formDt.telegram ?? '',
          website: formDt.website ?? '',
        },
      };
      const fileData = JSON.stringify(metadata, null, 2);
      const blob = new Blob([fileData], { type: 'application/json' });
      formData.append('file', blob, `${formDt.symbol}.json`);
      formData.append(
        'pinataMetadata',
        JSON.stringify({
          name: formDt.name?.replace(/\s/g, '') + '.json',
        }),
      );
      const options = JSON.stringify({
        cidVersion: 0,
      });

      formData.append('pinataOptions', options);

      const uploadResponse = await callPinata(formData);
      const uri = `https://scarlet-changing-grasshopper-251.mypinata.cloud/ipfs/${uploadResponse.IpfsHash}`;
      formDt.uri = uri;
      setUploadJsonUri(uri);
      requestUpdateTokenImage(RequestStatus.SUCCESS);
    } catch (error) {
      requestUpdateTokenImage(RequestStatus.ERROR);
    }

    // delete formDt.image;
    if (!formDt.uri) {
      formDt.uri = uploadJsonUri;
    }

    formDt.decimal = +formDt.decimal;
    formDt.supply = formDt.supply?.toString().replace(/,/g, '');

    onSubmit &&
      onSubmit({
        ...formDt,
      });
  };

  async function callPinata(formData: any) {
    try {
      const res = await fetch(
        'https://api.pinata.cloud/pinning/pinFileToIPFS',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PINATA_KEY || ''}`,
          },
          body: formData,
        },
      );
      return await res.json();
    } catch (err) {
      requestUpdateTokenImage(RequestStatus.ERROR);
    }
  }

  async function getFileBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to read file as ArrayBuffer'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Failed to read file'));
      };

      reader.readAsArrayBuffer(file);
    });
  }

  const uploadImage = async (img: any) => {
    requestUpdateTokenImage(RequestStatus.REQUESTING);
    try {
      // create a new NFTStorage client using our API key
      const imgBuffer = await getFileBuffer(img[0].file);
      const image = new File([imgBuffer], img[0].file.name, {
        type: img[0].file.type,
      });
      const formData = new FormData();
      formData.append('file', image);
      const metadata = JSON.stringify({
        name: img[0].file.name,
      });
      formData.append('pinataMetadata', metadata);

      const options = JSON.stringify({
        cidVersion: 0,
      });
      formData.append('pinataOptions', options);

      const resData = await callPinata(formData);
      setValue(
        'image',
        `https://scarlet-changing-grasshopper-251.mypinata.cloud/ipfs/${resData.IpfsHash}`,
      );
      requestUpdateTokenImage(RequestStatus.SUCCESS);
    } catch (error) {
      setErrorMsg(['Something went wrong. Unable to upload your token image']);
      requestUpdateTokenImage(RequestStatus.ERROR);
    }
  };

  const getUrl = async (data: any) => {
    const res = await fetch(data.uri);
    if (res.ok) {
      const dt = await res.json();
      setValue('image', dt.image);
      setValue('website', dt.extensions?.website);
      setValue('telegram', dt.extensions?.telegram);
      setValue('twitter', dt.extensions?.twitter);
    } else {
      setValue('image', '');
    }
  };

  const handleOptionContractAddress: any = () => {
    if (contractAddress) {
      const valueContractAddress = contractAddress.map(item => ({
        label: item.address,
        value: item.address,
      }));
      return [{ label: 'No select', value: '' }, ...valueContractAddress];
    }
  };

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        symbol: data.symbol,
        description: data.description,
        website: data.website,
        telegram: data.telegram,
        twitter: data.twitter,
        image: data.image,
        uri: data.uri,
      });
      getUrl(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (type === TokenFormType.CREATE) {
      const address =
        watchValuePrivateKey && getAddressFromPrivateKey(watchValuePrivateKey);
      if (watchValuePrivateKey) {
        if (isValidKey) {
          setValidErrorValue('');
        } else {
          setValidErrorValue('Private key is not valid');
        }
      }
      setAddress(address);
    }
  }, [watchValuePrivateKey]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submitForm)}
      style={{ marginTop: 50 }}
    >
      {type === TokenFormType.CREATE ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputContainer className="mt1 ">
              <Controller
                control={control}
                name="ownerPrivateKey"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <Input
                      label={t('Owner Private Key')}
                      isRequired
                      error={
                        validErrorValue || errors?.ownerPrivateKey?.message
                      }
                      value={value}
                      onChange={onChange}
                      horizontalLabel
                      addressWallet={address}
                      type={'password'}
                    />
                  );
                }}
              />
            </InputContainer>

            <InputContainer>
              <InputName>Contract Address (Optional)</InputName>
              <Controller
                control={control}
                name="address"
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <SelectItem
                      sx={{
                        border: '1px solid var(--black) !important',
                        borderRadius: '4px !important',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '23px',
                      }}
                      options={handleOptionContractAddress()}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </InputContainer>
          </Grid>
          <Grid item xs={6}>
            {/* <Title>Token Image</Title> */}
            <Controller
              control={control}
              name="image"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value } = field;
                return (
                  <ImageInput
                    label={t('Token Image')}
                    title={t('Drag an image here or click “Upload” below')}
                    width={120}
                    height={120}
                    onUpload={uploadImage}
                    value={value ? [value] : []}
                    isRequired
                    errorMessage={errors?.image?.message}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <InputContainer>
          <InputName>Contract Address (Optional)</InputName>
          <Controller
            control={control}
            name="address"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <SelectItem
                  sx={{
                    border: '1px solid var(--black) !important',
                    borderRadius: '4px !important',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '23px',
                  }}
                  options={handleOptionContractAddress()}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputContainer>
            <Controller
              control={control}
              name="name"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Name')}
                    isRequired
                    error={errors?.name?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer className="mt1 ">
            <Controller
              control={control}
              name="symbol"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Symbol')}
                    isRequired
                    error={errors?.symbol?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
      </Grid>
      <InputContainer className="single">
        <Controller
          control={control}
          name="description"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <TextArea
                label={t('Description')}
                error={errors?.description?.message}
                value={value}
                onChange={onChange}
                rows={3}
              />
            );
          }}
        />
      </InputContainer>
      {type === TokenFormType.CREATE ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputContainer className="mt1 ">
              <Controller
                control={control}
                name="decimal"
                rules={{
                  required: t('This field is required'),
                  pattern: {
                    value: /^[1-9]$/,
                    message: 'Decimal is between 1 and 9',
                  },
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      label={'Decimal'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.decimal?.message}
                      placeholder="Decimal"
                    />
                  );
                }}
              />
            </InputContainer>
          </Grid>
          <Grid item xs={6}>
            <InputContainer className="mt1 ">
              <Controller
                control={control}
                name="supply"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      label={'Supply'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.supply?.message}
                      placeholder="Supply"
                    />
                  );
                }}
              />
            </InputContainer>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <InputContainer className="mt1 ">
        <Controller
          control={control}
          name="website"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <Input
                label={t('Website')}
                labelName={t('Website (Optional)')}
                error={errors?.website?.message}
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      </InputContainer>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputContainer className="mt1 ">
            <Controller
              control={control}
              name="twitter"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Twitter')}
                    labelName={t('Twitter (Optional)')}
                    error={errors?.twitter?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer className="mt1">
            <Controller
              control={control}
              name="telegram"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Telegram')}
                    labelName={t('Telegram (Optional)')}
                    error={errors?.telegram?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        {type === TokenFormType.EDIT && (
          <CustomButton
            buttonType={CustomButtonType.NO_ICON}
            title={t(`Cancel`)}
            className="multi_color_btn main_btn secondary_btn"
            onClick={onCancel}
          />
        )}

        <CustomButton
          type="submit"
          buttonType={CustomButtonType.NO_ICON}
          title={type === TokenFormType.EDIT ? `Edit Token` : `Create Token`}
          className="multi_color_btn main_btn"
        />
      </Box>
    </Box>
  );
};
